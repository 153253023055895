import { Grid, Stack, Typography, Tooltip, TooltipProps, tooltipClasses, styled } from "@mui/material";
import Loader from "../../Loader/Loader";
import { ICommunity } from "../../../models/communities.modal";
import { useNavigate } from "react-router-dom";
import { metaConfig } from "../../../utils/constants";
import { Helmet } from "react-helmet";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { AdminCommunities } from "../AllCommunities/AdminCommunities";
import { useCommunity } from "../../../hooks/useCommunity";
import { useEffect, useState } from "react";

interface ITableProps {
  data: ICommunity[];
}

const AdministeredCommunitiesGrid = ({ data }: ITableProps) => {
  const navigate = useNavigate();
  const { getCommunitiesList } = useCommunity();

  const [communities, setCommunities] = useState<ICommunity[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCommunitiesList = async () => {
      try {
        setIsLoading(true);
        if (!communities.length) {
          const communitiesData = await getCommunitiesList();
          setCommunities(communitiesData);
        }
      } catch (e) {
        setError("An error occurred while fetching communities.");
        console.error("Error occurred while fetching communities:", e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCommunitiesList();
  }, []); // Empty dependency array ensures this runs only once

  // Determine which data source to use
  const communitiesToRender = communities.length > 0 ? communities : data;

  // Render the communities list
  const myCommunities = communitiesToRender.map((community) => (
    <Grid item key={community.id} xs={6} sm={2} md={2} lg={2} xl={2}>
      <AdminCommunities {...community} />
    </Grid>
  ));

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.adminCommunities}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>

      <Stack
        direction={"row"}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "sticky",
          top: -18,
          zIndex: 1,
          borderRadius: '10px',
          ml: 1,
          mr: 1,
          backgroundColor: "#ffffff",
          padding: "10px 0",
        }}
      >
        <BootstrapTooltip title="Back" placement="left">
          <KeyboardBackspaceIcon
            sx={{ color: "#50A1CA", cursor: "pointer", ml: 2, fontSize: { xs: "20px", md: '40px' } }}
            onClick={() => navigate(`/explore-communities`)}
          />
        </BootstrapTooltip>
        <Typography
          sx={{
            fontSize: { xs: "14px", md: '18px' },
            fontWeight: 700,
            color: "#000000",
          }}
        >
          Administered Communities
        </Typography>

        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            color: "#B3B3B3",
            mr: 2
          }}
        >
          {myCommunities?.length} communities
        </Typography>
      </Stack>

      <Stack>
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <Grid container spacing={0}>
            {myCommunities}
          </Grid>
        )}
      </Stack>
    </>
  );
};

export default AdministeredCommunitiesGrid;
