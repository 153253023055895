import axios from 'axios';
import { BASE_URL } from '../configurations/url.config';
import {
  ICategory,
  ICommunity,
  ICommunityResponse,
} from '../models/communities.modal';
import { IDate } from '../models/general.model';
import { formatDate } from '../utils/dateUtil';
const INTERNAL_SERVER_ERROR = 'Internal Server Error';

export const getCommunities = async (token: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/community`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      response.data = response.data.map((d: ICommunityResponse): ICommunity => {
        // console.log(d);
        const updatedDate = new Date(d.updatedAt);
        // Create an object of type IDate
        const updated: IDate = {
          date: `${updatedDate.getDate()} ${updatedDate.toLocaleString(
            'default',
            { month: 'short' }
          )}. ${updatedDate.getFullYear()}`,
          time: `${updatedDate.toLocaleString('default', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          })}`,
        };

        return {
          ...d,
          _id: d._id ?? '',
          title: d.name ?? 'Name not updated',
          createdBy: d.createdBy ?? '',
          totalMembers: d.members?.length ? d.members.length : 0,
          category: d.category ?? '',
          markedAs: d.type,
          status: d.status,
          updated: updated,
          membership: d.collectSubscription,
          earnings: d.earnings ?? 0,
          fullAddress: d.fullAddress,
          createdAt: formatDate(new Date(d.createdAt)),
          updatedAt: formatDate(new Date(d.updatedAt)),
          favorite: d.favorite ?? false,
          logo: d.logo,
          gallery: d.gallery,
          updatedBy: d.updatedBy ?? '',
          phoneNumber: d.phoneNumber,
          images: d.images ?? [''],
          services: d.services,
          maxMembers: d?.maxMembers ?? 0,
          banner: d.banner,
          otherCategory: d?.otherCategory,
          teams: d?.teams,
          faq: d?.faq,
          plans: d?.plans,
        };
      });
    }
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

export const getCategories = (): ICategory[] => {
  const data: ICategory[] = [
    { title: 'Health Care' },
    { title: 'Technology' },
    { title: 'Education' },
    { title: 'Politics' },
    { title: 'Business' },
    { title: 'Influencer' },
    { title: 'Consultancy' },
    { title: 'NGO' },
    { title: 'Travel' },
    { title: 'Social' },
  ];
  return data;
};

export const checkCommunityAvailability = async (
  token: string,
  name: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/community/${name}/available`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 302, data: [] };
  }
};

export const updateCommunityService = async (
  token: string,
  id: string | undefined,
  formData: any
) => {
  // Make the API call to update community details
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "multipart/form-data",
      Accept: '*',
      'X-Json-Data': true,
      'Content-Type': 'application/json',
    };
    const response = await axios.put(`${BASE_URL}/community/${id}`, formData, {
      headers,
    });
    if (response.status === 201) {
      let community = response.data;
      response.data = {
        ...community,
        // _id: community._id,
        name: community.name ?? '',
        category: community.category ?? '',
        zipCode: community.zipCode ?? '',
        fullAddress: community.fullAddress ?? '',
        gstNumber: community.gstNumber ?? '',
        type: community.type ?? '',
        collectSubscription: community.collectSubscription ?? '',
        city: community.city ?? '',
        maxMembers: community.maxMembers,
        logo: community.logo,
        banner: community.banner,
      };
    }
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

export const communityById = async (token: string, id: string) => {
  try {
    // console.log('getCommunity');

    const response = await axios.get(`${BASE_URL}/community/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    localStorage.setItem('communityId', id);
    // console.log(response?.data);
    // console.log(response?.data?.mapping?.subscriptionStatus);
    // console.log(response?.data?.paymentsDue?.status);

    // const paymentsDueStatuses =
    //   response?.data?.paymentsDue?.map(
    //     (payment: { status: any }) => payment.status
    //   ) || [];

    // console.log("Statuses:", paymentsDueStatuses);
    //let community = response.data;
    const { community, mapping } = response?.data;
    response.data = {
      _id: community?._id ?? '',
      name: community?.name ?? '',
      category: community?.category ?? '',
      zipCode: community?.zipCode ?? '',
      fullAddress: community?.fullAddress ?? '',
      gstNumber: community?.gstNumber ?? '',
      description: community?.description ?? '',
      type: community?.type ?? '',
      collectSubscription: community?.collectSubscription ?? '',
      //plan: community.plan ?? "",
      location:community?.location ?? '',
      vision: community?.vision ?? '',
      mission: community?.mission ?? '',
      adminMessage: community?.adminMessage ?? '',
      directorMessage: community?.directorMessage ?? '',
      contactName: community?.contactName ?? '',
      phoneNumber: community?.phoneNumber ?? '',
      mobileNumber: community?.mobileNumber ?? '',
      email: community?.email ?? '',
      createdAt: formatDate(new Date(community.createdAt)),
      updatedAt: formatDate(new Date(community.updatedAt)),
      joinedOn: formatDate(new Date(mapping?.createdAt)),
      socialLinks: community?.socialLinks ?? [],
      members: community?.members ?? [],
      posts: community?.posts ?? 0,
      maxMembers: community?.maxMembers,
      city: community?.city,
      logo: community?.logo,
      banner: community?.banner,
      services: community?.services,
      teams: community?.teams,
      faq: community?.faq,
      otherCategory: community?.otherCategory,
      gallery: community?.gallery ?? [],
      // status: paymentsDue?.status[0] ?? [],
      // paymentsDuestatus: paymentsDueStatuses,
      subscriptionStatus: mapping?.subscriptionStatus,
    };
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

export const createCommunity = async (token: string, CommunityData: any) => {
  try {
    const formData = JSON.stringify(CommunityData);
    const response = await axios.post(
      `${BASE_URL}/community/create`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    //console.log(CommunityData);

    if (response.status === 201) {
      let community = response.data;
      response.data = {
        _id: community._id,
        name: community.name ?? '',
        category: community.category ?? '',
        zipCode: community.zipCode ?? '',
        fullAddress: community.fullAddress ?? '',
        gstNumber: community.gstNumber ?? '',
        description: community.description ?? '',
        type: community.type ?? '',
        collectSubscription: community.collectSubscription ?? '',
        plan: community.plan ?? '',
        vision: community.vision ?? '',
        mission: community.mission ?? '',
        adminMessage: community.adminMessage ?? '',
        directorMessage: community.directorMessage ?? '',
        contactName: community.contactName ?? '',
        phoneNumber: community.phoneNumber ?? '',
        email: community.email ?? '',
        createdAt: formatDate(new Date(community.createdAt)),
        updatedAt: formatDate(new Date(community.updatedAt)),
        socialLinks: community.socialLinks ?? [],
        members: community.members ?? [],
        posts: community.posts ?? 0,
        city: community.city ?? '',
        maxMembers: community?.maxMembers,
      };
    }
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};
//update user role
export const updateUserRole = async (
  token: string,
  id: string | undefined,
  formData: any
) => {
  // Make the API call to update community details
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      //"Content-Type": "multipart/json",
    };
    console.log(formData);
    const response = await axios.put(
      `${BASE_URL}/community/${id}/role-update/${id}`,
      formData,
      {
        headers,
      }
    );
    if (response.status === 200) {
      return response.data;
    }
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};
// export const favCommunity = async (token: string,id:string, favorite: boolean) => {
//   try {
//     const headers = {
//       Authorization: `Bearer ${token}`,
//       "Content-Type": "application/json",
//     };

//     const response = await axios.put(
//       `${BASE_URL}/community/${id}/favorite`,
//        {},
//       {
//         headers,
//       }
//     );

//     if (response.status === 200) {
//       let community = response.data;
//       response.data = {
//         ...community,
//         _id: community._id??'',
//         name: community.name ?? "",
//         category: community.category ?? "",
//         zipCode: community.zipCode ?? "",
//         fullAddress: community.fullAddress ?? "",
//         gstNumber: community.gstNumber ?? "",
//         description: community.description ?? "",
//         type: community.type ?? "",
//         collectSubscription: community.collectSubscription ?? "",
//         plan: community.plan ?? "",
//         vision: community.vision ?? "",
//         mission: community.mission ?? "",
//         adminMessage: community.adminMessage ?? "",
//         directorMessage: community.directorMessage ?? "",
//         contactName: community.contactName ?? "",
//         phoneNumber: community.phoneNumber ?? "",
//         email: community.email ?? "",
//         createdAt: community.createdAt ?? "",
//         favorite:community.favorite??false
//       };
//     }
//     console.log(response.data)
//     return response;
//   } catch (err) {
//     console.log("ERR :", err);
//     return { status: 500, data: [] };
//   }
// };

// Add other community-related services here as needed

export const membershipCommunities = async (token: string) => {
  // Make the API call to update community details
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      //"Content-Type": "multipart/json",
    };

    const response = await axios.get(`${BASE_URL}/community/membership/all`, {
      headers,
    });
    if (response.status === 200) {
      // const communityData = response?.data;
      // const communitiesList = communityData?.data.map(
      //   (item: ICommunity) => item?.community
      // );

      // const _response = _parseCommunitiesResponse(communitiesList);
      // console.log(_response);
      // response.data = _response;
      return response;
    }
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

export const suggestionCommunities = async (token: string) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      //"Content-Type": "multipart/json",
    };

    // const response = await axios.get(`${BASE_URL}/communities/trending/all`, {
    const response = await axios.get(`${BASE_URL}/community/suggestions/all`, {
      headers,
    });
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

export const fetchTrendingCommunities = async (token: string) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    const fullAddress = token
      ? `${BASE_URL}/community/trending/all`
      : `${BASE_URL}/communities/trending`;
    const response = await axios.get(fullAddress, {
      headers,
    });

    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

export const joinCommunity = async (token: string, community_id: string) => {
  try {
    const formData = JSON.stringify({ community_id: community_id });
    // console.log(formData);
    const response = await axios.post(
      `${BASE_URL}/community/${community_id}/join`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (err) {
    return { status: 500, data: [], message: INTERNAL_SERVER_ERROR };
  }
};

export const favCommunity = async (
  token: string,
  id: string,
  favorite: boolean
) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    const response = await axios.put(
      `${BASE_URL}/community/${id}/favorite`,
      { favorite },

      {
        headers,
      }
    );

    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};
export const favJoinedCommunity = async (
  token: string,
  id: string,
  favorite: boolean
) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    const response = await axios.put(
      `${BASE_URL}/communities/${id}/mark-as-favorite`,
      { favorite },

      {
        headers,
      }
    );

    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};
export const updateUserMappingCommunity = async (
  token: string,
  id: string | undefined,
  formData: any
) => {
  // Make the API call to update community details
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      //  "Content-Type": "multipart/form-data",
      Accept: '*',
      'X-Json-Data': true,
      'Content-Type': 'application/json',
    };
    const response = await axios.put(
      `${BASE_URL}/communities/${id}/update-my-community-user-mapping`,
      formData,
      {
        headers,
      }
    );
    if (response.status === 201) {
      // let community = response.data;
      // response.data = {
      //   ...community,
      //   // _id: community._id,
      //   name: community.name ?? "",
      //   category: community.category ?? "",
      //   zipCode: community.zipCode ?? "",
      //   fullAddress: community.fullAddress ?? "",
      //   gstNumber: community.gstNumber ?? "",

      //   type: community.type ?? "",
      //   collectSubscription: community.collectSubscription ?? "",

      //   city: community.city ?? "",

      //   maxMembers: community.maxMembers,
      //   logo: community.logo,
      //   banner: community.banner,
      // };
      return response;
    }
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};

export const updateUserMappingCommunityBanner = async (
  token: string,
  id: string,
  files: FileList | null,
  data: FormData
) => {
  try {
    const formData = new FormData();
    if (files != null) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formData.append('banner', file);
      }
    }
    for (const [field, value] of data.entries()) {
      formData.append(field, value);
    }
    const response = await axios.put(
      `${BASE_URL}/communities/${id}/update-my-community-user-mapping`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          Accept: '*',
        },
      }
    );

    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: {} };
  }
};
