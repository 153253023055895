import Close from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { SnackbarKey, useSnackbar } from 'notistack'
import React from 'react'

export const SnackbarCloseButton = ({ snackbarId }: { snackbarId: SnackbarKey }) => {
    const { closeSnackbar } = useSnackbar();
    return (
        <IconButton color='inherit' size='small' onClick={() => closeSnackbar(snackbarId)}>
            <Close />
        </IconButton>
    )
}
