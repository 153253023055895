import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  Divider,
  Fab,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { AuthContext } from "../../contexts/Auth.context";
import LoginPopUp from "../Auth/Login/LoginPopUp";

const NoLoginPopUp = () => {
  const { isAuthenticated } = React.useContext(AuthContext);
  const [timerPopup, setTimerPopUp] = React.useState(false);
  const [loginDialogOpen, setLoginDialogOpen] = React.useState(false);

  React.useEffect(() => {
    // Open the dialog every 10 seconds
    const intervalId = setInterval(() => {
      setTimerPopUp(true);
    }, 15000);

    return () => {
      clearInterval(intervalId);
    };
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClosePopup = () => {
    setTimerPopUp(false);
  };

  const handleLoginButtonClick = () => {
    setLoginDialogOpen(true);
  };

  const handleLoginDialogClose = () => {
    setLoginDialogOpen(false);
  };

  return (
    <>

{!isAuthenticated &&(
           <Box
              sx={{
                textAlign: "center",
                mt: "auto",
                mb: "0px",
                // bgcolor:"#ffffff", 
                background:
                "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                // background: "linear-gradient(90deg, rgba(42, 83, 162, 0.40) 0%, rgba(79, 161, 202, 0.40) 17.19%, rgba(57, 155, 127, 0.40) 34.38%, rgba(34, 119, 39, 0.40) 51.04%, rgba(127, 196, 27, 0.40) 68.23%, rgba(254, 127, 6, 0.40) 83.85%, rgba(218, 2, 66, 0.40) 100%)",
                py:2,
                position: "fixed",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                bottom: 0,
                left: 0,
                right: 0,
                padding: "10px", // Optional: Add a subtle shadow
              }}
            >
          
              <>
                <Typography display={{xs:"none", sm:"none",md:"block",}} sx={{color:"#ffffff",fontWeight:"500"}}>
                Login to explore, create and embrace the limitless possibilities that await you here
                </Typography>
                <Button
                onClick={handleLoginButtonClick}
                sx={{
                 
                  textTransform: "capitalize",
                  color: "#ffffff",
                    border: "1px solid #ffffff",
                  borderRadius: "30px",
                  ml: "10px",
                  px: 3,
                  fontWeight:"500",
                  display: { xs: "block", sm: "block", md: "block" },
                }}
              >
               <Typography sx={{fontWeight:"500"}}>Login</Typography>
              </Button>
              </>
              </Box>
              )}
          <Box
              sx={{
                textAlign: "center",
                mt: "auto",
                mb: "0px",
                // bgcolor:"#ffffff",
                bgcolor: {xs:"#ffffff",md:"inherit"},
                position: "fixed",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                bottom: 0,
                left: 0,
                right: 0,
                padding: "10px", // Optional: Add a subtle shadow
              }}
            >
              <Button
              component="a"
              href="https://play.google.com/store"
              target="_blank"
              rel="noopener noreferrer"
                sx={{
                  background:
                    "linear-gradient(140deg, #3C5AA0 0%, #50A1CA 100%)",
                  textTransform: "capitalize",
                  color: "#ffffff",
                  borderRadius: "30px",
                  ml: "10px",
                  px: 2,
                  display: { xs: "block", sm: "none", md: "none" },
                  "&:hover": {
                    backgroundColor: "#3C5AA0",
                    borderColor: "#3C5AA0",
                  },
                }}
              >
                Getapp
              </Button>
              {!isAuthenticated && (
                <Button
                onClick={handleLoginButtonClick}
                sx={{
                 
                  textTransform: "capitalize",
                  color: "#50A1CA",
                    border: "1px solid #50A1CA",
                  borderRadius: "30px",
                  ml: "10px",
                  px: 3,
                  display: { xs: "block", sm: "block", md: "none" },
                }}
              >
                Login
              </Button>
              )}
              </Box>
              
      {!isAuthenticated && (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={timerPopup && !loginDialogOpen}
          onClose={() => handleClosePopup()}
          PaperProps={{
            sx: {
              width: "100%",
            },
          }}
        >
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ pt: "3px", backgroundColor: "#F0F9FF", height: "50px" }}
            >
              <Typography
                sx={{
                  color: "#3C5AA0",
                  fontSize: "20px",
                  fontWeight: "500",
                  backgroundColor: "#F0F9FF",
                  textAlign: "center",
                  pt: 1,
                  px: 2,
                }}
              >
                Enhance Your Experience
              </Typography>

              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{
                  backgroundColor: "#3C5AA0",
                  padding: "4px",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontSize: "10px",
                  mt: "0.5px",
                  mr: "10px",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#50A1CA",
                    cursor: "pointer",
                    border: "none",
                  },
                }}
              >
                <IconButton onClick={handleClosePopup}>
                  <Close sx={{ fontSize: "15px", color: "#FFFFFF" }} />
                </IconButton>
              </Stack>
            </Grid>
            <Stack display={"flex"} spacing={2}>
              <Typography
                sx={{
                  color: "black",
                  fontSize: "13px",
                  pt: 2,
                  px: 4,
                  textAlign: "center",
                }}
              >
                Explore, create, and embrace the limitless possibilities that
                await you here.
              </Typography>
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  px: 2,
                }}
              >
                <Typography
                  component="a"
                  href="https://play.google.com/store"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    textDecoration: "none",
                    border: "1px solid #F0F0F0",
                    cursor: "pointer",
                    textAlign: "center",
                    background:
                      "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                    // px: 4,
                    // pb:"3px",
                    color: "#ffffff",
                    borderRadius: "30px",
                    // display: "flex",
                    display: { xs: "flex", sm: "flex", md: "none" },
                    alignItems: "center",
                    justifyContent: "center",
                    mr: 2,
                    ml: 4,
                    width: "100%",
                    height: "40px",
                  }}
                >
                  Getapp
                </Typography>
                <Typography
                  component={"button"}
                  onClick={handleLoginButtonClick}
                  sx={{
                    border: "1px solid #50A1CA",
                    cursor: "pointer",
                    color: "#50A1CA",
                    textAlign: "center",
                    // px: 4,
                    // pb:"3px",
                    borderRadius: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: { xs: "100%", md: "30%" },
                    mx: 3,
                    height: "40px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  Login
                </Typography>
              </Stack>

              <Divider sx={{ px: 4 }}>
                <Box component={"span"}>or continue with</Box>
              </Divider>
              <Box sx={{ pb: 2 }}>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"center"}
                  alignContent={"center"}
                  sx={{}}
                >
                  <Fab size="medium" sx={{ boxShadow: "none" }}>
                    <Box
                      // onClick={handleGoogleLogin}
                      component={"img"}
                      src={require("./../../assets/images/google1.png")}
                      style={{ boxShadow: "none" }}
                    />
                  </Fab>

                  <Fab size="medium" sx={{ boxShadow: "none" }}>
                    <Box
                      component={"img"}
                      src={require("./../../assets/images/facebook1.png")}
                      style={{ boxShadow: "none" }}
                    />
                  </Fab>
                </Stack>
              </Box>
            </Stack>
          </>
        </Dialog>
      )}

<Dialog open={loginDialogOpen} onClose={handleLoginDialogClose} fullWidth
        maxWidth="sm">
          <Grid
            container
            direction="row"
           
          ><Box
          component={"img"}
          src={require("./../../assets/images/LoginMbg.png")}
          sx={{
            height: "100px",
            width: "100%",
            objectFit: "fill",
            display: { xs: "block", md: "block" },
          }}
          alt=""
        ></Box>
        <Box
              sx={{
                position: "absolute",
                   padding: "4px",
                borderRadius: "50%",
                width: "20px",
                height: "20px",
                     display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#3C5AA0",
                right: 8,
                top: 8,
                cursor:"pointer",
                color: (theme) => theme.palette.grey[500],
              }}
              onClick={() => handleLoginDialogClose()}
            >
              <Close sx={{ fontSize: "15px", color: "#FFFFFF" }} />
            </Box>
        </Grid>
        <LoginPopUp/>
        </Dialog>
    </>
  );
};

export default NoLoginPopUp;
