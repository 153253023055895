import { useContext, useEffect, useState } from "react";
import { AuthContext, IAuthContext } from "../contexts/Auth.context";
import { useSnackbar } from "notistack";
import { deletePlan, getCommunityPlans, savePlan, updatePlan } from "../services/plans.service";
import { IPlan } from "../components/Plans/Plans";
import store from "../store";
import { useSelector } from "react-redux";
// import { deletePost } from "../services/post.service";

export const usePlans = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [plans, setPlans] = useState<IPlan[]>([]);
  //const [plan, setPlan] = useState<IPlan>();

  //data from the store
  const state = store.getState();
  const community = useSelector(() => {
    return state?.selectedCommunity;
  });
  useEffect(() => {
    const communityId = community?.selectedCommunity?._id??""
   
    if( communityId)
      getCommunityPlansList(communityId)
    
    // getPlansList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[community?.selectedCommunity?._id]);

  // const getPlansList = async () => {
  //   setIsLoading(true);
  //   const response = await getPlans(getAccessToken());
  //   if (response.status === 200) {
  //     setPlans(response.data);
  //   } else {
  //     enqueueSnackbar("Couldn't load plans", { variant: "error" });
  //   }
  //   setIsLoading(false);
  // };


  const getCommunityPlansList = async (id:string) => {
    setIsLoading(true);
    const response = await getCommunityPlans(getAccessToken(),id);
    // console.log(response , "response")
    if (response.status === 200) {
      setPlans(response.data);
    } else {
      enqueueSnackbar("Couldn't load plans", { variant: "error", autoHideDuration: 3000 });
    }
    setIsLoading(false);
  };



  const deletePlanById = async (id: string) => {
    try {
      const response = await deletePlan(getAccessToken(), id);
      console.log(response,'response')
      if (response.status === 200) {
  
        setPlans((prevPlans) => prevPlans?.filter((plan) => plan?._id !== id));
         enqueueSnackbar("Plan deleted", { variant: "success", autoHideDuration: 3000 });
         window.location.reload();
      } else {
         enqueueSnackbar("Couldn’t delete plan", { variant: "error", autoHideDuration: 3000 });
      } return response; 
    } catch (error) {
      enqueueSnackbar("Couldn’t  delete plan ", {
        variant: "error",
        autoHideDuration: 3000
      });
    }
  };
  const editPlan = async (planId: string, formData: any,files:FileList | null,
    documents:FileList | null) => {
    try {
      const response = await updatePlan(getAccessToken(), planId, formData,files,documents);
      if (response.status === 200) {
        // Find and update the post in the posts state
        setPlans((prevPosts) =>
          prevPosts.map((plan) => (plan._id === planId ? response.data : plan))
        );
        enqueueSnackbar("Plan updated", { variant: "success", autoHideDuration: 3000 });
      } else {
        enqueueSnackbar("Plan update failed", { variant: "error", autoHideDuration: 3000 });
      }
      return response;
    } catch (error) {
      enqueueSnackbar("Plan update failed", {
        variant: "error",
        autoHideDuration: 3000
      });
    }
  };
  const createNewPlan = async (formData: FormData) => {
    try {
      const response = await savePlan(getAccessToken(), formData);
      // console.log("FormData:", formData);
      if (response.status === 200) {
        
        setPlans((prevPlans) => [...prevPlans, response.data]);
        enqueueSnackbar("Plan Created", { variant: "success", autoHideDuration: 3000 });
      } else {
        enqueueSnackbar("Creation failed", { variant: "error", autoHideDuration: 3000 });
      }
      return response;
      
    } catch (error) {
      enqueueSnackbar("Couldn't create ", {
        variant: "error",
        autoHideDuration: 3000
      });
    }
  };
  return {
    isLoading,
    plans,
    deletePlanById,
    editPlan,
    createNewPlan
   
  };
};
