import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/system/Stack";

export const SubNavbar = ({ children, title, value }: any) => {
  return (
    <>
      <Box>
        <Stack direction={"row"} alignItems={"baseline"} spacing={1}>
          <Typography sx={{ fontSize: "28px", fontWeight: 500,color:'#3760A9' }}>
            {title}
          </Typography>
          {value && value !== "undefined" ? (
            <Typography variant="body2" sx={{ color: "#565656" }}>
              {value}
            </Typography>
          ) : (
            ""
          )}
          {children}
        </Stack>
      </Box>
    </>
  );
};
