
// import{ useRef, useState } from "react";
import {Box} from "@mui/material";
import { Stack } from "@mui/system";
// import { useCommunity } from "../../hooks/useCommunity";
// import { useCategories } from "../../hooks/useCategories";
// import { ExploreCreateCommunities2 } from "./ExploreCreateCommunities2";
import { CreateCommunity } from "../community/CreateCommunity";
import { Helmet } from "react-helmet";



const ExploreCreateCommunities = () => {
//   // const categories = useCategories();
//   const { isLoading, isCommunityExist } = useCommunity();
//   const [isCommunityAvailable, setIsCommunityAvailable] = useState<boolean>(false);
//   const [page, setPage] = useState(1);
//   const [selectedCommunity, setSelectedCommunity] = useState(false);
//   const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
//   // const [communityNameCreate, setSelectedcommunityNameCreate] = useState<string | null>(null);
//   const communityName = useRef<string>("");
//   // const enteredCommunnity: string = "";
//   const [isCategorySelected, setIsCategorySelected] = useState(false);




//   const checkCommunity = async () => {
//     setSelectedCommunity(false);


//     if (communityName.current) {
//       console.log("CURRENT COMM NAME:", communityName.current);
//       const data = await isCommunityExist(communityName.current);
//       console.log("RES:", data);

//       if (typeof data === 'boolean') {
//         setSelectedCommunity(data);

//         // Enable the "Save & Continue" button if community is available and category is selected
//         if (data && selectedCategory) {
//           setIsCommunityAvailable(true);
//         } else {
//           setIsCommunityAvailable(false);
//         }
//       }
//     }
//   };

//  // Function to check if a community is available and a category is selected
// const isSaveButtonEnabled = () => {
//   if (page === 1) {
//     return isCommunityAvailable && selectedCategory !== null;
//   } else if (page === 2) {
//     // Add additional conditions if needed for page 2
//     // For example, check if necessary data for page 2 is filled
//     return true; // Return true or false based on your conditions
//   }
//   return false;
// };

// // Function to enable the "Save & Continue" button when community is available and category is selected
// const enableSaveButton = () => {
//   if (selectedCommunity && selectedCategory) {
//     setIsCommunityAvailable(true);
//   } else {
//     setIsCommunityAvailable(false);
//   }
// };

// // Function to handle category selection
// const handleCategorySelection = (categoryId: string) => {
//   setSelectedCategory((prevCategory) =>
//     prevCategory === categoryId ? null : categoryId
//   );
//   setIsCategorySelected(true);
//   enableSaveButton();
// };

  
  
  
  return (
    <>     
    <Helmet>
      <title>Create Community - Communn - Build & Manage your community</title>
      <meta
          name="description"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta
          name="keywords"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
      </Helmet>
    <Box>
        {/* <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{ backgroundColor: "#F4F4F4", borderRadius: "30px", p: 2 }}
          >
            <Stack direction={"column"}>
              <Stack>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 500,
                    color: "#454545",
                    fontFamily: "Inter",
                    ml: 3,
                    mb: 2,
                  }}
                >
                  Create a community
                </Typography>
              </Stack> */}

              {/* {page === 1 && (
                <>
                  <Box sx={{ ml: 3 }}>
                    <Grid container>
                      <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Box
                          sx={{
                            padding: "10px",
                            border: "1px solid #C6C6C6",
                            // borderColor: "business.main",
                            // backgroundColor: "common.white",
                            display: "inline-block",
                            borderRadius: "10px",
                            "& > input": {
                              border: "none",
                              background: "transparent",
                              fontSize: "24px",
                              fontWeight: 400,
                            },
                            "& > input:focus-visible": {
                              outline: "none !important",
                            },
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{
                              display: "inline-block",
                              fontWeight: 400,
                              ml: 2,
                              color: "#D3D3D3",
                              fontSize: "18px",
                            }}
                          >
                            communn.com/
                          </Typography>
                          <input
                            type="text"
                            onChange={(e) =>
                              (communityName.current = e.target.value)
                            }
                            placeholder="Enter your community"
                            style={{
                              fontSize: "18px",
                              fontWeight: "600",
                              color: "#1A1A1A",
                            }}
                          />
                        </Box>

                      </Grid>
                      <Grid item xs={12} md={3} lg={3} xl={3}>
                        <Button
                          fullWidth

                          variant="contained"
                          color="economy"
                          onClick={checkCommunity}
                          sx={{
                            // ml: 2, mb: 1.6,
                            borderRadius: "10px",
                            fontSize: "18px",
                            fontWeight: 500,
                            textTransform: "capitalize",
                            backgroundColor: "#50A1CA",
                            color: "#ffffff",
                            boxShadow: "none",

                            mt: { xs: 2, sm: 0, md: 0, lg: 0 }


                          }}
                        >
                          Check Availability
                        </Button>

                      </Grid>

                    </Grid>

                    {communityName.current ? (
                      selectedCommunity && selectedCommunity === true ? (
                        <Typography
                          variant="body1"
                          sx={{ color: "#7FC41B", fontSize: "18px", mt: "5px" }}
                        >
                          ☑&nbsp;
                          {`${communityName.current} is available.`}
                        </Typography>
                      ) : (
                        <Typography
                          variant="body1"
                          sx={{ color: "red", fontSize: "18px", mt: '5px' }}
                        >
                          🚫{`${communityName.current} is not available.`}
                        </Typography>
                      )
                    ) : null}

                  </Box>

                  <Stack
                    spacing={1}
                    sx={{
                      pt: 4,
                      ml: 3
                    }}
                  >
                    <Typography variant="h6" sx={{
                      fontSize: "20px",
                      fontWeight: 500,
                      color: "#454545",
                      fontFamily: "Inter",     
                    }}>Choose categories</Typography>
                    <Typography variant="body2">
                      Select atleast one, best suitable for you.
                    </Typography>
                    <Grid container direction="row" spacing={1} sx={{ width: "100%" }}>
                      {categories.list.map((c: ICategory) => (
                        <Button
                          key={c.name}
                          sx={{
                            fontSize: "18px",
                            fontWeight: selectedCategory === c.name ? "600" : "400",
                            p: "7px 20px",
                            border: selectedCategory === c.name ? "1px solid #50A1CA" : "1px solid #E0E0E0",
                            borderRadius: "10px",
                            color: selectedCategory === c.name ? "#50A1CA" : "#565656",
                            backgroundColor: selectedCategory === c.name ? "#ffffff" : "#ffffff",
                            m: 1,
                            textTransform: "capitalize",
                          }}
                          onClick={() => {
                            handleCategorySelection(c.name);  
                          }}  
                        >
                          {c.name}
                        </Button>
                      ))}
                    </Grid>
                    <Box sx={{ pt: 5, ml: 3 }}>
                      <Button
                        size="large"
                        onClick={() => setPage(2)}
                        variant="contained"
                        color="economy"
                        disabled={!isSaveButtonEnabled()}
                        sx={{
                          background:
                            "linear-gradient(to right bottom, #50a1ca, #3b9b7f)",
                          textTransform: "capitalize",
                          borderRadius: "5px",
                          width: "250px",
                          fontFamily: "Inter",
                          ml: 3,
                        }}
                      >
                        Save & Continue
                      </Button>
                    </Box>
                  </Stack>
                </>
              )}
              {page === 2 && (
                <ExploreCreateCommunities2 communityName={communityName.current}  selectedCategory={selectedCategory}
                 />
              )}
            </Stack> */}

            <Stack>
              <CreateCommunity  communitydata={null} />
            </Stack>

            {/* <Stack direction={"row"} justifyContent={"center"} mt={2}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to="#" style={{ color: "#A0A0A0", fontSize: "12px" }}>
                  FAQs
                </Link>
                <Link to="#" style={{ color: "#A0A0A0", fontSize: "12px" }}>
                  Terms of Services
                </Link>
                <Link to="#" style={{ color: "#A0A0A0", fontSize: "12px" }}>
                  Privacy Policy
                </Link>
                <Link to="#" style={{ color: "#A0A0A0", fontSize: "12px" }}>
                  Contact Us
                </Link>
              </Breadcrumbs>
            </Stack> */}
          {/* </Grid>
        </Grid> */}
      </Box>
    </>
  );
};
export default ExploreCreateCommunities;

