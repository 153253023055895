const StaticValues = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  INVALID: "Invalid",
  ADMIN: "Admin",
  USER: "Member",
  MODERATOR: "Moderator",
  YES: "Yes",
  NO: "No",
  YEAR: "Year",
  MONTH: "Month",
  DAY: "Day",
  WEEK: "Week",
  YEARLY: "Yearly",
  MONTHLY: "Monthly",
  WEEKLY: "Weekly",
  DAILY: "Daily",
  QUARTERLY: "Quarterly",
  HALF_YEARLY: "Half Yearly",
  ONE_TIME: "One Time",
  FREE: "Free",
  PAID: "Paid",
  PUBLIC: "Public",
  PRIVATE: "Private",
  BUSYNESS: "Business",
  Business: "Business",
  TECHNOLOGY: "Technology",
  "Role Type": "Role",
  INVITED: "Invited",
  IN_ACTIVE: "In_Active",
  userCancelled: "Cancelled",
  FAILURE: "Failed",
  SUCCESS: "Success",
  USERCANCELLED: "Cancelled",
  DROPPED: "Dropped",
  SUPERADMIN: "Superadmin",
  dayly: "Daily",
  weekly: "Weekly",
  monthly: "Monthly",
  quarterly: "Quarterly",
  half_yearly: "Half Yearly",
  one_time: "One Time",
  yearly: "Yearly",
"REJECT":"Reject",
"NOT_PAID"  :"Not Paid",
"EXPIRED" : "Expired",
"CANCELLED": "Cancelled",
"PAID_BY_CASH" : "Paid By Cash",
"Settled" : "Settled",
};
export const getStaticValue = (key: string) => {
  //console.log(key);
  return StaticValues[key as keyof typeof StaticValues]; // Use type assertion
};
