import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { AuthContext, IAuthContext } from "../contexts/Auth.context";
import { createSubscriptionSequences, fetchSubscriptionByCommunityAndPlanId, fetchSubscriptionByCommunityId } from "../services/subscription.service";

export const useSubscription = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);



  const subscriptionListByCommunityId = async (id: string) => {
    try {
      const response = await fetchSubscriptionByCommunityId(getAccessToken(), id);
      if (response.status === 200) {
        return response.data;
      }
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn't fetch Subscription List", {
        variant: "error",
        autoHideDuration: 3000
      });
    }
  };


  const subscriptionByCommunityIdAndPlanId = async (id: string, planId: string) => {
    try {
      const response = await fetchSubscriptionByCommunityAndPlanId(getAccessToken(), id, planId);
      console.log(response, "SUBSCRIPTION");
      if (response.status === 200) {
        return response.data;
      }
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn't fetch Subscription List", {
        variant: "error",
        autoHideDuration: 3000
      });
    }
  };



  const createSubscriptionSequencesByPlanAndCommunityId = async (communityId: string, planId: string) => {
    setIsLoading(true);
    try {
      const response = await createSubscriptionSequences(getAccessToken(), communityId, planId);
      console.log("response", response);
      if (response.status === 200) {
        return response;
      }
      return response;
    } catch (err) {
      enqueueSnackbar("Couldn't create Subscription", {
        variant: "error",
        autoHideDuration: 3000,
      });
      return err;
    }
    finally {
      setIsLoading(false);
    }
  };


  return {
    isLoading,
    subscriptionListByCommunityId,
    subscriptionByCommunityIdAndPlanId,
    createSubscriptionSequencesByPlanAndCommunityId
  };
};



