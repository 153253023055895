import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { AuthContext, IAuthContext } from "../contexts/Auth.context";
import { generateAiPostSuggestions } from "../services/postAi.service";

export const useAiPostSuggestions = () => {
    const { getAccessToken } = useContext<IAuthContext>(AuthContext);
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getAiPostSuggestions = async (community_name: string) => {
        setIsLoading(true);
        const response = await generateAiPostSuggestions(community_name);
        setIsLoading(false);
        if (response.status === 200) {
            return response;
        } else if (response.status === 302) {
            return response;
        } else {
            enqueueSnackbar("Fetching communities failed", {
                variant: "error",
                autoHideDuration: 3000,
            });
        }
    };

    return {
        isLoading,
        getAiPostSuggestions,
    };
};
