// import { red } from "@mui/material/colors";

export const styles = {
  "& .expanded": {
    padding: "6px",
    marginRight: "15px",
    marginLeft: "15px",
    backgroundColor: "#ffffff",
  },
  "& .collapsed": {
    "& .menu-item>*": {
      flexGrow: 1,
    },
    "& .nav-link": {
      border: "none",
      borderRadius: "0px",
    },
  },
  "&  .collapsed": {
    margin: "8px",
    backgroundColor: "white",
  },
  "& .nav-link .collapsed": {
    padding: "8px",
  },

  "& .nav-link": {
    // border: "1px solid #E0E0E0",
    borderRadius: "4px",
    padding: "8px",
    color: "#515151",
    fontSize: "12px",
    cursor: "pointer",
    textDecoration: "none",

    "&.active": {
      backgroundColor: "#2952A20A",
      color: "#50A1CA",
      fontWeight: "600",
      borderRadius: "8px",
      "& svg": {
        //  color: "#50A1CA ",
        fill: "#50A1CA",
        // margin: "8px",
      },
    },
    "&:hover": {
      backgroundColor: "#2952A20A",
      color: "#50A1CA",
      borderRadius: "8px",
      "& svg": {
        color: "#50A1CA",
      },
    },
    "& svg": {
      color: "gray",
      marginRight: 1,
    },
    "& ul": {
      fontSize: "12px",
      lineHeight: 2,
    },
    "& menu-item": {
      backgroundColor: "red",
    },
  },
};
