import { Box, Grid, Stack, Tab, Typography } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AllClass from './Components/AllClasses';
import React from 'react';
import PastClasses from './Components/PastClasses';

const LiveClass = () => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <Stack direction={'row'} flexGrow={1} spacing={1}>
        <Grid item xs={12} sm={6} md={6} lg={8} xl={8}>
          <Typography
            sx={{
              fontSize: { xs: "15px", md: "20px" },
              fontWeight: 500,
              color: "#50A1CA",
              fontFamily: "Inter",
              textAlign: "center",
            }}
          >
            Classes
          </Typography>
        </Grid>
      </Stack>

      <Box sx={{ width: '100%', mt: 2 }}>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              backgroundColor: 'white',
              borderRadius: '5px',
            }}
          >
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label="All Classes"
                value="1"
                sx={{
                  fontSize: '15px',
                  fontWeight: 400,
                  textTransform: 'capitalize',
                  color: '#92929D',
                  '&.Mui-selected': {
                    color: '#50A1CA',
                    fontWeight: 600,
                  },
                }}
              />
              <Tab
                label="Past Classes"
                value="2"
                sx={{
                  fontSize: '15px',
                  fontWeight: 400,
                  textTransform: 'capitalize',
                  color: '#92929D',
                  '&.Mui-selected': {
                    color: '#50A1CA',
                    fontWeight: 600,
                  },
                }}
              />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ px: 0 }}>
            <AllClass />
          </TabPanel>
          <TabPanel value="2" sx={{ px: 0 }}>
            <PastClasses />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default LiveClass;
