import { useAuth } from "../hooks/useAuth";
import { AuthContext } from "./Auth.context";

const AuthProvider = ({ children }: any) => {
  const { 
    user,
    isAuthenticated,
    loading,
    login,
    logout,
    getAccessToken,
    autoLogin,
    autoCreate,
    getSelectedCommunityId,
    autoCreateLoginPopup,
    autoLoginForLoginPopup,
    loginPopUp} = useAuth();
  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        isAuthenticated,
        getAccessToken,
        login,
        logout,
        autoLogin,
        autoCreate,
        getSelectedCommunityId,
        autoLoginForLoginPopup,
        autoCreateLoginPopup,
        loginPopUp
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
