import { Card, CardMedia, Paper, Stack, Typography } from "@mui/material";

interface NoDataProps {
    message: string;
    image: string;
}

const NoData = ({ image, message }: NoDataProps) => (



    <Paper elevation={0}>
        <Stack
            spacing={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ paddingBottom: "10px", p: 2 }}
        >
            <Card elevation={0}>
                <CardMedia
                    component="img"
                    alt="No posts"
                    image={image}
                    sx={{
                        objectFit: "cover",
                        width: "100%", // Set the width to 100% to fill the container
                        height: { xs: "40%", md: "40vh" }, // Set the height to 100% to fill the container
                    }}
                />
            </Card>
            <Typography
                sx={{
                    color: "#777777",
                    fontSize: { xs: "13px", md: "15px" },
                    fontWeight: "600",
                    fontFamily: "Montserrat",

                }}
            >
                {message}
            </Typography>
        </Stack>
    </Paper>
);

export default NoData;
