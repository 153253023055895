import { useEffect } from 'react';

const EnablePopupsNotification = () => {
    useEffect(() => {
        const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
        const isSafari = isIOS && navigator.userAgent.includes("Safari") && !navigator.userAgent.includes("CriOS") && !navigator.userAgent.includes("FxiOS");
        const notificationDismissed = localStorage.getItem("popupNotificationDismissed");

        if (isSafari && !notificationDismissed) {
            const userResponse = window.confirm("🚨 Reminder: To complete your payment, please enable popups in Safari settings:\n\n1. Open the Settings app.\n2. Scroll down and tap 'Safari.'\n3. Under 'General,' toggle off 'Block Pop-ups.'\n\nClick 'OK' if you have enabled popups and don't want to see this message again.");

            if (userResponse) {
                localStorage.setItem("popupNotificationDismissed", "true");
            }
        }
    }, []);

    return null;
};

export default EnablePopupsNotification;
