import { format } from "date-fns";
import { IDate } from "../models/general.model";

export const formatDate = (date: Date): string => {
    return format(date, "do MMM. yyyy h:mma");
};

export const dateFormat = (date: string) => {
  if(!date || date?.length===0)
  return null

  const updatedDate = new Date(date);
  // Create an object of type IDate
  const formattedDate: IDate = {
    date: `${updatedDate.getDate()} ${updatedDate.toLocaleString("default", {
      month: "short",
    })}. ${updatedDate.getFullYear()}`,
    time: `${updatedDate.toLocaleString("default", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })}`,
  };

  return formattedDate;
};


