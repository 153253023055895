import {
  Button,
  Card,
  Stack,
  Typography,
  Tooltip,
  Avatar,
} from "@mui/material";
import { ICommunity } from "../../../models/communities.modal";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { KingIcon } from "../../../assets/icons";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useState } from "react";
import CommunityPreview from "../CommunityPreview/CommunityPreview";
import {
  ADMIN_APP_BASE_URL,
  USER_APP_BASE_URL,
} from "../../../configurations/url.config";
import { useSnackbar } from "notistack";

export const AdminCommunities = (community: ICommunity) => {
  const [copied, setCopied] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [link, setLink] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  // const navigate = useNavigate();

  const handlePreviewCommunity = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // const handleButtonClick = () => {
  //   navigate('/home', { state: { id: selectedCommunity?.selectedCommunity?._id } });
  // };

  // const handleCopyLink = () => {
  //   const labelContent = community?._id;
  //   navigator.clipboard
  //     .writeText(labelContent)
  //     .then(() => {
  //       setCopied(true);
  //     })
  //     .catch((error) => {
  //       // console.error("Error copying text: ", error);
  //     });
  // };

  const handleCopyLink = () => {
    if (community?.status === "PUBLISHED") {
      const labelContent = `${USER_APP_BASE_URL}/community/${community?.name?.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${community?._id}`;
      navigator.clipboard
        .writeText(labelContent)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 3000);
          enqueueSnackbar("Link copied successfully", {
            variant: "success",
            autoHideDuration: 3000,
          });
        })
        .catch((error) => {
          console.error("Error copying text: ", error);
        });
    } else {
      // Handle the case when the status is not "PUBLISHED"
      setLink(`${ADMIN_APP_BASE_URL}/communities/build/${community?._id}`);
      enqueueSnackbar(
        "Cannot copy link & Share the for Non-Published Communities",
        { variant: "error", autoHideDuration: 3000 }
      );
    }
  };
  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${community?.name?.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${community?._id}`
  )}`;
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${community?.name?.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${community?._id}`
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    community?.url
  )}&text=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${community?.name?.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${community?._id}`
  )}`;
  const linkedinShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
    community?.url
  )}&title=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${community?.name?.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${community?._id}`
  )}`;

  const navigateToAdminFlow = () => {
    const token = localStorage.getItem("access-token");
    const id = community?._id;
    const url = `${ADMIN_APP_BASE_URL}/login?communityId=${id}&token=${token}`;
    window.open(url, "_blank");
  };

  return (
    <>
      <Stack spacing={2} p={1}>
        <Card
          sx={{
            textAlign: "center",
            borderRadius: "10px",
            p: 2,
            m: 1,
            minHeight: { xs: "20vh", md: "27vh" },
          }}
        >
          <Stack
            direction={"row"}
            alignContent={"space-evenly"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} alignContent={"center"}>
              {" "}
              {community?.type === "PRIVATE" ? (
                <LockOutlinedIcon sx={{ color: "#838383", fontSize: "18px" }} />
              ) : (
                <Stack sx={{ height: "25px" }}></Stack>
              )}
              {community?.collectSubscription === "YES" ? (
                <KingIcon sx={{ color: "#838383" }} />
              ) : (
                <Stack sx={{ height: "25px" }}></Stack>
              )}
            </Stack>

            <Stack>
              <BootstrapTooltip title="Info" placement="left">
                <InfoOutlinedIcon
                  onClick={handlePreviewCommunity}
                  sx={{ color: "#838383", fontSize: "18px", cursor: "pointer" }}
                ></InfoOutlinedIcon>
              </BootstrapTooltip>
            </Stack>
          </Stack>
          {/* Community Logo */}
          <Stack display="flex" alignItems="center" justifyContent="center">
            <Avatar
              className="product--image"
              src={community?.logo}
              alt="community-profile"
              sx={{
                objectFit: "cover",
                width: "60px",
                height: "60px",
                borderRadius: "100px",
              }}
            />
          </Stack>
          {/* Render name with conditional check */}
          {community?.name ? (
            <Typography
              variant="h2"
              sx={{
                fontSize: "12px",
                color: "#4D4C4C",
                fontWeight: "600",
                mt: 0.5,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "100%", // Ensure the text doesn't overflow the container
              }}
            >
              {community.name.length > 20
                ? `${community.name.slice(0, 20)}...`
                : community.name}
            </Typography>
          ) : (
            <Stack sx={{ height: "15px" }}></Stack>
          )}

          {/* Render city with conditional check */}
          {community?.city ? (
            <Typography
              sx={{ fontSize: "10px", color: "#878787", fontWeight: "500" }}
            >
              {community?.city.length > 20
                ? `${community?.city.slice(0, 20)}...`
                : community?.city}
            </Typography>
          ) : (
            <Stack sx={{ height: "15px" }}></Stack>
          )}

          {/* Render members count with conditional check */}
          {community?.members?.length !== undefined ? (
            <Typography
              sx={{ fontSize: "11px", color: "#353535", fontWeight: "500" }}
            >
              {community?.members?.length}{" "}
            </Typography>
          ) : (
            <Stack sx={{ height: "15px" }}></Stack>
          )}
          <Stack
            direction={"row"}
            alignContent={"center"}
            justifyContent={"center"}
            spacing={2}
            mt={1}
          >
            <Button
              variant="contained"
              size="small"
              sx={{
                background:
                  "linear-gradient(to right bottom, #50a1ca, #3b9b7f)",
                textTransform: "capitalize",
                height: "25px",
                color: "#ffffff",
                boxShadow: "none",
              }}
              onClick={navigateToAdminFlow}
            >
              <Typography
                sx={{ color: "#ffffff", fontSize: "13px", fontWeight: "500" }}
              >
                Go
              </Typography>
            </Button>
          </Stack>
        </Card>
      </Stack>

      <CommunityPreview
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        logo={community.logo}
        id={community?._id}
        title={community?.name}
        city={community?.city}
        status={community?.status}
        members={community?.members.length || []}
        totalmembers={community?.totalMembers}
        type={community?.type}
        description={community?.description}
        handleCopyLink={handleCopyLink}
        copied={copied}
        whatsappShareUrl={whatsappShareUrl}
        facebookShareUrl={facebookShareUrl}
        linkedinShareUrl={linkedinShareUrl}
        twitterShareUrl={twitterShareUrl}
        coverImageSrc={community?.banner}
        link={link}
      />
    </>
  );
};
