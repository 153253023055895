import React, { useContext, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AuthContext } from "../../../contexts/Auth.context";
import { useSnackbar } from "notistack";
import { BASE_URL, USER_APP_BASE_URL } from "../../../configurations/url.config";
import { auth, google, facebook } from "../../../configurations/fireBase"
import { signInWithPopup } from "@firebase/auth";
import axios from "axios";
import { useDispatch } from "react-redux";
import { loadSelectedCommunity } from "../../../store/slices/CommunitySlice";
import { joinCommunity } from "../../../services/communities.service";
import { sendRequestToJoin } from "../../../services/requests.service";
import SendRequestCommunityPreview from "../../ExploreCommunities/SendRequestCommunityPreview";
// import { ICommunity } from "../../../models/communities.modal";

// << ---- Class or interface definition  starts ---- >>
interface FormValues {
  firstName: string;
  lastName: string;
  emailId: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
  isPhoneVerified: boolean;
  userName: string;
}


interface RegisterPopUpProps {
  handleClose: () => void;
  phoneNumber: string
  communityData: any;
}
const RegisterPopUp: React.FC<RegisterPopUpProps> = ({ handleClose, phoneNumber, communityData }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const communityId = useParams();

  // console.log(communityData, "communityData");

  const authContex = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isModalOpen, setModalOpen] = useState(false);
  const [createShowPassword, setCreateShowPassword] = React.useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [userNames] = useState<string[]>([]);
  // const [userName,setUserName]=useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [formValues, setFormValues] = useState<FormValues>({
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: phoneNumber,
    password: "",
    confirmPassword: "",
    isPhoneVerified: true,
    userName: "",
  });


  const [formErrors, setFormErrors] = useState<FormValues>({
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    isPhoneVerified: true,
    userName: "",
  });


  const handleClickCreateShowPassword = () => {
    setCreateShowPassword((show) => !show);
  }
  const handleClickConfirmShowPassword = () => {
    setConfirmShowPassword((show) => !show);
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCopyLink = () => {
    const labelContent = `${USER_APP_BASE_URL}/communities/${communityData?._id}/about`;

    navigator.clipboard
      .writeText(labelContent)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };


  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${communityData?._id}/about`
  )}`;

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${communityData?._id}/about`
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${communityData?._id}/about`
  )}`;
  const linkedinShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${communityData?._id}/about`
  )}`;



  const handleButtonClick = () => {
    const { _id } = communityData;
    navigate('/home', { state: { _id } });
    dispatch(loadSelectedCommunity(communityData));
  };





  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value })
  }

  const handleUserName = (e: any) => {
    const { outerText } = e.target;
    // setUserName(outerText);
    setFormValues({ ...formValues, userName: outerText })
  }

  const handleCloseSnackbar = () => {
    setErrorMessage(null);
  };

  const generateUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    //const { name, value } = event.target;
    if (formValues.lastName.length >= 0) {
      let count = 4;
      while (count > 0) {
        let uname =
          formValues.firstName +
          formValues.lastName.slice(0, 3) +
          Math.floor(1000 + Math.random() * 9000);
        userNames.push(uname.toLocaleLowerCase());
        // count = count -1;
        --count;
      }
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const errors: FormValues = {
      firstName: "",
      lastName: "",
      emailId: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      isPhoneVerified: true,
      userName: "",
    };
    if (!formValues.firstName) {
      errors.firstName = "Please enter your First Name";
    } else if (!/^[A-Z,a-z ,.'-]+$/.test(formValues.firstName)) {
      errors.firstName = "Special characters not allowed";
    } else if (
      formValues.firstName.length < 4 ||
      formValues.firstName.length > 50
    ) {
      errors.firstName = "Please enter min 3 or max 50 characters";
    }
    if (!formValues.lastName) {
      errors.lastName = "Please enter your Last Name";
    } else if (!/^[A-Z,a-z ,.'-]+$/.test(formValues.lastName)) {
      errors.lastName = "Special characters not allowed";
    } else if (
      formValues.lastName.length < 1 ||
      formValues.lastName.length > 50
    ) {
      errors.lastName = "Please enter min 1 Characters";
    }
    if (!formValues.emailId) {
      errors.emailId = "Please enter your Email ID";
    } else if (!/\S+@\S+\.\S+/.test(formValues.emailId)) {
      errors.emailId = "Please enter a valid Email ID";
    }
    if (!formValues.phoneNumber) {
      errors.phoneNumber = "Please enter Mobile Number";
    } else if (!/^\d{10}$/.test(formValues.phoneNumber)) {
      errors.phoneNumber = "Please enter valid Mobile Number";
    }
    if (!formValues.password) {
      errors.password = "Please enter a password";
    } else if (formValues.password.length < 8) {
      errors.password = "Password must be at least 8 characters";
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,15}$/.test(
        formValues.password
      )
    ) {
      errors.password = "Password must be in valid format";
    }
    if (!formValues.confirmPassword) {
      errors.confirmPassword = "Re-Enter Password";
    } else if (formValues.password !== formValues.confirmPassword) {
      errors.confirmPassword = "Password mismatched";
    }
    if (!formValues.userName) {
      errors.userName = "Please enter Username";
    } else if (formValues.userName.length < 0) {
      errors.userName = "Please enter a Username";
    }

    setFormErrors(errors);

    // if ((formValues.firstName) && (formValues.lastName) && (formValues.email) && (formValues.phoneNumber) && (formValues.password) && (formValues.confirmPassword)) {
    //   alert('signupsuccesfully')
    // }

    const data = JSON.stringify(formValues);
    const response: any = await authContex.autoCreate(data);

    try {
      setIsLoading(true);
      if (response.status === 200) {
        if (communityData?.type === "PUBLIC") {
          setModalOpen(true);

          const result = await joinCommunity(
            response?.data.user.token.accessToken,
            communityData._id ?? "",
          );
          navigate(`/community/${communityData?._id}/about`);
          dispatch(loadSelectedCommunity(communityData));
          if (result.status === "success") {
            navigate(`/community/${communityData?._id}/about`);
            dispatch(loadSelectedCommunity(communityData));
          } else {
            navigate(`/communities/${communityData?._id}/about`);
            dispatch(loadSelectedCommunity(communityData));
          }
          handleClose();

        }
        else if (communityData.type === "PRIVATE") {
          setModalOpen(true);
          const payload = {
            community: data,
          };
          const result = await sendRequestToJoin(
            response?.data.user.token.accessToken,
            payload
          );
          if (result.status === 201) {
            // enqueueSnackbar("Request Sent Successfully to Join Community", {
            //   variant: "success",
            // });
            navigate("/explore-communities");

          }
        }
        if (communityId.id) {

          navigate(`/communities/${communityId.id}/about`);
          dispatch(loadSelectedCommunity(communityData));
        } else {
          navigate("/explore-communities", {
            state: { id: 2, data: response.data },
          });
          enqueueSnackbar("Account Created successfully", {
            variant: "success",
          });
        }
      } else if (response?.response.status === 500) {
        // console.log("response", response);
        //setError('Your not found!');
        navigate("/register");
      } else if (
        response?.response.data &&
        Array.isArray(response.response.data)
      ) {
        // Handle array of errors
        const errorMessages = response.response.data.map(
          (error: { msg: string }) => error.msg
        );
        setErrorMessage(errorMessages.join(", ")); // Set the error message
      } else {
        console.log(response.response.data.message, "error");
        setErrorMessage(response.response.data.message);
      }
    } catch (error) {
      console.log("error");
    } finally {
      setIsLoading(false);
    }
    //setLoading(false);
    // const data1 = JSON.parse(data);
    //console.log(data);
    // console.log(data1)
  };

  const SocialLogin = async (login: any) => {
    const response: any = await signInWithPopup(auth, login)
    const socialProfile = response['_tokenResponse'];
    const user = response.user;
    const autoCreateData = {
      firstName: socialProfile.firstName,
      lastName: socialProfile.lastName,
      emailId: socialProfile.email,
      isEmailVerified: socialProfile.emailVerified,
      avatar: socialProfile.photoUrl,
      phoneNumber: user?.phoneNumber

    };

    axios.post(`${BASE_URL}/auth/social-login`, autoCreateData)
      .then(async (response) => {
        const { user } = response?.data
        console.log("User Info:", user)
        const autologinResponse = await authContex.autoLogin("", user?.emailId);
        handleLoginResponse(autologinResponse);

      })
      .catch((error) => {
        console.error("Auto login error:", error);
      });

  }

  // const handleGoogleLogin = async () => {
  //   SocialLogin(google);
  // };

  // const handleFacebookLogin = async () => {
  //   SocialLogin(facebook);
  // }
  const handleLoginResponse = async (response: any) => {
    //console.log(response);
    if (response?.status === 200) {
      console.log(response.status)
      const { user, adminCommunities } = response?.data;
      if (adminCommunities && adminCommunities.length > 0) {
        console.log("INSIDE 1")
        navigate("/home", { state: { id: user?.community } });
      } else if (!adminCommunities || adminCommunities.length === 0) {
        console.log("INSIDE 2")
        navigate("/explore-communities", {
          state: { id: 2, data: user },
        });
      } else if (user?.community) {
        navigate("/dashboard", { state: { id: 3, data: user } });
      }
    } else if (response.status === 403) {
      // setError(INACTIVE_USER_MSG);
      // setOtpSuccessMessage(EMPTY_STRING);
    } else if (response?.status === 404) {
      // setError(USER_NOT_FOUNT_MSG);
      // navigate("/register", { state: { id: 1, phoneNumber: phoneNumber } });
    }
    // setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Register - Communn - Build & Manage your community</title>
        <meta
          name="description"
          content="Automate Community Management with AI-Powered
        Subscription effortlessly
        Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Automate Community Management with AI-Powered
        Subscription effortlessly
        Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta
          name="keywords"
          content="Automate Community Management with AI-Powered
        Subscription effortlessly
        Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
      </Helmet>
      <>
        <Box
          component={"img"}
          src={require("./../../../assets/images/LoginMbg.png")}
          sx={{
            height: "100px",
            width: "100%",
            objectFit: "fill",
            display: { xs: "block", md: "block" },
          }}
          alt=""
        ></Box>

        <IconButton
          aria-label="close"
          onClick={() => handleClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ color: "white" }} />
        </IconButton>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Stack
              sx={{
                overflow: "auto",
                justifyContent: {
                  xs: "flex-start",
                  sm: "flex-start",
                  md: "center",
                  lg: "center",
                },
              }}
              alignItems={"center"}
            >
              <Box>
                {/* <Stack
                  direction={"row"}
                  sx={{
                    pr: 10,
                    pl: 2,
                    // pt: 0.5,
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <ArrowBackIcon
                    sx={{ color: "#1C1B1F", cursor: "pointer" }}
                    onClick={() =>
                      handleClose()
                    } 
                  />
                </Stack> */}
                <Box
                  component={"form"}
                  sx={{
                    pr: { xs: 5, sm: 5, md: 10, lg: 10, xxl: 10 },
                    pl: { xs: 5, sm: 5, md: 10, lg: 10, xxl: 10 },
                  }}
                >
                  <Stack direction={"row"} justifyContent={"center"}>
                    <Box

                      component={"img"}
                      src={require("./../../../assets/images/Communn-new-logo.png")}
                      alt=""
                      width={"40%"}
                      sx={{ pb: 2 }}
                    />
                  </Stack>
                  {/* <Stack direction={"row"} justifyContent={"center"}>
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#424242",
                        fontFamily: "Inter",
                        pb: 1,
                        pt: 2,
                      }}
                    >
                      Register
                    </Typography>
                  </Stack> */}
                  <Stack>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          onChange={handleChange}
                          value={formValues.firstName}
                          fullWidth
                          label="Name"
                          size="small"
                          type="text"
                          name="firstName"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{
                                  cursor: "pointer",
                                  backgroundColor: "#E5E3EF",
                                  borderRadius: "100px",
                                }}
                              >

                              </InputAdornment>
                            ),
                            style: {
                              borderRadius: "30px",
                              backgroundColor: "#ffffff",
                            },
                          }}
                        />
                        {formErrors.firstName && (
                          <Stack
                            sx={{
                              fontSize: "12px",
                              color: "red",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            {formErrors.firstName}
                          </Stack>
                        )}
                      </Grid>
                      {/* <Grid item xs={12} sm={6}>
                        <TextField
                          value={formValues.lastName}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleChange(e);
                            generateUserName(e);
                          }}
                          fullWidth
                          label="Last Name"
                          type="text"
                          name="lastName"
                          size="small"
                        ></TextField>
                        {formErrors.lastName && (
                          <Stack
                            sx={{
                              fontSize: "12px",
                              color: "red",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            {formErrors.lastName}
                          </Stack>
                        )}
                      </Grid> */}
                    </Grid>
                  </Stack>

                  {/* {userNames.length > 0 && (
                    <Stack>
                      <Grid container spacing={2} sx={{ pt: 4 }}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontWeight: 400,
                              pb: 1,
                            }}
                          >
                            Available usernames
                          </Typography>

                          <Grid container spacing={1}>
                            {userNames.slice(0, 8).map((e, index) => (
                              <Grid
                                item
                                key={index}
                                xs={6}
                                sm={6}
                                md={6}
                                lg={3}
                              >
                                <Chip
                                  label={e}
                                  component="a"
                                  sx={{
                                    color: "#50A1CA",
                                    border: "1px solid #50A1CA",
                                    marginBottom: 1,
                                  }}
                                  variant="outlined"
                                  onClick={(e: any) => handleUserName(e)}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Stack>
                  )} */}

                  {/* <Stack>
                    <Grid container spacing={2} sx={{ pt: 4 }}>
                      <Grid item xs={12}>
                        <TextField
                          value={formValues.userName}
                          fullWidth
                          label="Create User Name"
                          size="small"
                          name="userName"
                          id="outlined-size-userName"
                          onChange={handleChange}
                          autoComplete="userName"
                        />
                        {formErrors.userName && (
                          <Stack
                            sx={{
                              fontSize: "12px",
                              color: "red",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            {formErrors.userName}
                          </Stack>
                        )}
                      </Grid>
                    </Grid>
                  </Stack> */}
                  <Stack>
                    <Grid container spacing={2} sx={{ pt: 4 }}>
                      <Grid item xs={12}>
                        <TextField
                          value={formValues.phoneNumber}
                          onChange={handleChange}
                          fullWidth
                          size="small"
                          name="phoneNumber"
                          label="10-Digit Phone Number"
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{
                                  cursor: "pointer",
                                  backgroundColor: "#E5E3EF",
                                  borderRadius: "100px",
                                }}
                              >

                              </InputAdornment>
                            ),
                            style: {
                              borderRadius: "30px",
                              backgroundColor: "#ffffff",
                            },
                          }}

                        />
                        {formErrors.phoneNumber && (
                          <Stack
                            sx={{
                              fontSize: "12px",
                              color: "red",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            {formErrors.phoneNumber}
                          </Stack>
                        )}
                      </Grid>
                    </Grid>
                  </Stack>

                  <Stack>
                    <Grid container spacing={2} sx={{ pt: 4 }}>
                      <Grid item xs={12}>
                        <TextField
                          value={formValues.emailId}
                          onChange={handleChange}
                          fullWidth
                          label="Email"
                          type="email"
                          size="small"
                          name="emailId"
                          id="outlined-size-emailId"
                          autoComplete="email"
                          InputProps={{

                            endAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{
                                  cursor: "pointer",
                                  backgroundColor: "#E5E3EF",
                                  borderRadius: "100px",
                                }}
                              >

                              </InputAdornment>
                            ),
                            style: {
                              borderRadius: "30px",
                              backgroundColor: "#ffffff",
                            },
                          }}

                        />
                        {formErrors.emailId && (
                          <Stack
                            sx={{
                              fontSize: "12px",
                              color: "red",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            {formErrors.emailId}
                          </Stack>
                        )}
                      </Grid>
                    </Grid>
                  </Stack>

                  {/* <Stack>
                    <Grid container spacing={2} sx={{ pt: 4 }}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={formValues.password}
                          onChange={handleChange}
                          fullWidth
                          label="Create Password"
                          type={createShowPassword ? "text" : "password"}
                          size="small"
                          name="password"
                          id="outlined-size-password"
                          autoComplete="new-password"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                onClick={handleClickCreateShowPassword}
                                position="start"
                                sx={{ cursor: "pointer" }}
                              >
                                {createShowPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                        {formErrors.password && (
                          <Stack
                            sx={{
                              fontSize: "12px",
                              color: "red",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            {formErrors.password}
                          </Stack>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={formValues.confirmPassword}
                          onChange={handleChange}
                          fullWidth
                          label="Confirm Password"
                          type={confirmShowPassword ? "text" : "password"}
                          size="small"
                          name="confirmPassword"
                          id="outlined-size-password"
                          autoComplete="confirm-password"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                onClick={handleClickConfirmShowPassword}
                                position="start"
                                sx={{ cursor: "pointer" }}
                              >
                                {confirmShowPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                        {formErrors.confirmPassword && (
                          <Stack
                            sx={{
                              fontSize: "12px",
                              color: "red",
                              backgroundColor: "#f5f5f5",
                            }}
                          >
                            {formErrors.confirmPassword}
                          </Stack>
                        )}
                      </Grid>
                    </Grid>
                  </Stack> */}

                  <Grid
                    container
                    sx={{
                      mt: 5,
                      mb: 2,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid item>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignContent={"center"}
                        justifyContent={"center"}
                        mb={2}
                      >
                        <Button
                          size="large"
                          variant="contained"
                          type="submit"
                          fullWidth
                          onChange={(e: any) => setFormValues(e.target.value)}
                          onClick={handleSubmit}
                          sx={{
                            background:
                              "linear-gradient(to right bottom, #50a1ca, #3b9b7f)",
                            textTransform: "capitalize",
                            borderRadius: "100px",
                            fontSize: "16px",
                            width: 210,
                          }}
                        >
                          {isLoading ? (
                            <CircularProgress sx={{ color: "grey" }} />
                          ) : (
                            "Save & Continue"
                          )}
                        </Button>
                      </Stack>
                      {errorMessage && (
                        <Snackbar
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={Boolean(errorMessage)}
                          autoHideDuration={6000}
                          onClose={handleCloseSnackbar}
                        >
                          <Alert
                            severity="error"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={handleCloseSnackbar}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                            onClose={handleCloseSnackbar}
                          >
                            <AlertTitle>Error</AlertTitle>
                            {errorMessage}
                          </Alert>
                        </Snackbar>
                      )}
                      {/* <Divider sx={{ pt: 2 }}>
                        <Box component={"span"}>OR Continue With</Box>
                      </Divider> */}
                      {/* <Stack
                        direction={"row"}
                        spacing={2}
                        justifyContent={"center"}
                        alignContent={"center"}
                        sx={{ pt: 2 }}
                      >
                        <Fab size="medium">
                          <Box
                            onClick={() => handleGoogleLogin()}
                            component={"img"}
                            src={require("./../../../assets/images/google.png")}
                          />
                        </Fab>
                        <Fab size="medium">
                          <Box
                            onClick={() => handleFacebookLogin()}
                            component={"img"}
                            src={require("./../../../assets/images/facebook.png")}
                          />
                        </Fab>
                      </Stack> */}
                      <SendRequestCommunityPreview
                        isOpen={isModalOpen}
                        handleClose={handleCloseModal}
                        logo={communityData?.logo ?? ""}
                        id={communityData?._id ?? ""}
                        title={communityData?.name ?? ""}
                        city={communityData?.city}
                        members={communityData?.members?.length || []}
                        message={communityData?.directorMessage ?? ""}
                        type={communityData?.type ?? ""}
                        description={communityData?.description ?? ""}
                        handleCopyLink={handleCopyLink}
                        copied={copied}
                        whatsappShareUrl={whatsappShareUrl}
                        facebookShareUrl={facebookShareUrl}
                        linkedinShareUrl={linkedinShareUrl}
                        twitterShareUrl={twitterShareUrl}
                        coverImageSrc={communityData?.banner ?? ""}
                        handleButtonClick={handleButtonClick}

                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </>
    </>
  );
};

export default RegisterPopUp;
