
import axios from 'axios';
import { BASE_URL, BASE_URL_V2 } from '../configurations/url.config';

export const getPaymentList = async (token: string, id: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/payment/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};
export const initiatePayment = async (
  token: string,
  communityId: string,
  planId: string,
  sequenceIds: string[], // Accepting sequenceIds as an array
  amount: string
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/payment/${communityId}/initiate/${planId}`,
      {
        sequenceIds: sequenceIds, // Pass the array in the payload
        amount: amount,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const initialSubscriptionPayment = async (
  token: string,
  communityId: string,
  planId: string,
  invitesId: string
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/payment/${communityId}/initial-subscription-payment`,
      {
        planId: planId,
        invitesId: invitesId,
      },

      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const getPaymentStatus = async (token: string, id: string) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/payment/${id}`,
      { txnid: id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    // console.log('Payment-response:', response);
    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};
export const customPayment = async (
  token: string,
  id: string,
  formData: any
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/payment/${id}/customPay`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response;
  } catch (err) {
    return { status: 500, data: [], message: err };
  }
};
// get payment request bu user
export const paymentRequestbyUser = async (
  token: string,
  communityId: string,
  userId: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/payment/${communityId}/requests/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

// export const paymenttransactionsbyUser = async (token: string,communityId:string,) => {
//   try {
//     const response = await axios.get(`${BASE_URL}/payment/${communityId}/my_payments `, {

//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//     });

//     return response ;
//   } catch (err) {
//     return { status: 500, data: [] };
//   }
// };
export const paymenttransactionsbyloggedInUser = async (token: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/payment/my-payments`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    console.log('response123', response);

    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};
export const subscriptionpaymentsdue = async (token: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/payment/my-payments-due`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const updateSequencesStatus = async (
  token: string,
  communityId: string,
  ids: any
) => {
  try {
    const response = await axios.put(
      `${BASE_URL_V2}/subscriptions/${communityId}/update-subscription-sequences`,
      {
        sequences: ids,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};
