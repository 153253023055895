
import {
 
  Card,
  CardMedia,

  Paper,
  Stack,
 
  Typography,
} from "@mui/material";
// Use lowercase for the function name
export const NoPosts = () => {
  return (
    <>
      <Paper elevation={0}>
        <Stack
          spacing={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ paddingBottom: "10px", p: 2 }}
        >
          <Card elevation={0}>
            <CardMedia
              component="img"
              alt=""
              
              image={require("./../../assets/icons/svg-icons/No Post.png")}
              sx={{
                objectFit: "cover",
                width: "100%", // Set the width to 100% to fill the container
                height: {xs:"30vh", md:'40vh'},
              }}
            />
          </Card>
           <Typography sx={{color:'#777777', fontSize:{xs:'13px', md:'15px'}, fontWeight:'500', fontFamily:'Inter'}}>No Posts Available</Typography>
          <Typography textAlign={"center"} sx={{color:'#9F9F9F', fontSize:'12px'}}>
          Be the first one to share something with the community!
          </Typography>
          {/* <Divider sx={{width:'100%'}} /> */}
        </Stack>
      </Paper>
    </>
  );
};
