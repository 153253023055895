import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { communities, selectedCommunity } from "./slices/CommunitySlice";
import loggedInUser from "./slices/StoreSlice";
import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import localforage from "localforage";

const persistConfig = {
  key: "communn",
  storage: localforage,
};
const rootReducer = combineReducers({
  loggedInUser,
  selectedCommunity: selectedCommunity.reducer,
  communities: communities.reducer,
});

const pReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: pReducer,
});

export default store;
export const persister = persistStore(store);
