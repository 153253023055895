import { useContext,useState } from "react";
import { AuthContext, IAuthContext } from "../contexts/Auth.context";
import { useSnackbar } from "notistack";
import { emailOtpVerify, sendOtpEmailService } from "../services/otp.service";
export const useOtpAuth = () => {
    const { getAccessToken } = useContext<IAuthContext>(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState<boolean>(true);
   
    const  sendOtpEmail  = async (email: string) =>{
      try {
        const response = await sendOtpEmailService(getAccessToken(),email);
          return response;
      } catch {
        enqueueSnackbar(`Error while sending OTP to email - ${email}`, { variant: "error" });
      } finally {
        setIsLoading(false);
      }

    }


    const  verifyEmailOtp  = async (otp: string, email:string) =>{
      try {
        const response = await emailOtpVerify(getAccessToken(),email,otp);
          return response;
      } catch {
        enqueueSnackbar(`Error while sending OTP to email - ${email}`, { variant: "error" });
      } finally {
        setIsLoading(false);
      }

    }
   
    return {
      isLoading,
      sendOtpEmail,
      verifyEmailOtp
    };

  }
  