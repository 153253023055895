import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Box, InputAdornment } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { Close } from "@mui/icons-material";

interface customPay {
  fullWidth: boolean;
  maxWidth: "xs" | "sm" | "md" | "lg" | "xl" | false;
  customPay: boolean;
  handleCustomPayClose: () => void;
  handleCustomPayIcon: () => void;
  handleSubmitClick: () => void;
  buttonText: any;
  paymentvalue: any;
  paymentOnChange: (value: any) => void;
  paymenReason: string;
  paymentReasonOnChange: (value: string) => void;
  readonly: boolean;
}

const CustomPayModel: React.FC<customPay> = ({
  fullWidth,
  maxWidth,
  customPay,
  handleCustomPayClose,
  handleCustomPayIcon,
  handleSubmitClick,
  buttonText,
  paymentvalue,
  paymentOnChange,
  paymenReason,
  paymentReasonOnChange,
  readonly,
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={customPay}
      onClose={handleCustomPayClose}
      // sx={{
      //   "& .MuiDialog-paper": {
      //     borderRadius: "12px",
      //   },
      // }}
    >
      <DialogTitle sx={{ padding: "0px 5px 10px 0px" }}>
        <Grid container sx={{ backgroundColor: "#F0F9FF", p: "4px" }}>
          <Grid item xs={5} md={3} lg={3} xl={3} sm={3}>
            <Stack
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              direction={"row"}
            >
              <Typography
                sx={{
                  color: "#50A1CA",
                  fontSize: { xs: "15px", md: "16px" },
                  fontWeight: 500,
                  ml: 2,
                }}
              >
                {" "}
                Pay Now
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={2} md={4} lg={4} xl={4} sm={4}></Grid>
          <Grid item xs={5} md={5} lg={5} xl={5} sm={5}>
            <Stack display={"flex"} alignItems={"end"}>
              <Box
                onClick={() => {
                  handleCustomPayIcon();
                  setErrorMessage("");
                }}
                sx={{
                  backgroundColor: "#50A1CA",

                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontSize: "10px",
                  mt: "0.5px",

                  cursor: "pointer",
                  "&:hover": {
                    // backgroundColor: "#50A1CA", // Background color on hover
                    cursor: "pointer",
                    border: "none",
                  },
                }}
              >
                <Close sx={{ fontSize: "15px" }} />
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid container>
            <Grid
              item
              xs={3.5}
              md={6}
              lg={6}
              xl={3.5}
              sm={6}
              display={"flex"}
              alignItems={"center"}
            >
              <Typography
                sx={{
                  color: "gray",
                  fontSize: { xs: "14px", md: "17px" },
                  fontWeight: "500",
                }}
              >
                Amount:
              </Typography>
            </Grid>
            <Grid item xs={8.5} md={6} lg={6} xl={8.5} sm={6}>
              <TextField
                type="number"
                placeholder="Min 1 rupee required"
                disabled={readonly}
                value={paymentvalue} // Assuming paymentvalue is a number
                onChange={(e) => paymentOnChange(e.target.value)}
                size="small"
                fullWidth
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                  sx: {
                    fontSize: { xs: "13px", md: "17px" },
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <CurrencyRupeeIcon
                        sx={{ fontSize: { xs: "15px", md: "20px" } }}
                      />
                    </InputAdornment>
                  ),
                  // style: {
                  //   borderRadius: "10px",
                  // },
                }}
              />
            </Grid>
          </Grid>

          <Grid item sx={{ mt: 2 }} xs={12} md={12} lg={12} xl={12}>
            <TextField
              value={paymenReason}
              label="Reason for the Pay"
              onChange={(e) => paymentReasonOnChange(e?.target?.value)}
              disabled={readonly}
              multiline // Enable multiline
              rows={4} // S
              size="small"
              fullWidth
              InputProps={{
                // style: {
                //   borderRadius: "10px",
                // },
                sx: {
                  fontSize: { xs: "13px", md: "17px" },
                },
              }}
            ></TextField>
            <Button
              onClick={() => {
                if (paymentvalue && paymentvalue >= 1.0 && paymenReason) {
                  handleSubmitClick();
                  setErrorMessage("");
                } else if (paymentvalue && paymentvalue < 1.0) {
                  // handleSubmitClick();
                  setErrorMessage("Min 1 Rupee required");
                  return false;
                } else if (!paymentvalue && !paymenReason) {
                  setErrorMessage("Amount and Payment reason  required");
                } else if (paymentvalue && !paymenReason) {
                  setErrorMessage("Payment Reason Required");
                } else if (!paymentvalue && paymenReason) {
                  setErrorMessage("Amount is  Required");
                } else {
                  setErrorMessage("");
                }
              }}
              fullWidth
              size="large"
              variant="contained"
              sx={{
                mt: 2,
                textTransform: "capitalize",
                background:
                  "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
              }}
            >
              {buttonText}
            </Button>
            {errorMessage && (
              <Typography
                sx={{
                  color: "red",
                  font: "Roboto",
                  fontSize: "13px",
                  fontWeight: "400",
                }}
              >
                {errorMessage}
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CustomPayModel;
