import { useContext, useState } from 'react';
import { IApiResponse, IMessageResponse } from '../models/notifications.model';
import { useSnackbar } from 'notistack';
import { AuthContext, IAuthContext } from '../contexts/Auth.context';
import {
  getNotifications,
  updateNotifications,
} from '../services/pushNotification.service';

export const NotificationList = () => {
  const [notificationList, setNotificationList] = useState<IApiResponse | null>(
    null
  );
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  // Fetch notification list
  const getNotificationList = async (
    userId: string | undefined
  ): Promise<IApiResponse | null> => {
    setIsLoading(true);
    try {
      const response = await getNotifications(getAccessToken(), userId);
      if (response.data && response.data.data) {
        setNotificationList(response.data);
        return response.data;
      } else {
        enqueueSnackbar("Couldn't load Notifications", {
          variant: 'error',
          autoHideDuration: 3000,
        });
        return null;
      }
    } catch (error) {
      enqueueSnackbar('An error occurred while loading notifications', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const updateNotificationStatus = async (
    notificationIds: string[],
    status: string
  ): Promise<boolean> => {
    setIsUpdating(true);
    try {
      const response = await updateNotifications(
        getAccessToken(),
        notificationIds,
        status
      );
      if (response.status === 200) {
        enqueueSnackbar('Notifications updated successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
        return true;
      } else {
        enqueueSnackbar('Failed to update notifications', {
          variant: 'error',
          autoHideDuration: 3000,
        });
        return false;
      }
    } catch (error) {
      enqueueSnackbar('An error occurred while updating notifications', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return false;
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    isLoading,
    isUpdating,
    getNotificationList,
    updateNotificationStatus,
  };
};
