import { Box, Button, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Typography } from "@mui/material"
import BreadCrumbs from "../AlternatePages/BreadCrumbs"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux";
import React, { useEffect } from "react";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined';
import PhotoCameraFrontOutlinedIcon from '@mui/icons-material/PhotoCameraFrontOutlined';
import classImage from "../../assets/images/Claasimage.png"
import { useLiveClasses } from "../../hooks/useLiveClassess";
import { ILiveClass } from "../../models/liveClasses.model";
import Happiness from "../../assets/images/Happiness.svg"


const ClassDetails = () => {


    const { id } = useParams();

    console.log(id, "id")

    const singleCommunity = useSelector(
        (state: any) => state.selectedCommunity.selectedCommunity
    );
    let community_id = singleCommunity?._id;
    const { getClassById } = useLiveClasses();


    const [classDetails, setClassDetails] = React.useState<ILiveClass[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);

    // console.log(classDetails, "classDetails")

    const fetchClassDetails = async () => {
        setIsLoading(true);
        try {
            const response = await getClassById(community_id, id || "");
            if (Array.isArray(response)) {
                setClassDetails(response);
            } else {
                setClassDetails([]);
            }
        } catch (err) {
            console.error('Error fetching live classes:', err);
            setClassDetails([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchClassDetails();
    }, []);

    return (
        <>
            {classDetails?.map((classDetail, index) => {
                return (
                    <>
                        <BreadCrumbs title="Class" subTitle={classDetail?.name} link="/classes" />
                        <Box>
                            <Paper sx={{ p: 2, mt: 3 }} elevation={0}>
                                <Stack sx={{ backgroundColor: '#F0F9FF', p: 5, borderRadius: '5px' }}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={6}>
                                            <Typography
                                                sx={{
                                                    fontWeight: 500,
                                                    fontFamily: 'Inter',
                                                    fontSize: '25px',
                                                    color: '#50A1CA'
                                                }}>
                                                {classDetail?.name}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: '13px',
                                                    color: '#5E6C84'
                                                }}>
                                                {classDetail?.description}
                                            </Typography>
                                            <Button
                                                startIcon={<VideoCameraFrontOutlinedIcon />}
                                                size="medium"
                                                variant="outlined"
                                                sx={{
                                                    borderRadius: '5px',
                                                    fontFamily: 'Inter',
                                                    textTransform: 'capitalize',
                                                    color: '#50A1CA',
                                                    border: '1px solid #50A1CA',
                                                    fontSize: '12px',
                                                    fontWeight: '400',
                                                    mt: 2,
                                                    "&:hover": {
                                                        border: '1px solid #50A1CA',
                                                    }
                                                }}
                                                component="a"
                                                href={classDetail?.googleMeetLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Join
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box
                                                component={'img'}
                                                src={classImage}
                                                width="100%"
                                                height={200}
                                                sx={{ borderRadius: '10px' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Stack>


                                <Paper sx={{ mt: 5, border: '1px solid #E9E9E9', borderRadius: '10px' }} elevation={0}>
                                    <Stack display={"flex"} flexDirection={'row'} gap={5} alignItems={'center'} p={3} justifyContent={'space-between'}>
                                        <Stack display={"flex"} flexDirection={'row'} gap={0.5} alignItems={'center'}>
                                            <CalendarMonthOutlinedIcon sx={{ fontSize: '22px', color: '#0000008a', }} />
                                            <Typography sx={{
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                color: '#565656',
                                                fontFamily: 'Inter',
                                            }}>
                                                {new Date(classDetail?.startDate).toLocaleString('en-GB', {
                                                    timeZone: 'Asia/Kolkata',
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: '2-digit',
                                                    hour12: false,
                                                })} | {classDetail?.startTime}
                                            </Typography>
                                        </Stack>
                                        <Stack display={"flex"} flexDirection={'row'} gap={0.5} alignItems={'center'}>
                                            <UploadFileOutlinedIcon sx={{ fontSize: '22px', color: '#0000008a', }} />
                                            <Typography sx={{
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                color: '#565656',
                                                fontFamily: 'Inter',
                                            }}>
                                                By  {classDetail?.instructorName}
                                            </Typography>
                                        </Stack>
                                        <Stack display={"flex"} flexDirection={'row'} gap={0.5} alignItems={'center'}>
                                            <VideoCameraFrontOutlinedIcon sx={{ fontSize: '22px', color: '#0000008a', }} />
                                            <Typography sx={{
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                color: '#565656',
                                                fontFamily: 'Inter',
                                            }}>
                                                {classDetail?.googleMeetLink}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack>
                                        <List sx={{ backgroundColor: '#F2F2F2', borderRadius: '0px 0px 10px 10px ' }}>
                                            <ListItem disablePadding key={index}>
                                                <ListItemButton
                                                    sx={{
                                                        backgroundColor: '#F2F2F2',
                                                    }}>
                                                    <ListItemIcon>
                                                        <PhotoCameraFrontOutlinedIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '12px',
                                                                    fontWeight: 500,
                                                                    color: '#565656',
                                                                    fontFamily: 'Inter',
                                                                }}
                                                            >
                                                                By -  {classDetail?.instructorName}
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        </List>
                                    </Stack>
                                </Paper>
                                <Stack display={"flex"} flexDirection={'row'} gap={5} alignItems={'center'} sx={{ p: 2 }}>
                                    <Stack>
                                        <Box component={'img'}
                                            src={Happiness}
                                        />
                                    </Stack>
                                    <Stack>
                                        <Typography sx={{ color: '#3760A9', fontSize: '30px', fontFamily: 'Inter', fontWeight: 500 }}>
                                            You joined the class!
                                        </Typography>
                                        <Typography sx={{ color: '#565656', fontSize: '14px' }}>
                                            Click on the play button to start the class
                                        </Typography>

                                    </Stack>
                                </Stack>
                            </Paper>

                        </Box >

                    </>
                )
            })}

        </>
    )
}
export default ClassDetails