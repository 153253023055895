
import { Box,  Stack, Typography, } from "@mui/material";


export const NoPaymentRequests = () => { 
  return (
    <>
     
        <Stack
          spacing={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ paddingBottom: "10px", p: 2 }}
        >
           
            <Box
              component="img"
              alt="No Invites"
              src={require("./../../assets/icons/svg-icons/noPayments.png")}
              sx={{
                objectFit: {xs:"contain", md:"cover"},
                width: {xs:"60%", md:"21%"}, // Set the width to 100% to fill the container
                height: "20%", // Set the height to 100% to fill the container
              }}
            /> 
            <Typography sx={{color:'#777777', fontSize:{xs:'10px', md:'13px'}, fontWeight:'500', fontFamily:'Inter'}}>No Payment Requests Available</Typography>
        </Stack>

        
     
    </>
  );
};
