import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Fab,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../contexts/Auth.context";
import { getOtp, verifyOtp } from "../../../services/otp.service";
import {
  EMPTY_STRING,
  INACTIVE_USER_MSG,
  INVALID_OTP,
  OTP_LIMIT_REACHED,
  OTP_RESENT,
  OTP_SEND_FAILED,
  OTP_SENT,
  PASSWORD_INVALID,
  USER_NOT_FOUNT_MSG,
} from "../../../utils/UdfMessages";
// import {

//   useGoogleLogin,
//   CodeResponse,

// } from "@react-oauth/google";
import { BASE_URL } from "../../../configurations/url.config";
import { Helmet } from "react-helmet";
import RegisterPopUp from "./RegisterPopUp";
import { SocialProfile } from "./Login";

// interface User {
//   access_token: string;
// }


const LoginPopUp: React.FC = () => {

  const authContex = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword] = useState(false);
  const [error, setError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [enableGetOTP, setEnableGetOTP] = useState(false);
  const [otpReceived, setOtpReceived] = useState(false);
  const [otpSuccessMessage, setOtpSuccessMessage] = useState("");
  // const [enteredOpt, setEnteredOtp] = useState("");
  const [resendCount, setResendCount] = useState(0);
  const [remainingTime, setRemainingTime] = useState(30000);
  const [timerRunning, setTimerRunning] = useState(false);
  const [isBlocked] = useState(false);
  const [actionButton, setActionButton] = useState("Login");
  const [information] = React.useState("");
  // const [otp6, setOtp6] = useState("");
  // const [generatedOTP, setGeneratedOTP] = useState<Number>(0);
  const NUMBER_3 = 3;

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Mobile Number is required")
      .test(
        "username",
        "Invalid Mobile Number",
        (value) => validateEmail(value) || validateMobileNumber(value)
      ),
    password: Yup.string()
      .required("OTP is required")
      .max(40, "Password must not exceed 40 characters"),
  });

  const validateEmail = (value: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(value);
  };

  const validateMobileNumber = (value: string) => {
    const mobileNumberRegex = /^\d{10}$/;
    return mobileNumberRegex.test(value);
  };

  const [open, setOpen] = useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleNumberChange = (e: any) => {
    const value = e.target.value;
    if (/^\d+$/.test(value) && value.length === 10) {
      setPhoneNumber(value);
      setEnableGetOTP(true);
      setValidPhoneNumber(true);
      setResendCount(0);
      setTimerRunning(false);
      setRemainingTime(60);
      setOtpReceived(false);
      setOtpSuccessMessage("");
      setError("");
    } else {
      setEnableGetOTP(false);
      setValidPhoneNumber(false);
      setError(EMPTY_STRING);
      setActionButton("Login");
      // setGeneratedOTP(0);
      setOtpSuccessMessage(EMPTY_STRING);
    }
  };

  const generateOTP = async () => {
    if (resendCount < NUMBER_3) {
      const { data } = await getOtp(phoneNumber);
      let number = phoneNumber.replace(/^.{6}/g, "******");
      setError("");
      if (data) {
        setOtpReceived(true);
        setActionButton("Verify OTP");
        setResendCount(resendCount + 1);
        setTimerRunning(true);
        // setGeneratedOTP(data?.opt);
        //console.log(generatedOTP);
        if (resendCount === 0) {
          setOtpSuccessMessage(OTP_SENT.replace("#PHONE_NUMBER", number));
        } else {
          setOtpSuccessMessage(OTP_RESENT.replace("#PHONE_NUMBER", number));
        }
      } else {
        setOtpSuccessMessage("");
        setError(OTP_SEND_FAILED.replace("#PHONE_NUMBER", number));
      }
    } else if (resendCount === 3) {
      setOtpSuccessMessage(OTP_LIMIT_REACHED);
      setTimerRunning(false);
    }
  };

  useEffect(() => {
    if (timerRunning && remainingTime > 0) {
      const interval = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 3000);

      return () => clearInterval(interval);
    } else {
      setTimerRunning(false);
      setRemainingTime(30);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerRunning, remainingTime]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const value = data.password;
    //console.log(generatedOTP);
    if (!isNaN(value) && value.toString().length === 6) {
      setLoading(true);
      // Call verify OTP to verify
      const verifyResponse = await verifyOtp(phoneNumber, value);
      if (verifyResponse.status === 200) {
        // call autoLogin
        const response = await authContex.autoLogin(phoneNumber, "");
        handleLoginResponse(response);
      } else {
        // OTP
        setOtpSuccessMessage(EMPTY_STRING);
        setError(INVALID_OTP);
        setLoading(false);
      }
      setLoading(false);
    } else {
      // Email flow: login
      const response: any = await authContex.login(data);
      handleLoginResponse(response);
      if (response?.response?.status === 401) {
        setError(PASSWORD_INVALID)
      } else if (response?.response?.status === 404) {
        setError("User not Found, check your Account Credentials");
      }
      setLoading(false)

    }
  };

  const { id } = useParams()
  const handleLoginResponse = async (response: any) => {
    if (response?.status === 200) {
      const { user, joinedCommunities } = response?.data;
      if (joinedCommunities && joinedCommunities.length > 0) {
        // navigate("/home", { state: { id: user?.community } });

        navigate(`/community/${id}/about`)
      } else if (!joinedCommunities || joinedCommunities.length === 0) {
        navigate("/explore-communities", {
          state: { id: 2, data: user },
        });
      } else if (user?.community) {
        navigate("/dashboard", { state: { id: 3, data: user } });
      }
    } else if (response.status === 403) {
      setError(INACTIVE_USER_MSG);
      setOtpSuccessMessage(EMPTY_STRING);
    } else if (response?.status === 404) {
      setError(USER_NOT_FOUNT_MSG);
      // navigate("/register", { state: { id: 1, phoneNumber: phoneNumber } });
      setOpen(true)
    }
    setLoading(false);
  };



  // const usernameValue = watch("username");
  // const isValidUsername = /^\d+$/.test(usernameValue);

  // const [profile, setProfile] = useState<Profile | null>(null);
  const [accessToken] = useState<string | null>(null);

  useEffect(() => {
    if (accessToken) {

      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
        })
        .then((res) => {
          const socialProfile = res.data as SocialProfile;
          //console.log("User Info:", socialProfile);
          const autoCreateData = {
            firstName: socialProfile.given_name,
            lastName: socialProfile.family_name,
            emailId: socialProfile.email,
            isEmailVerified: socialProfile.verified_email,
            avatar: socialProfile.picture
          };

          axios
            .post(`${BASE_URL}/auth/social-login`, autoCreateData)
            .then(async (response) => {
              const { user } = response?.data
              // console.log("User Info:", user)
              const autologinResponse = await authContex.autoLogin("", user?.emailId);
              handleLoginResponse(autologinResponse);
            })
            .catch((error) => {
              console.error("Auto login error:", error);
            });
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  // Use MUI's useMediaQuery hook to determine screen size
  const isLargeScreen = useMediaQuery('(min-width:1200px)');
  const isMediumScreen = useMediaQuery('(min-width:850px) and (max-width:1199px)');
  const dialogWidth = isLargeScreen ? '50%' : isMediumScreen ? '68%' : '100%';

  return (
    <>
      <Helmet>
        <title>
          Login / Register - Communn - Build & Manage your community
        </title>
        <meta
          name="description"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta
          name="keywords"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
      </Helmet>
      {/* <StyledLogin> */}
      <Grid container>
        <Grid item xs={12} md={12}>
          <Stack
            sx={{
              overflow: "auto",
              justifyContent: {
                xs: "flex-start",
                sm: "flex-start",
                md: "center",
                lg: "center",
              },
            }}
            className="login-form-container"
            alignItems={"center"}
          >
            {information ? (
              <Typography
                sx={{
                  color: "green",
                  fontWeight: "700",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                {information}
              </Typography>
            ) : (
              ""
            )}

            <Box
              component={"form"}
              onSubmit={onSubmit}
              sx={{
                width: "328px",
                textAlign: "center",
                mt: { xs: "5vh", sm: "5vh", md: "10vh", lg: "2vh" },
              }}
            >
              <Box
                sx={{}}
                component={"img"}
                src={require("./../../../assets/images/Communn-new-logo.png")}
                alt=""
                width={"60%"}
              />

              <Typography
                sx={{
                  color: "#000000",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                New user? Enter mobile number please.
              </Typography>
              <Stack
                spacing={2}
                sx={{ pt: 2, backgroundColor: "white", px: 2 }}
                className="login-form"
              >
                <TextField
                  sx={{ backgroundColor: "white" }}
                  size="small"
                  required
                  fullWidth
                  placeholder="Enter Mobile Number"
                  {...register("username")}
                  error={errors.username ? true : false}
                  helperText={
                    errors.username ? (errors.username.message as string) : ""
                  }
                  onChange={handleNumberChange}
                  // InputProps={{ style: { borderRadius: "30px", backgroundColor: '#ffffff' } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{
                          cursor: "pointer",
                          backgroundColor: "#ffffff",
                          alignContent: "center",
                          borderRadius: "100px",
                        }}
                      >
                        <Box
                          display="flex"
                          sx={{
                            display: validPhoneNumber ? "block" : "none",
                          }}
                        >
                          <Stack direction="row">
                            <Divider
                              orientation="vertical"
                              sx={{ m: 1, height: 24 }}
                            />

                            <Button
                              size="small"
                              sx={{
                                backgroundColor: "#3b9b7f",
                                textTransform: "capitalize",
                                color: "white",
                                borderRadius: "100px",
                                height: "30px",
                                mt: "5px",
                                mr: "-15px",
                                ":hover": {
                                  backgroundColor: "#50a1ca",
                                  color: "#ffffff",
                                },
                                // display: validPhoneNumber ? "block" : "none",
                              }}
                              disabled={
                                !enableGetOTP || isBlocked || timerRunning
                              }
                              onClick={generateOTP}
                            >
                              {timerRunning
                                ? `Resend OTP in ${remainingTime}s`
                                : resendCount === 0
                                  ? `Get OTP`
                                  : `Resend OTP`}
                            </Button>
                          </Stack>
                        </Box>
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "100px",
                      backgroundColor: "#ffffff",
                    },
                  }}
                />

                <TextField
                  size="small"
                  required
                  defaultValue=""
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter OTP "
                  {...register("password")}
                  error={errors.password ? true : false}
                  helperText={
                    errors.password ? (errors.password.message as string) : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{
                          cursor: "pointer",
                          backgroundColor: "#E5E3EF",
                          borderRadius: "100px",
                        }}
                      >
                        <Stack
                          divider={<Divider orientation="vertical" flexItem />}
                        ></Stack>
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "30px",
                      backgroundColor: "#ffffff",
                    },
                  }}
                ></TextField>

                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{
                    borderRadius: "30px",
                    background:
                      "linear-gradient(140deg, #50A1CA 0%, #3B9B7F 100%)",
                    textTransform: "capitalize",
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  {" "}
                  {loading ? <CircularProgress size={24} sx={{ color: 'white' }} />
                    :
                    `${actionButton}`}
                </Button>

                <Stack
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ color: "red" }}
                >
                  {" "}
                  {error ? (
                    <Typography sx={{ fontSize: "16px", textAlign: "center" }}>
                      {error}
                    </Typography>
                  ) : null}{" "}
                </Stack>
                {otpReceived && (
                  <p
                    style={{
                      color: "green",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                    className="success-message"
                  >
                    {otpSuccessMessage}
                  </p>
                )}
                <Divider sx={{ pt: 1 }}>
                  <Box component={"span"}>OR Continue With</Box>
                </Divider>
                <Box sx={{ pt: 1 }}>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"center"}
                    alignContent={"center"}
                  >
                    <Fab size="medium">
                      <Box
                        // onClick={handleGoogleLogin}
                        component={"img"}
                        src={require("./../../../assets/images/google.png")}
                      />
                    </Fab>

                    <Fab size="medium">
                      <Box
                        component={"img"}
                        src={require("./../../../assets/images/facebook.png")}
                      />
                    </Fab>
                  </Stack>
                  <React.Fragment>
                    <Dialog
                      fullWidth
                      maxWidth="sm"
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          width: dialogWidth,
                          padding: 0,
                        },
                      }}
                    >
                      <DialogContent sx={{ padding: 0 }}>
                        {/* <RegisterPopUp handleClose={handleClose} phoneNumber={phoneNumber}/> */}
                      </DialogContent>
                    </Dialog>
                  </React.Fragment>

                  <Box
                    sx={{
                      color: "#9A9A9A",
                      fontSize: "12px",
                      pt: 2,
                      textAlign: "center",
                      mt: 2,
                    }}
                  >
                    By clicking Get OTP, you agree to the communn{" "}
                    <Link href="" style={{ color: "#9A9A9A" }}>
                      User Agreement,
                    </Link>
                    <Link href="" style={{ color: "#9A9A9A" }}>
                      {" "}
                      Privacy Policy,
                    </Link>
                    and
                    <Link href="" style={{ color: "#9A9A9A" }}>
                      {" "}
                      Cookie Policy.
                    </Link>
                  </Box>
                </Box>

                <Stack>
                  <script
                    async
                    src="https://cse.google.com/cse.js?cx=a2e80b1b9920b4352"
                  ></script>
                  <div className="gcse-search"></div>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginPopUp;