import axios from 'axios';
import { BASE_URL } from '../configurations/url.config';

export const getOtp = async (phoneNumber: string) => {
  try {
    // console.log(token);
    const formData = new FormData();
    formData.append('phoneNumber', phoneNumber);
    const response = await axios.post(`${BASE_URL}/auth/otp/send`, formData, {
      headers: {
        //Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: undefined };
  }
};
//original //

// export const verifyOtp = async (phoneNumber: string,otp:string) => {
//   try {

//     const formData = new FormData();
//     formData.append("phoneNumber", phoneNumber);
//     formData.append("otp", otp.trim());
//     const response = await axios.post(`${BASE_URL}/auth/otp/varify`, formData, {
//       headers: {
//         //Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//     });
//     return response;
//   } catch (err) {
//     console.log("ERR :", err);
//     return { status: 500, data: undefined };
//   }
// };

// export const verifyOtp = async (phoneNumber: string, otp: string) => {
//   try {
//     const formData = new FormData();
//     formData.append("phoneNumber", phoneNumber);
//     formData.append("otp", otp.trim());

//     const response = await axios.post(`${BASE_URL}/auth/otp/verify`, formData, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });

//     return {
//       status: response.data.status, // Assuming your response has a 'status' field
//       otp: response.data.otp || "", // Assuming your response has an 'otp' field
//     };
//   } catch (err) {
//     console.log("ERR:", err);
//     return { status: 500, otp: undefined };
//   }
// };
export const verifyOtp = async (phoneNumber: string, otp: string) => {
  try {
    const formData = new FormData();
    formData.append('phoneNumber', phoneNumber);
    formData.append('otp', otp.trim());
    const response = await axios.post(`${BASE_URL}/auth/otp/verify`, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (err: any) {
    console.log('ERR:', err);
    return {
      status: 500,
      otp: undefined,
      verifyResponse: err.response || undefined,
    };
  }
};

export const sendOtpEmailService = async (token: string, emailId: string) => {
  try {
    const formData = new FormData();
    formData.append('emailId', emailId);
    const response = await axios.post(
      `${BASE_URL}/auth/otp/verify-otp-email`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const emailOtpVerify = async (
  token: string,
  emailId: string,
  otp: string
) => {
  try {
    // console.log(token);
    const formData = new FormData();
    formData.append('emailId', emailId);
    formData.append('otp', otp.trim());
    const response = await axios.post(
      `${BASE_URL}/auth/otp/send-otp-email`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: undefined };
  }
};
