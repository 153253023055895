import { Grid } from "@mui/material";
import React, {} from "react";

import HomePost from "../../../Home/HomePost";
// import { useNavigate } from "react-router-dom";
// import { useFileHandler } from "../../../../hooks/useFileHandler";
// import store from "../../../../store";
// import { useSelector } from "react-redux";
// import { ICommunity } from "../../../../models/communities.modal";

// import { useCommunity } from "../../../../hooks/useCommunity";
import { Helmet } from "react-helmet";
import { metaConfig } from "../../../../utils/constants";
// import Loader from "../../../Loader/Loader";

const Profile: React.FC = () => {


  return (
    <>
      <Helmet>
      <title>{metaConfig.title.myProfile}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.description} />
        <meta name="keywords" content={metaConfig.description} />
      </Helmet>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HomePost />
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
