import { Grid } from "@mui/material";
import HomePost from "./HomePost";
import { HomeCarousel } from "./HomeCarousel";
import { Helmet } from "react-helmet";
import { metaConfig } from "../../utils/constants";


const Home = () => {

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.home}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.description} />
        <meta name="keywords" content={metaConfig.description} />
      </Helmet>
      <Grid container >
        <Grid item xs={12}  >
          <HomeCarousel />
          <HomePost />
        </Grid>
      </Grid>

    </>
  );
};
export default Home