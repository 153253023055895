import { useEffect, useRef, useState } from 'react';
import jwt_decode from 'jwt-decode';
import axios, { AxiosResponse } from 'axios';
import { BASE_URL } from '../configurations/url.config';
import { loginUser, logoutUser } from '../store/slices/StoreSlice';
import store from '../store';
import {
  loadCommunities,
  loadSelectedCommunity,
  removeCommunity,
  removeCommunities,
} from '../store/slices/CommunitySlice';
import {
  communityById,
  membershipCommunities,
} from '../services/communities.service';
import { ICommunity, IMemberCommunity } from '../models/communities.modal';
import { useDispatch } from 'react-redux';
import { getDeviceToken } from '../services/pushNotification/notification.service';

const setTokens = ({ accessToken, refreshToken }: any) => {
  if (
    accessToken &&
    accessToken !== null &&
    accessToken !== undefined &&
    accessToken !== ''
  ) {
    localStorage.setItem('access-token', accessToken);
    localStorage.setItem('refresh-token', refreshToken);
  }
};

const getTokens = () => {
  return {
    accessToken: localStorage.getItem('access-token'),
    refreshToken: localStorage.getItem('refresh-token'),
  };
};

const removeTokens = () => {
  localStorage.removeItem('access-token');
  localStorage.removeItem('refresh-token');
  localStorage.removeItem('community');
};

const purgeStoredState = () => {
  localStorage.clear();
  sessionStorage.clear();
};

export const useAuth = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [defaultCommunity, setDefaultCommunity] = useState<string>('');

  const intervalId = useRef<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const token = params?.get('token');
        const AdminCommunId = params?.get('communityId');
        const redirect = params?.get('redirect');
        if (params && token && AdminCommunId) {
          dispatch(removeCommunity());
          localStorage.setItem('access-token', token);
          localStorage.setItem('refresh-token', token);
          const adminToken = await axios.get(
            `${BASE_URL}/auth/get-user-by-token`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          setTokens(token);
          setIsAuthenticated(true);
          setAccessToken(token);
          setRefreshToken(token);
          setUser(adminToken.data);
          store.dispatch(loginUser(adminToken.data));

          const response = await axios.get(
            `${BASE_URL}/community/${AdminCommunId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );

          //console.log(response.data.community,"communityhh")
          let community = response?.data.community;
          // console.log(response.data);

          dispatch(loadSelectedCommunity(community));
          const memberInCommunity = await membershipCommunities(token);
          if (memberInCommunity?.status === 200) {
            store.dispatch(loadCommunities(memberInCommunity?.data));
          }
          const communityId = AdminCommunId;
          if (communityId) {
            const res = await communityById(token, communityId);

            if (res.status === 200) {
              dispatch(loadSelectedCommunity(res.data));
            }
            response.data = { ...response.data, community: communityId };

            setDefaultCommunity(communityId);
            localStorage.setItem('communityId', communityId);
          }

          window.history.replaceState(
            {},
            document.title,
            window.location.pathname
          );

          // Redirect to the specified path after login
          if (redirect) {
            window.location.href = decodeURIComponent(redirect);
          }
        }
      } catch (error) {
        // console.error("Error processing URL parameters:", error);
        // setLoading(false);
      }
    };
    //fetchdata only when its came from admin with params token and communityid
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyToken = (token: string) => {
    const decoded: { exp: number } = jwt_decode(token);
    const remainingTime = decoded.exp - ((Date.now() / 1000) | 0);
    if (remainingTime < 100) {
      clearInterval(intervalId.current);
      getNewToken();
    } else {
      setIsAuthenticated(true);
      setLoading(false);
    }
  };

  const getNewToken = () => {
    logout();
    setLoading(false);
  };

  // GET TOKENS FROM LOCAL STORAGE
  useEffect(() => {
    const { accessToken } = getTokens();
    if (accessToken) {
      setAccessToken(accessToken);
      //setRefreshToken(refreshToken ?? ""));
      verifyToken(accessToken);
    } else {
      getNewToken();
    }
    // eslint-disable-next-line
  }, []);

  const getAccessToken = () => {
    return accessToken ?? '';
  };

  const getSelectedCommunityId = () => {
    return defaultCommunity ?? localStorage.getItem('communityId');
  };

  const login = async (cred: any) => {
    try {
      const response = (await axios.post(
        `${BASE_URL}/login`,
        {
          ...cred,
        },
        { headers: { 'Content-Type': 'application/json' } }
      )) as AxiosResponse;
      if (response.status === 200) {
        const { user, joinedCommunities } = response?.data;
        const tokens = user?.token;
        setTokens(tokens);
        setIsAuthenticated(true);
        setAccessToken(tokens.accessToken);
        setRefreshToken(tokens.refreshToken);
        const OnlyUser = user;
        delete OnlyUser['token'];
        setUser(OnlyUser);
        store.dispatch(loginUser(OnlyUser));
        let communityId = '';
        if (joinedCommunities && joinedCommunities.length > 0) {
          const { defaultJoinedCommunity, notPaidCommunities } =
            checkPaidSubscription(joinedCommunities);
          //  const defaultJoinedCommunity = _getDefaultJoinedCommunity(joinedCommunities)
          //console.log(defaultJoinedCommunity," -> Default community")
          // console.log(notPaidCommunities," -> Not Paid community")
          if (defaultJoinedCommunity && defaultJoinedCommunity?._id) {
            communityId = defaultJoinedCommunity?._id;
            const res = await communityById(tokens.accessToken, communityId);
            if (res.status === 200) {
              store.dispatch(loadSelectedCommunity(res.data));
              response.data.user = {
                ...response.data.user,
                community: communityId,
              };
              setDefaultCommunity(communityId);
              localStorage.setItem('communityId', communityId);
            }
            const communities = _getAdminCommunities(joinedCommunities);

            store.dispatch(loadCommunities(communities));
          } else if (notPaidCommunities) {
            response.data.notPaidCommunities = {
              ...response.data,
              communities: notPaidCommunities,
            };
          }
        }
      }

      return response;
    } catch (err) {
      setIsAuthenticated(false);
      return err;
    }
  };
  const checkPaidSubscription = (data: IMemberCommunity[]) => {
    let defaultJoinedCommunity: ICommunity | undefined;
    const notPaidCommunities: any = [];
    data?.forEach((mapping: IMemberCommunity) => {
      if (mapping.community?.collectSubscription === 'NO') {
        defaultJoinedCommunity = mapping.community;
        return false;
      }
    });

    if (!defaultJoinedCommunity) {
      data?.forEach((community) => {
        if (
          community.community?.collectSubscription === 'YES' &&
          community.subscriptionStatus === 'PAID'
        ) {
          defaultJoinedCommunity = community.community;
          return false;
        } else {
          notPaidCommunities.push(community);
        }
      });
    }
    return { defaultJoinedCommunity, notPaidCommunities };
  };
  const loginPopUp = async (cred: any) => {
    try {
      const response = (await axios.post(
        `${BASE_URL}/login`,
        {
          ...cred,
        },
        { headers: { 'Content-Type': 'application/json' } }
      )) as AxiosResponse;
      if (response.status === 200) {
        const { user, joinedCommunities } = response?.data;
        const tokens = user?.token;
        setTokens(tokens);
        setIsAuthenticated(true);
        setAccessToken(tokens.accessToken);
        setRefreshToken(tokens.refreshToken);
        const OnlyUser = user;
        // delete OnlyUser["token"];
        setUser(OnlyUser);
        store.dispatch(loginUser(OnlyUser));
        let communityId = '';
        if (joinedCommunities && joinedCommunities.length > 0) {
          const { defaultJoinedCommunity, notPaidCommunities } =
            checkPaidSubscription(joinedCommunities);
          if (defaultJoinedCommunity && defaultJoinedCommunity?._id) {
            communityId = defaultJoinedCommunity?._id;
            const res = await communityById(tokens.accessToken, communityId);
            if (res.status === 200) {
              store.dispatch(loadSelectedCommunity(res.data));
              response.data.user = {
                ...response.data.user,
                community: communityId,
              };
              setDefaultCommunity(communityId);
              localStorage.setItem('communityId', communityId);
            }
            const communities = _getAdminCommunities(joinedCommunities);
            store.dispatch(loadCommunities(communities));
          } else if (notPaidCommunities) {
            response.data.notPaidCommunities = {
              ...response.data,
              communities: notPaidCommunities,
            };
          }
        }
      }

      return response;
    } catch (err) {
      setIsAuthenticated(false);
      return err;
    }
  };
  const logout = () => {
    removeTokens();
    setUser(null);
    setIsAuthenticated(false);
    setAccessToken(null);
    setRefreshToken(null);
    purgeStoredState();
    setDefaultCommunity('');
    store.dispatch(removeCommunity());
    store.dispatch(removeCommunities());
    store.dispatch(logoutUser());
    localStorage.setItem('communityId', '');
  };
  const autoLogin = async (phoneNumber: string, emailId: string) => {
    try {
      const formData = new FormData();
      formData.append('phoneNumber', phoneNumber);
      formData.append('emailId', emailId);
      let deviceToken = localStorage.getItem('deviceToken');

      if (!deviceToken) {
        deviceToken = await getDeviceToken();
        if (deviceToken) {
          localStorage.setItem('deviceToken', deviceToken);
        } else {
          console.warn('Device token is unavailable or permission is denied.');
        }
      }

      if (deviceToken) {
        formData.append('deviceToken', deviceToken);
      }

      const response = await axios.post(
        `${BASE_URL}/auth/autoLogin`,
        formData,
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (response.status === 200) {
        const { user, joinedCommunities } = response?.data;
        const tokens = user?.token;
        setTokens(tokens);
        setIsAuthenticated(true);
        setAccessToken(tokens.accessToken);
        setRefreshToken(tokens.refreshToken);
        const OnlyUser = user;
        delete OnlyUser['token'];
        setUser(OnlyUser);
        store.dispatch(loginUser(OnlyUser));

        let communityId = '';
        if (joinedCommunities && joinedCommunities.length > 0) {
          const { defaultJoinedCommunity, notPaidCommunities } =
            checkPaidSubscription(joinedCommunities);
          if (defaultJoinedCommunity && defaultJoinedCommunity?._id) {
            communityId = defaultJoinedCommunity?._id;
            const res = await communityById(tokens.accessToken, communityId);

            if (res.status === 200) {
              store.dispatch(loadSelectedCommunity(res.data));
              response.data.user = {
                ...response.data.user,
                community: communityId,
              };
              setDefaultCommunity(communityId);
              localStorage.setItem('communityId', communityId);
            }

            const communities = _getAdminCommunities(joinedCommunities);
            store.dispatch(loadCommunities(communities));
          } else if (notPaidCommunities) {
            response.data.notPaidCommunities = {
              ...response.data,
              communities: notPaidCommunities,
            };
          }
        }
      }
      return response;
    } catch (err: any) {
      setIsAuthenticated(false);
      return err?.response ? err?.response : err;
    }
  };
  const autoLoginForLoginPopup = async (
    phoneNumber: string,
    emailId: string
  ) => {
    try {
      const formData = new FormData();
      formData.append('phoneNumber', phoneNumber);
      formData.append('emailId', emailId);
      const response = (await axios.post(
        `${BASE_URL}/auth/autoLogin`,
        formData,
        { headers: { 'Content-Type': 'application/json' } }
      )) as AxiosResponse;

      if (response.status === 200) {
        const { user, joinedCommunities } = response?.data;
        const tokens = user?.token;
        setTokens(tokens);
        setIsAuthenticated(true);
        setAccessToken(tokens.accessToken);
        setRefreshToken(tokens.refreshToken);
        const OnlyUser = user;
        // delete OnlyUser["token"];
        setUser(OnlyUser);
        store.dispatch(loginUser(OnlyUser));
        let communityId = '';
        if (joinedCommunities && joinedCommunities.length > 0) {
          const { defaultJoinedCommunity, notPaidCommunities } =
            checkPaidSubscription(joinedCommunities);
          if (defaultJoinedCommunity && defaultJoinedCommunity?._id) {
            communityId = defaultJoinedCommunity?._id;

            const res = await communityById(tokens.accessToken, communityId);
            if (res.status === 200) {
              store.dispatch(loadSelectedCommunity(res.data));
              response.data.user = {
                ...response.data.user,
                community: communityId,
              };
              setDefaultCommunity(communityId);
              localStorage.setItem('communityId', communityId);
            }
            const communities = _getAdminCommunities(joinedCommunities);
            store.dispatch(loadCommunities(communities));
          } else if (notPaidCommunities) {
            response.data.notPaidCommunities = {
              ...response.data,
              communities: notPaidCommunities,
            };
          }
        }
      }

      return response;
    } catch (err: any) {
      setIsAuthenticated(false);
      return err?.response ? err?.response : err;
    }
  };
  const autoCreate = async (formData: any) => {
    try {
      // console.log('formData123', formData);
      const response = (await axios.post(
        `${BASE_URL}/auth/autoCreate`,
        formData,
        { headers: { 'Content-Type': 'application/json' } }
      )) as AxiosResponse;
      if (response.status === 200) {
        const { user, joinedCommunities } = response?.data;
        const tokens = user?.token;
        setTokens(tokens);
        setIsAuthenticated(true);
        setAccessToken(tokens.accessToken);
        setRefreshToken(tokens.refreshToken);
        const OnlyUser = user;
        delete OnlyUser['token'];
        setUser(OnlyUser);
        store.dispatch(loginUser(OnlyUser));
        let communityId = '';
        if (joinedCommunities && joinedCommunities.length > 0) {
          const { defaultJoinedCommunity, notPaidCommunities } =
            checkPaidSubscription(joinedCommunities);
          if (defaultJoinedCommunity && defaultJoinedCommunity?._id) {
            communityId = defaultJoinedCommunity?._id;
            const res = await communityById(tokens.accessToken, communityId);
            if (res.status === 200) {
              store.dispatch(loadSelectedCommunity(res.data));
              response.data.user = {
                ...response.data.user,
                community: communityId,
              };
              setDefaultCommunity(communityId);
              localStorage.setItem('communityId', communityId);
            }
            const communities = _getAdminCommunities(joinedCommunities);
            store.dispatch(loadCommunities(communities));
          } else if (notPaidCommunities) {
            response.data.notPaidCommunities = {
              ...response.data,
              communities: notPaidCommunities,
            };
          }
        }
      }

      return response;
    } catch (err) {
      setIsAuthenticated(false);
      return err;
    }
  };
  const autoCreateLoginPopup = async (formData: any) => {
    try {
      console.log('formData123', formData);
      const response = (await axios.post(
        `${BASE_URL}/auth/autoCreate`,
        formData,
        { headers: { 'Content-Type': 'application/json' } }
      )) as AxiosResponse;
      if (response.status === 200) {
        const { user, joinedCommunities } = response?.data;
        const tokens = user?.token;
        setTokens(tokens);
        setIsAuthenticated(true);
        setAccessToken(tokens.accessToken);
        setRefreshToken(tokens.refreshToken);
        const OnlyUser = user;
        // delete OnlyUser["token"];
        setUser(OnlyUser);
        store.dispatch(loginUser(OnlyUser));
        let communityId = '';
        if (joinedCommunities && joinedCommunities.length > 0) {
          const { defaultJoinedCommunity, notPaidCommunities } =
            checkPaidSubscription(joinedCommunities);
          if (defaultJoinedCommunity && defaultJoinedCommunity?._id) {
            communityId = defaultJoinedCommunity?._id;

            const res = await communityById(tokens.accessToken, communityId);
            if (res.status === 200) {
              store.dispatch(loadSelectedCommunity(res.data));
              response.data.user = {
                ...response.data.user,
                community: communityId,
              };
              setDefaultCommunity(communityId);
              localStorage.setItem('communityId', communityId);
            }
            const communities = _getAdminCommunities(joinedCommunities);
            store.dispatch(loadCommunities(communities));
          } else if (notPaidCommunities) {
            response.data.notPaidCommunities = {
              ...response.data,
              communities: notPaidCommunities,
            };
          }
        }
      }

      return response;
    } catch (err) {
      setIsAuthenticated(false);
      return err;
    }
  };

  return {
    user,
    isAuthenticated,
    loading,
    login,
    logout,
    getAccessToken,
    autoLogin,
    autoCreate,
    getSelectedCommunityId,
    autoCreateLoginPopup,
    autoLoginForLoginPopup,
    loginPopUp,
  };
};

const _getAdminCommunities = (data: IMemberCommunity[]) => {
  return data?.map((item) => ({
    ...item.community,
    id: item.community?._id ?? '', // Provide a default value if _id is undefined
  }));
};
