import React from 'react';
// import Modal from '@mui/material/Modal';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import PublicIcon from '@mui/icons-material/Public';
import { Box, Dialog, DialogActions, DialogContent, DialogProps, Divider, Stack } from '@mui/material';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
// import CancelIcon from '@mui/icons-material/Cancel';
// import ShareIcon from '@mui/icons-material/Share';
import { USER_APP_BASE_URL } from '../../configurations/url.config';
import Close from '@mui/icons-material/Close';




interface CustomModalProps {
  isOpen: boolean;
  handleClose: () => void;
  coverImageSrc: string;
  dpSrc: string;
  title: string;
  city: string;
  members: [];
  communityId: string;
  handleCopyLink: () => void;
  copied: boolean;
  whatsappShareUrl: string;
  facebookShareUrl: string;
  linkedinShareUrl: string;
  twitterShareUrl: string;
}

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen,
  handleClose,
  coverImageSrc,
  dpSrc,
  title,
  city,
  members,
  communityId,
  handleCopyLink,
  copied,
  whatsappShareUrl,
  facebookShareUrl,
  linkedinShareUrl,
  twitterShareUrl,
}) => {
  const [maxPopWidth] = React.useState<DialogProps["maxWidth"]>("sm");
  const [fullWidth] = React.useState(true);



  const maxLength = 8; // Maximum length for the title

  const truncatedTitle = title?.length > maxLength ? title.substring(0, maxLength) : title;

  const label = `${USER_APP_BASE_URL}/${truncatedTitle}`;
  return (

    <Stack>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxPopWidth}
        open={isOpen}
        onClose={handleClose}
      >
        <DialogActions sx={{ backgroundColor: "#F0F9FF" }}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ backgroundColor: "#F0F9FF" }}
          >
            <Stack></Stack>
            <Stack></Stack>
            <Stack sx={{ backgroundColor: "#F0F9FF" }}>
              <Box
                onClick={handleClose}
                sx={{
                  backgroundColor: "#50A1CA",
                  padding: "4px",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontSize: "10px",
                  mt: "0.5px",
                  mr: "10px",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#50A1CA", // Background color on hover
                    cursor: "pointer",
                    border: "none",
                  },
                }}
              >
                <Close sx={{ fontSize: "15px" }} />
              </Box>
            </Stack>
          </Stack>
        </DialogActions>
        <DialogContent sx={{ p: 0 }}>
          {/* Top Section - Cover image */}
          <div style={{ position: "relative" }}>
            <img src={coverImageSrc} alt="Cover" style={{
              width: "100%",
              height: "auto",  // Set height to auto to maintain aspect ratio
              maxHeight: 200,  // Set the maximum height
              objectFit: "cover",  // Use object-fit to handle the cropping
              position: "relative",
            }} />

          </div>
          {/* Bottom Section - Description */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#ffffff",
              marginTop: "-18px",
              position: 'relative',
              borderRadius: "10px 10px 0px 0px",
              borderTop: '1px solid #A9A9A9',
              boxShadow: '0px -5px 5px -5px #A9A9A9',
            }}
          >
            {/* Add display picture (DP) */}
            <Box sx={{ position: "relative" }}>
              <Avatar
                src={dpSrc}
                alt="DP"
                sx={{
                  width: "100px",
                  height: "100px",
                  position: "relative",
                  top: "-60px",
                  zIndex: 1, // Ensure the Avatar is on top of the white circle
                }}
              />
              <Avatar
                sx={{
                  backgroundColor: "#ffffff",
                  width: "120px", // Adjust the size to fit your design
                  height: "120px", // Adjust the size to fit your design
                  borderRadius: "100%",
                  position: "absolute",
                  top: "-70px", // Adjust the top position to align with the Avatar
                  left: "-10px", // Adjust the left position to align with the Avatar
                  zIndex: 0, // Set a lower zIndex to place it beneath the Avatar
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)", // Add a box shadow
                }}
              />
            </Box>
            {/* Add text below */}
            <Typography
              sx={{ fontWeight: "700", fontSize: "18px", mt: "-40px" }}
              variant="body1"
              gutterBottom
            >
              {title}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {city} | {members.length} Members
            </Typography>

            <Stack direction={"row"} sx={{
              mt: 2
            }}>
              <Chip
                label={label}
                icon={<PublicIcon />}
                component="a"
                href={`${USER_APP_BASE_URL}/community/${title.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${communityId}`}
                target="_blank"
                clickable
                sx={{
                  backgroundColor: '#f1f1f1',
                  fontSize: '12px',
                  textTransform: 'lowercase',
                  "@media (max-width: 600px)": {
                    marginBottom: "1rem",
                  },
                }}
              />
              <InputAdornment position="end">
                <Button
                  sx={{
                    backgroundColor: "#f1f1f1",
                    textTransform: "capitalize",
                    color: "#636363",
                    fontWeight: 'normal',
                    mt: 4,
                    fontSize: "12px",
                    "&:hover": { backgroundColor: "#f1f1f1" },
                    borderRadius: "20px 20px 20px 20px",
                  }}
                  variant="text"
                  color="primary"
                  startIcon={<FileCopyOutlinedIcon />}
                  onClick={handleCopyLink}
                >
                  {copied ? 'Copied!' : 'Copy'}
                </Button>
              </InputAdornment>
            </Stack>
          </div>

          <Box sx={{ paddingLeft: "1rem", pt: 2 }}>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                pb: 3,
                mx: 2,
              }}
            >
              <TextField
                size="small"
                sx={{
                  borderRadius: "100px",
                  width: "calc(50% - 5px)",
                  "&:hover": { border: "none" },
                }}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Button
                        sx={{
                          textTransform: "capitalize",
                          color: "#ffffff",
                          fontSize: { xs: '13px', md: '15px' },
                          fontWeight: 600,
                          backgroundColor: "#3B9B7F",
                          cursor: "default",
                          "&:hover": { backgroundColor: "#3B9B7F" },
                          ml: "-15px",
                          borderRadius: "20px 0px 0px 20px",
                        }}
                      >
                        Share With
                      </Button>
                      <Divider
                        orientation="vertical"
                        sx={{ height: 38 }} />
                      <Stack
                        direction="row"
                        spacing={1.5}
                        alignItems="center"
                        ml="10px"
                        sx={{ pt: 0.5 }}
                      >
                        <a
                          href={whatsappShareUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <WhatsAppIcon sx={{
                            fontSize: { xs: "25px", md: "30px" },
                            color: "#25d366",

                          }} />
                        </a>
                        <a
                          href={facebookShareUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FacebookIcon sx={{
                            fontSize: { xs: "25px", md: "30px" },
                            color: "#3b5998",

                          }} />
                        </a>
                        <a
                          href={linkedinShareUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LinkedInIcon
                            sx={{
                              fontSize: { xs: "25px", md: "30px" },
                              color: "#1976d2",

                            }} />
                        </a>
                        <a
                          href={twitterShareUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <TwitterIcon sx={{
                            fontSize: { xs: "25px", md: "30px" },
                            color: "#1da1f2",

                          }} />
                        </a>
                      </Stack>
                    </InputAdornment>
                  ),
                  style: {
                    borderRadius: "100px",
                    backgroundColor: "#FFFFFF",
                  },
                }} />
            </Box>
          </Box>

        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default CustomModal;
