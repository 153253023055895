import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { AuthContext, IAuthContext } from "../contexts/Auth.context";
// import { ICommunity } from "../models/communities.modal";


import { acceptInvitationRequest, requestsByCommunityById, sendRequestToJoin } from "../services/requests.service";
// import store from "../store";
// import { useSelector } from "react-redux";
import { Invitations } from "../models/Invitation.model";

export const useRequests = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [requests, setRequests] = useState<Invitations[]>([]);

  // const state = store.getState();
 
  // const selectedCommunity = useSelector(() => {
  //   return state?.selectedCommunity;
  // });

  
    // const [selectedCommunityId,setSelectedCommunityId]= useState(selectedCommunity.selectedCommunity?.id)

// TODO: Its like onload
  // useEffect(() => {
  //   if(!selectedCommunityId) return
  //   getCommunityRequestsById(selectedCommunityId??"");
    
  // }, [selectedCommunityId]);
 
  const getCommunityRequestsById = async (id: string) => {
   
    setIsLoading(true);
    const response = await requestsByCommunityById(getAccessToken(), id);
    if (response.status === 200) {
        setRequests(response.data.data);
      return response;
    } else if (response.status === 302) {
      return response;
    } else {
      enqueueSnackbar("Fetching invites failed", { variant: "error", autoHideDuration: 3000 });
    }
    setIsLoading(false);
  };
  const AcceptInvitation = async ( formData: FormData) => {
    try {
      setIsLoading(true);
      const response = await acceptInvitationRequest(
        getAccessToken(),
        
        formData
      );

      if (response.status === 200) {
        setRequests((prevInvitation) => [
          ...prevInvitation,
          response.data.data,
        ]);
        enqueueSnackbar("User added", { variant: "success", autoHideDuration: 3000 });
      } else {
        enqueueSnackbar("Couldn’t accept the request", { variant: "error", autoHideDuration: 3000 });
      }
      setIsLoading(false);
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn’t add user", {
        variant: "error",
        autoHideDuration: 3000
      });
    }
  };

  const SendCommunityRequest = async ( formData: FormData) => {
    try {
      setIsLoading(true);
      const response = await sendRequestToJoin(
        getAccessToken(),
        formData
      );

      if (response.status === 201) {
        setRequests((prevInvitation) => [
          ...prevInvitation,
          response.data.data,
        ]);
        enqueueSnackbar(response?.data?.message, { variant: "success", autoHideDuration: 5000 });
      } else {
      
        enqueueSnackbar(response?.data?.message, { variant: "error", autoHideDuration: 5000 });
      }
      setIsLoading(false);
      return response;
    } catch (error) {
      enqueueSnackbar("Failed to send request", {
        variant: "error",
        autoHideDuration: 3000
      });
    }
  };

  return {
    isLoading,
    requests,
    getCommunityRequestsById,
    AcceptInvitation,
    SendCommunityRequest,
  };
};
