import { Stack, Typography } from "@mui/material";
import Carousel from "react-multi-carousel";
import "./ArrowStyles.css";
import { JoinedAllCommunities } from "../AllCommunities/JoinedAllCommunities";
import { ICommunity } from "../../../models/communities.modal";
import { useCommunity } from "../../../hooks/useCommunity";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Loader from "../../Loader/Loader";



const JoinedCommunities = () => {
  const { isLoading, memberCommunities } = useCommunity();
  const [myMemberCommunities, setMyMemberCommunities] = useState<ICommunity[]>([]);
  // console.log(myMemberCommunities, "myMemberCommunities");
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {
      try {
        const responseData = await memberCommunities();
        setMyMemberCommunities(responseData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 6,
      slidesToSlide: 3,

    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 2.5,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };


  return (
    <>

      <Stack>
        <Stack
          direction={"row"}
          sx={{ display: "flex", justifyContent: "space-between", }}
        >
          <Typography
            sx={{
              fontSize: { xs: "14px", md: '18px' },
              fontWeight: 700,
              textDecoration: "none",
              color: "#000000",
              marginLeft: 2,

            }}
          >
            Joined Communities
          </Typography>

          {
            myMemberCommunities && myMemberCommunities.length > 5 ? (
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  color: "#50A1CA",
                  textAlign: "right",
                  alignItems: "end",
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={() => navigate(`/joined-communities`)}
              >
                {myMemberCommunities && (`${myMemberCommunities?.length} Communities`)}
              </Typography>
            ) : null
          }

        </Stack>
        {isLoading ? (
          <Loader />
        ) : (
          <Carousel showDots={false} arrows={true} responsive={responsive} >
            {myMemberCommunities?.map((elm: any, i: number) => (

              <div key={i} >
                <JoinedAllCommunities data={elm} />
              </div>
            ))}
          </Carousel>
        )}
      </Stack>

      <Stack >
        {!isLoading && myMemberCommunities.length === 0 && (
          <Stack direction={"row"}
            sx={{
              display: "flex",
              alignContent: 'center',
              justifyContent: 'center',
              border: '1px solid #BFBFBF',
              height: "200px",
              borderRadius: "6px",
              alignItems: 'center'
            }}>

            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 700,
                textDecoration: "none",
                color: "#838383",
                textAlign: "center",

              }}
            >
              No Communities
            </Typography>
          </Stack>
        )}
      </Stack>

    </>
  );
};

export default JoinedCommunities;
