import axios from "axios";
import { BASE_URL } from "../configurations/url.config";

export const get = async (token: string,) => {
    try {
        const response = await axios.get(`${BASE_URL}/utility/category/get`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        return response;
    } catch (err) {
        console.log("ERR :", err);
        return { status: 500, data: [] };
    }
};