
// import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { styles } from "./NoAuthLayout.styles";
import { Outlet } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Header from "../ExploreCommunities/Layout/Header";
import Sidebar from "../ExploreCommunities/Layout/Sidebar";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
// import store from "../../store";
import { Link } from 'react-router-dom';
import { ICommunity } from "../../models/communities.modal";
import { useCommunity } from "../../hooks/useCommunity";
// import { ADMIN_APP_BASE_URL } from "../../configurations/url.config";

// interface FormValues {
//   firstName: string;
//   lastName: string;
//   emailId: string;
//   phoneNumber: string;
// }

export default function NoAuthLayout() {
  // const theme = useTheme();
  // const communityState = store.getState();
  // const communities = communityState?.communities?.communities;
  // const navigate = useNavigate();

  // console.log(communities, "communities");
  const { memberCommunities } = useCommunity();
  const [myMemberCommunities, setMyMemberCommunities] = useState<ICommunity[]>([]);
  

  useEffect(() => {
    async function fetchData() {
      const responseData = await memberCommunities();
  
      setMyMemberCommunities(responseData)
    }
    fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(myMemberCommunities "MemberCommunities");


  // const userData = useLocation();
  // const users = userData?.state?.data;
  // // console.log("USER", users?.firstName)
  // const [profileImage, setProfileImage] = useState(
  //   "https://media.licdn.com/dms/image/C4D03AQHY0lgjd9835g/profile-displayphoto-shrink_800_800/0/1657463160696?e=2147483647&v=beta&t=Pzo6gxl5qtxsg875yYk1-9g21CQrNtaFcIvyWKjSYtc"
  // );



  // const [formValues] = useState<FormValues>({
  //   firstName: users?.firstName,
  //   lastName: users?.lastName,
  //   emailId: "",
  //   phoneNumber: users?.phoneNumber,

  // });
  // console.log(formValues);

  // const data = JSON.stringify(formValues);
  // console.log("Values:", data);


  // const usersData = store.getState();


  // const location = useLocation();
  // const isExploreCommunitiesPage = location.pathname === "/explore-communities" || location.pathname === "/invites-communities" ||
  //   location.pathname === "/trending-communities" || location.pathname === "/joined-communities" || location.pathname === "/admin-communities";
  // const isCreateCommunityPage =
  //   location.pathname.includes("/explore-create-community") || /^\/profile-edit\/\d+$/.test(location.pathname);
  // const isProfileEdit = /^\/profile-edit\/\d+/.test(location.pathname);



//   const navigateToAdminFlowCreateCommunnity = () => {
//     const token = localStorage.getItem("access-token");
//     const url = `${ADMIN_APP_BASE_URL}/login?token=${token}`;
//     window.open(url, '_blank');
// };



  return (
    <Grid container>
      {/* Sidebar */}
      <Grid item md={3} sm={12} xs={12}>

        <Stack
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
              xl: "block",
              xxl: "block",
            },
          }}
        >
          
            {/* <Link to="/home" style={{textDecoration:'none'}}>
              <Box
                ml={3}
                mt={1}
                component={"img"}
                src={require("../../assets/images/Communn-Logo.png")}
                alt=""
                sx={{ width: "60%" }}
              />
            </Link> */}
         

            <Box
              ml={3}
              mt={1}
              component={"img"}
              src={require("../../assets/images/Communn-Logo.png")}
              alt=""
              sx={{ width: "60%" }}
            />

          
        </Stack>

        <Sidebar />
      </Grid>

      {/* Main Content */}
      <Grid item md={9} sm={12} xs={12}>
        <Box sx={styles.main}>
          {/* Header Section */}
          <Header />

          {/* Content Section */}
          <Box sx={styles.contentSection}>
            {/* <Dashboard /> */}
            <Outlet />

            <Stack
              sx={{
                display: { xs: "block", sm: "block", md: "none", lg: "none" },
              }}
            >
              {/* <AppBar
                position="fixed"
                color="primary"
                sx={{
                  top: "auto",
                  bottom: 0,
                  backgroundColor: "#FFFFFF",
                  p: 1,
                  justifyContent: "center",
                  mt: -5,
                }}
              > */}
                {/* <Toolbar sx={{ justifyContent: "center" }}>
                  <Stack direction={"row"} display={"flex"} spacing={2}>
                    <Stack>
                      {communities.length >= 1 ? (
                        // Display "Home" button if communities exist
                        <Link to="/home" style={{ textDecoration: 'none' }}>
                        <Button
                          size="large"
                          variant="contained"

                          fullWidth
                          sx={{
                            background: "linear-gradient(to right bottom, #50A1CA, #3B9B7F)",
                            textTransform: "capitalize",
                            borderRadius: "100px",
                            boxShadow:'none',
                            fontSize: "10px",
                            width: 150,
                            display: isProfileEdit ? "none" : "block",
                            textAlign: 'center',
                          }}
                         
                        >
                          Home
                        </Button>
                        </Link>
                      ) : (
                        // Display "Create Community" button if no communities exist
                        <Button
                          size="large"
                          variant="contained"

                          fullWidth
                          sx={{
                            background: "linear-gradient(to right bottom, #50A1CA, #3B9B7F)",
                            textTransform: "capitalize",
                            borderRadius: "100px",
                            fontSize: "10px",
                            width: 150,
                            textAlign: 'center',
                            display: isProfileEdit ? "none" : "block",
                          }}
                          onClick={() => navigate(`/profile-edit/${usersData?.loggedInUser?.user?.id}`)}
                        >
                          Profile Edit
                        </Button>
                      )}
                    </Stack>
                    <Stack>
                    <Link to="/explore-communities" style={{ textDecoration: 'none' }}>
                      <Button
                        size="large"
                        variant="outlined"

                        fullWidth
                        sx={{
                          textTransform: "capitalize",
                          textAlign: 'center',
                          borderRadius: "100px",
                          border: "1px solid #50A1CA",
                          fontSize: "10px",
                          width: 150,
                          color: "#50A1CA",
                          display: isExploreCommunitiesPage ? "none" : "block",
                        }}
                       
                      >
                        Explore Communities
                      </Button>
                      </Link>
                    </Stack>
                    <Stack>
                      <Button
                        size="large"
                        variant="contained"

                        fullWidth
                        sx={{
                          background: isProfileEdit ? "linear-gradient(to right bottom, #50A1CA, #3B9B7F)" : "#FFFFFF",
                          textTransform: "capitalize",
                          borderRadius: "100px",
                          fontSize: "10px",
                          boxShadow:'none',
                          width: 150,
                          border: "1px solid #50A1CA",
                          textAlign: 'center',
                          color: isProfileEdit ? "#ffffff" : "#50A1CA",
                          display: isCreateCommunityPage ? "none" : "block",
                          '&:hover': {
                            backgroundColor: "#FFFFFF",
                          },
                        }}
                        onClick={navigateToAdminFlowCreateCommunnity}
                      >
                        Create Community
                      </Button>
                    </Stack>
                  </Stack>
                </Toolbar> */}
              {/* </AppBar> */}
            </Stack>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
