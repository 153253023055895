import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import paymentSuccess from '../../assets/lotties/payment-success.json';
import { ReactNode } from "react";
import { Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Lottie from "react-lottie";




interface MaxWidthDialogProps {
  open: boolean;
  amount: string;
  timer: number;
  txnid: string;
  dialogText: string;
  onOpen?: () => void;
  onClose?: () => void;
  onClickRetryButton?: () => void;
  onClickNavigateButton?: () => void;
  startIcon?: ReactNode;
  statusIcon?: ReactNode; // New prop for the start icon
  // New prop for startIcon style
  startIconOnClick?: () => void;
}

export default function PaymentSuccess({
  open,
  timer: intialtimer,

  onOpen,
  onClose,
  onClickNavigateButton,
  amount,
  txnid,

}: MaxWidthDialogProps) {
  const [fullWidth] = useState(true);
  const [maxWidth] = useState<DialogProps["maxWidth"]>("sm");

  const [time, setTime] = useState(intialtimer);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: paymentSuccess,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  useEffect(() => {
    if (!open) return;

    // setTime(intialtimer);
    const interval = setInterval(() => {
      setTime((prevTimer: any) => {
        if (prevTimer <= 1) {
          clearInterval(interval);
          onClose?.();
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [open, time, onClose]);
  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "5px",
          },
        }}
      >
        <DialogTitle
          display="flex"
          alignItems="flex-end"
          justifyContent="flex-end"
          sx={{ backgroundColor: "#F0F9FF", padding: "7px" }}
        >
          <Typography sx={{ color: "gray", fontSize: "13px", mr: 2, fontFamily: 'Inter' }}>
            Auto closing in {time} seconds
          </Typography>
          <Box
            onClick={onClose}
            sx={{
              backgroundColor: "#50A1CA",
              padding: "4px",
              borderRadius: "50%",
              width: "20px",
              height: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              fontSize: "10px",
              mt: "0.5px",

              cursor: "pointer",
              "&:hover": {
                // backgroundColor: "#50A1CA", // Background color on hover
                cursor: "pointer",
                border: "none",
              },
            }}
          >
            <Close sx={{ fontSize: "15px" }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
              mt: 1,
            }}
          >
            <Stack
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography
                sx={{ fontWeight: "bold" }}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >

                <Lottie
                  options={defaultOptions}
                  height={100}
                  width={100}
                />

              </Typography>
              <Typography
                sx={{ fontWeight: "bold" }}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CurrencyRupeeIcon sx={{ fontSize: "18px", fontFamily: 'Inter' }} />
                {amount}/-
              </Typography>
              <Typography sx={{ fontWeight: "700", fontFamily: 'Inter', textAlign: 'center' }}>
                Payment Successful!
              </Typography>

              {/* <Typography
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ color: "#1a1a1a", pl: 4, pr: 4, fontFamily: 'Inter', fontSize: '12px', textAlign: 'center' }}
              >
                Traxn Id:{" "} {txnid}
              </Typography> */}
              <Typography></Typography>
              <Stack direction={"row"} spacing={4} sx={{ mt: 1 }}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={onClickNavigateButton}
                  sx={{
                    backgroundColor: "#3B9B7F",
                    mt: 1,
                    textTransform: "capitalize",
                    fontFamily: 'Inter',
                    boxShadow: 'none',
                    "&:hover": { backgroundColor: "#3B9B7F", fontFamily: 'Inter' },
                  }}
                >
                  Home
                </Button>
              </Stack>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
