import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    Grid,
    Typography,
} from "@mui/material";
import { ITeam } from "../../models/communities.modal";
import { Close } from "@mui/icons-material";


interface membersProps {
    teamData: ITeam[];
}
const NewAboutTeam: React.FC<membersProps> = ({ teamData }) => {


    const [open, setOpen] = React.useState(false);
    const [fullWidth] = React.useState(true);
    const [maxWidth] = useState<DialogProps["maxWidth"]>("sm");
    const [selectedItem, setSelectedItem] = useState<ITeam | null>(null);

    const handleCloses = () => {
        setOpen(false);
    };

    const handleClickOpen = (item: ITeam) => {
        setSelectedItem(item);
        setOpen(true);
    };





    // console.log(productData, "Teams");
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1024 },
            items: 3,
            slidesToSlide: 1,
        },
        desktop: {
            breakpoint: { max: 1024, min: 800 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 800, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    const product = teamData?.map((item: any, index) => (
        <>
            <Card
                key={item._id}
                style={{
                    height: "90%", marginRight: "20PX", paddingBottom: "20px", borderRadius: '20px'
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Avatar sx={{
                        color: '#ffffff',
                        fontSize: { xs: "14px", md: '25px' },
                        fontFamily: 'Montserrat',
                        fontWeight: "700",
                        textAlign: 'center',
                        p: 5, background: "linear-gradient(90deg, #50A1CA 0%, #3B9B7F 100%)", borderRadius: '100px'
                    }}>0{index + 1}</Avatar>
                </Box>

                <CardContent sx={{ marginTop: "0px" }}>
                    <Typography
                        sx={{
                            color: '#2952A2',
                            fontSize: { xs: "12px", md: '16px' },
                            fontFamily: 'Montserrat',
                            fontWeight: "700",
                            textAlign: 'center',
                            textTransform: 'capitalize',
                            mt: 2
                        }}
                    >
                        {item.name}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: { xs: "13px", md: '15px' },
                            fontFamily: "Montserrat",
                            color: "#565656",
                            fontWeight: 600,
                            textTransform: "capitalize",
                            textAlign: 'center',
                            marginTop: '10px',
                        }}
                    >
                        {item.designation} | {" "}{item.experience}
                    </Typography>

                    <Box
                        sx={{
                            // display: 'flex',
                            alignItems: 'center',
                            marginTop: '10px',
                            paddingBottom: '15px',

                        }}
                    >
                        <Typography

                            sx={{
                                fontFamily: "Montserrat",
                                color: "#000000",
                                fontWeight: 400,
                                textAlign: 'justify',
                                flexGrow: 1,
                                fontSize: { xs: '12px', md: '14px' },
                            }}
                        >
                            {item?.description && item.description.length > 200
                                ? item.description.substring(0, 200) + ''
                                : item.description}
                        </Typography>
                        {item?.description && item?.description.length > 200 && (
                            <Button
                                onClick={() => handleClickOpen(item)}
                                sx={{
                                    color: "#2952A2",
                                    fontWeight: 500,
                                    fontSize: { xs: '12px', md: '14px' },
                                    textTransform: 'capitalize',
                                    textDecoration: 'underline',
                                    cursor: "pointer",
                                    pt: 0,
                                    fontFamily: 'Montserrat'
                                }}
                            >
                                Read More
                            </Button>
                        )}
                    </Box>

                </CardContent>

            </Card>

        </>
    ));

    return (
        <div>
            <Carousel showDots={false} responsive={responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
            >
                {product}
            </Carousel>
            {selectedItem && (
                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={open}
                    onClose={handleCloses}
                >
                    <DialogActions sx={{ backgroundColor: "#F0F9FF" }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ backgroundColor: "#F0F9FF" }}
                        >
                            <Typography
                                sx={{
                                    fontSize: { xs: "13px", md: "16px" },
                                    fontWeight: 600,
                                    textDecoration: "none",
                                    color: "#50A1CA",
                                    textTransform: "uppercase",
                                    marginLeft: 2,
                                    fontFamily: "Montserrat",
                                }}
                            >
                                {selectedItem.name}
                            </Typography>
                            <Box
                                onClick={handleCloses}
                                sx={{
                                    backgroundColor: "#50A1CA",
                                    padding: "4px",
                                    borderRadius: "50%",
                                    width: "20px",
                                    height: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "white",
                                    fontSize: "10px",
                                    mt: "0.5px",
                                    mr: "10px",
                                    cursor: "pointer",
                                    "&:hover": {
                                        backgroundColor: "#50A1CA", // Background color on hover
                                        cursor: "pointer",
                                        border: "none",
                                    },
                                }}
                            >
                                <Close sx={{ fontSize: "15px" }} />
                            </Box>
                        </Grid>
                    </DialogActions>
                    <DialogContent sx={{ p: 2 }}>
                        <DialogContentText>
                            <Typography sx={{
                                fontFamily: "Montserrat",
                                color: "#000000",
                                fontWeight: 500,
                                textTransform: "capitalize",
                                textAlign: 'left',
                                flexGrow: 1,
                                fontSize: { xs: '12px', md: '14px' },
                            }}> {selectedItem.description}</Typography>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            )}
        </div>
    );
};

export default NewAboutTeam;
