import { IconButton } from '@mui/material';
import { IPost } from '../Posts/Posts';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useState } from 'react';

interface FavoriteProps {
  post: IPost;
  userId: string | undefined;
  handleHeartClick: (e: React.MouseEvent<HTMLButtonElement>, data: any) => void;
}

export const Favorite: React.FC<FavoriteProps> = ({
  post,
  userId,
  handleHeartClick,
}) => {
  const isLikedByUser = post?.likedBy.some((user) => user._id === userId);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsAnimating(true);
    handleHeartClick(e, post);
  };

  // Remove animation class after animation ends
  const handleAnimationEnd = () => {
    setIsAnimating(false);
  };

  return (
    <IconButton
      onClick={handleClick}
      onAnimationEnd={handleAnimationEnd}
      className={isAnimating ? 'zoom-animation' : ''}
      sx={{
        '&:hover': {
          backgroundColor: '#fff',
          cursor: 'pointer',
        },
      }}
    >
      {isLikedByUser ? (
        <FavoriteIcon
          sx={{
            color: 'red',
            fontSize: '30px',
          }}
        />
      ) : (
        <FavoriteBorderIcon
          sx={{
            color: 'gray',
            fontSize: '30px',
          }}
        />
      )}
    </IconButton>
  );
};
