import React from "react";
import { Card, CardMedia, Paper, Stack } from "@mui/material";


export const NoActivity = () => {
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "85vh",
        }}
      >
        <Stack
          spacing={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ paddingBottom: "10px", p: 2 }}
        >
          <Card elevation={0}>
            <CardMedia
              component="img"
              alt="No posts"
              image={require("./../../assets/images/activities.png")}
              sx={{
                objectFit: "cover",
                width: "100%",
                height: "60vh",
              }}
            />
          </Card>
        </Stack>
      </Paper>
    </>
  );
};
