import React, { useEffect, useState } from 'react';
import { useAiPostSuggestions } from '../../hooks/usePostAi';
import { Typography, Box, } from '@mui/material';
// import { marked } from 'marked';
import Loader from '../Loader/Loader';

interface Props {
    community_name: string;
}

const PostAiSuggestions: React.FC<Props> = ({ community_name }) => {
    const [postAI, setPostAI] = useState<string[]>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setIsLoading] = useState(false);
    const [currentWords, setCurrentWords] = useState<string[]>([]);
    const { getAiPostSuggestions } = useAiPostSuggestions();

    useEffect(() => {
        setIsLoading(true);
        const fetchPostAi = async () => {
            const response = await getAiPostSuggestions(community_name);
            console.log(response, "response");
            if (response && response.status === 200) {
                setPostAI(response.data?.customResults);

            } else {
                console.error("Failed to fetch Post AI Suggestions");
            }
            setIsLoading(false);
        };

        fetchPostAi();
    }, [community_name]);

    useEffect(() => {
        if (postAI.length > 0 && currentIndex < postAI.length) {
            const words = postAI[currentIndex].split(' ');
            let wordIndex = 0;
            const timer = setInterval(() => {
                if (wordIndex < words.length) {
                    setCurrentWords(prevWords => [...prevWords, words[wordIndex]]);
                    wordIndex++;
                } else {
                    clearInterval(timer);
                    if (currentIndex + 1 < postAI.length) {
                        setCurrentIndex(prevIndex => prevIndex + 1);
                    }
                    setCurrentWords([]);
                }
            }, 100);

            return () => clearInterval(timer);
        }
    }, [currentIndex, postAI]);

    return (
        <>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <Loader />
                </Box>
            ) : (
                postAI.length > 0 ? (
                    <Box>
                        {/* {currentWords.length > 0 && (
                            <Paper style={{ padding: '16px', margin: '16px 0' }}>
                                <div dangerouslySetInnerHTML={{ __html: marked(currentWords.join(' ')) }} />

                            </Paper>
                        )} */}
                    </Box>
                ) : (
                    <Typography>No suggestions available.</Typography>
                )
            )}
        </>
    );
};

export default PostAiSuggestions;
