
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useSelector } from "react-redux";
import { Card, Paper, Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFetchCommunityData } from "../../hooks/useFetchCommunityData";





export const HomeCarousel = () => {

  const selectedCommunity = useSelector((state: any) => state?.selectedCommunity.selectedCommunity);

  const community_id = selectedCommunity?._id
  
  const { data, showPopup,  } = useFetchCommunityData(community_id);
 
  const selectedCommunityImages: string[] = data?.gallery || [];

  const defaultImages = [
    'https://upload-community-files.s3.ap-south-1.amazonaws.com/static/community_4.jpeg',
    'https://upload-community-files.s3.ap-south-1.amazonaws.com/static/community_3.png',
  ];


  const communityBanner = selectedCommunity?.banner

  const navigate = useNavigate();

  const handleNavigateExplore = () => {
    navigate('/joined-communities')
  }

  const handleNavigateSubscribe = () => {
    navigate('/subscriptions')
  }

  // console.log(data, "data");
  

  return (
    <>
      <Paper elevation={0}>
        <Card elevation={0}>
          <Carousel showThumbs={false} infiniteLoop autoPlay>
            {communityBanner && (
              <div>
                <Box
                  component={'img'}
                  src={communityBanner}
                  alt="Community Banner"
                  sx={{
                    width: "100%",
                    height: 300,
                    maxHeight: { xs: 200, md: 300 },
                    objectFit: "cover",
                    borderRadius: '5px'
                  }}
                />
              </div>
            )}
            {(selectedCommunityImages.length > 0 ? selectedCommunityImages : defaultImages).map((image, index) => (
              <div key={index}>
                <Box
                  component={'img'}
                  src={image}
                  alt={`Community Gallery ${index}`}
                  sx={{
                    width: "100%",
                    height: 300,
                    maxHeight: { xs: 200, md: 300 },
                    objectFit: "cover",
                    borderRadius: '5px'
                  }}
                />
              </div>
            ))}
          </Carousel>
        </Card>
      </Paper>



      {/* Expiry Popup */}


      <Dialog open={showPopup}>
        {data?.subscriptionStatus === "EXPIRED" ? (
          <DialogTitle sx={{ color: '#F94545', textAlign: 'center', fontSize: { xs: "16px", md: '20px' } }}>Subscription  Expired</DialogTitle>
        ) : (
          <DialogTitle sx={{ color: '#F94545', textAlign: 'center', fontSize: { xs: "16px", md: '20px' } }}>This is a paid community</DialogTitle>
        )}
        {data?.subscriptionStatus === "EXPIRED" ? (
          <DialogContent sx={{ textAlign: 'center', fontSize: { xs: "12px", md: '16px' } }}>
            Your plan has expired. Please renew to continue using the services.
          </DialogContent>
        ) : (
          <DialogContent sx={{ textAlign: 'center', fontSize: { xs: "12px", md: '16px' } }}>
            Please subscribe to a suitable plan to enjoy all the benefits it offers.
          </DialogContent>
        )}
        <DialogActions sx={{ pb: 3 }}>
          <Button
            variant="outlined"
            sx={{
              textTransform: "capitalize",
              borderRadius: "100px",
              fontSize: { xs: "12px", md: '16px' },
              width: { xs: 110, md: 130 },
              color: '#50a1ca',
              border: '1px solid #50a1ca',
            }} onClick={handleNavigateExplore}>
            Explore
          </Button>
          <Button
            variant="contained"
            sx={{
              background: "linear-gradient(to right bottom, #50a1ca, #3b9b7f)",
              textTransform: "capitalize",
              borderRadius: "100px",
              fontSize: { xs: "12px", md: '16px' },
              boxShadow: 'none',
              width: { xs: 110, md: 130 },
            }}
            onClick={handleNavigateSubscribe}
          >
            {data?.subscriptionStatus === "EXPIRED" ? "Renew Now" : "Subscribe"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
