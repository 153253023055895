import React, { useContext } from "react";
import {
  Avatar,
  Badge,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  SelectChangeEvent,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { AppBar } from "../../Layout/components/NavigationBar/NavigationBar.styles";
import { AuthContext } from "../../../contexts/Auth.context";
import store from "../../../store";
import { useSelector } from "react-redux";
import Close from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { logoutService } from "../../../services/logOut.service";


interface INavbarProps { }
// eslint-disable-next-line no-empty-pattern
const SubscriptionLayoutHeader = ({ }: INavbarProps) => {
  useContext(AuthContext);

  const state = store.getState();
  const loggedInUserData = useSelector(() => {
    return state?.loggedInUser;
  });


  const handleLogout = async () => {
    const success = await logoutService();

    if (success) {
      localStorage.removeItem("access-token");
      localStorage.removeItem("refresh-token");
      window.location.reload();
      // navigate('/');
    } else {
      console.error("Logout failed, unable to navigate to login.");
    }
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));





  const [opens, setOpens] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("xs");

  const handleClickOpens = () => {
    setOpens(true);
  };

  const handleCloses = () => {
    setOpens(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleMaxWidthChange = (event: SelectChangeEvent<typeof maxWidth>) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  return (
    <>
      <AppBar sx={{ backgroundColor: 'white', position: 'sticky', top: 0 }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <Link to="/explore-communities">
                <Box
                  mt={1}
                  component={"img"}
                  src={require("../../../assets/images/Communn-Logo.png")}
                  alt=""
                  sx={{ width: "45%" }}
                />
              </Link>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Link to="/explore-communities">
                <Box

                  mt={1}
                  component={"img"}
                  src={require("../../../assets/images/Communn-Logo.png")}
                  alt=""
                  sx={{ width: "15%" }}
                />
              </Link>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <IconButton
                disableRipple
                size="small"
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                onClick={handleClickOpens}
                color="inherit"
                sx={{ ml: 5 }}
              >
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <Avatar
                    src={loggedInUserData?.user?.avatar}
                    sx={{ backgroundColor: "#3C5AA0" }}
                  >
                    {loggedInUserData?.user?.avatar
                      ? null
                      : loggedInUserData?.user?.firstName?.charAt(0) +
                      "" +
                      loggedInUserData?.user?.lastName?.charAt(0)}
                  </Avatar>
                </StyledBadge>
              </IconButton>
              <Dialog maxWidth={maxWidth} open={opens} onClose={handleCloses}>
                <DialogActions sx={{ backgroundColor: "#F0F9FF" }}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{ backgroundColor: "#F0F9FF" }}
                  >
                    <Stack></Stack>
                    <Stack></Stack>
                    <Stack sx={{ backgroundColor: "#F0F9FF" }}>
                      <Box
                        onClick={handleCloses}
                        sx={{
                          backgroundColor: "#50A1CA",
                          padding: "4px",
                          borderRadius: "50%",
                          width: "20px",
                          height: "20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white",
                          fontSize: "10px",
                          mt: "0.5px",
                          mr: "10px",
                          cursor: "pointer",
                          "&:hover": {

                            cursor: "pointer",
                            border: "none",
                          },
                        }}
                      >
                        <Close sx={{ fontSize: "15px" }} />
                      </Box>
                    </Stack>
                  </Stack>
                </DialogActions>
                <DialogTitle>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      font: "Roboto",
                      // color: "red",
                    }}
                  >
                    Are you sure you want to log out?
                  </Typography>
                </DialogTitle>
                <Divider />

                <Stack
                  direction="row"
                  sx={{ alignItems: "center", justifyContent: "center" }}
                >
                  <DialogActions>
                    <Button
                      size="small"
                      onClick={handleLogout}
                      sx={{
                        cursor: "pointer",
                        pr: 1,
                        textTransform: "capitalize",
                        color: "White",
                        background:
                          "linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)",
                      }}
                    >
                      Yes
                    </Button>
                    <Divider
                      orientation="vertical"
                      style={{ marginLeft: 25 }}
                    />
                  </DialogActions>
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{
                      cursor: "pointer",
                      textTransform: "capitalize",
                      color: "#50A1CA",
                      border: "1px solid #50A1CA",
                    }}
                    onClick={handleCloses}
                  >
                    No
                  </Button>
                </Stack>
              </Dialog>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

    </>
  );
};

export default SubscriptionLayoutHeader;
