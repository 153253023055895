import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { AuthContext, IAuthContext } from '../contexts/Auth.context';
import {
  customPayment,
  getPaymentList,
  getPaymentStatus,
  initialSubscriptionPayment,
  initiatePayment,
  paymentRequestbyUser,
  paymenttransactionsbyloggedInUser,
  subscriptionpaymentsdue,
  updateSequencesStatus,
} from '../services/payment.service';
import { IPaymentList } from '../models/payment.model';
export const usePayment = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  //// Payment Details/////
  const getCommunityPaymentsById = async (id: string) => {
    setIsLoading(true);
    const response = await getPaymentList(getAccessToken(), id);

    if (response.status === 200) {
      setIsLoading(false);
      return response.data.data as IPaymentList[];
    } else {
      enqueueSnackbar('Fetching community Payments failed', {
        variant: 'error',
      });
    }
    setIsLoading(false);
  };
  ////Payment  initiate//////
  const initiatePaymentByIds = async (
    communityId: string,
    planId: string,
    sequenceIdsOrDueDate: string[] | string,
    amount: string
  ) => {
    setIsLoading(true);

    try {
      const sequenceIds =
        typeof sequenceIdsOrDueDate === 'string'
          ? [sequenceIdsOrDueDate]
          : sequenceIdsOrDueDate;

      const response = await initiatePayment(
        getAccessToken(),
        communityId,
        planId,
        sequenceIds, // Always pass as an array
        amount
      );

      if (response?.status === 200) {
        setIsLoading(false);
        return response?.data; // Return successful payment data
      } else {
        enqueueSnackbar('Payment initiation failed or No bank account found', {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar('An error occurred during payment initiation', {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const initialSubscriptionPaymentIds = async (
    communityId: string,
    planId: string,
    invitesId: string
  ) => {
    setIsLoading(true);
    const response = await initialSubscriptionPayment(
      getAccessToken(),
      communityId,
      planId,
      invitesId
    );
    console.log(response);
    if (response?.status === 200) {
      setIsLoading(false);
      return response?.data;
    } else {
      enqueueSnackbar('Bank account not added', { variant: 'error' });
    }
    setIsLoading(false);
  };

  const updateSequencesPaymentStatus = async (
    communityId: string,
    ids: any
  ) => {
    const response = await updateSequencesStatus(
      getAccessToken(),
      communityId,
      ids
    );
    return response?.data;
  };
  const getPaymentStatusById = async (id: string) => {
    const response = await getPaymentStatus(getAccessToken(), id);
    if (response?.status === 200) {
      return response?.data as IPaymentList[];
    } else {
      return response?.data as IPaymentList[];
      // enqueueSnackbar("Error while fetching payment status", {
      //   variant: "error",
      // });
    }
  };
  const CustomPay = async (id: string, formData: FormData) => {
    const response = await customPayment(getAccessToken(), id, formData);
    console.log(' ++++++++++++++++++++', response);
    if (response?.status === 200) {
      return response.data as any;
    } else {
      const msg =
        'No Active Bank Account to receive payment, Please check with community admin.';
      enqueueSnackbar(msg, { variant: 'error' });
    }
  };
  // get user payment request
  const userPaymentRequest = async (communityId: string, userId: string) => {
    const response = await paymentRequestbyUser(
      getAccessToken(),
      communityId,
      userId
    );

    if (response?.status === 200) {
      return response.data.requests;
    } else {
      // return response
      // enqueueSnackbar("Error while fetching payment status", {
      //   variant: "error",
      // });
    }
  };

  // const userPaymentTransactionList = async (communityId:string,) => {
  //   const response = await paymenttransactionsbyUser(getAccessToken(),communityId);
  //   // console.log(communityId,userId);
  //   console.log(response);
  //   if (response?.status === 200) {
  //     return response.data.data as any[]
  //   } else {
  //     // return response
  //     // enqueueSnackbar("Error while fetching payment status", {
  //     //   variant: "error",
  //     // });
  //   }

  // }
  const userPaymentTransactionList = async () => {
    const response = await paymenttransactionsbyloggedInUser(getAccessToken());
    // console.log(communityId,userId);

    if (response?.status === 200) {
      return response.data.data as any[];
    } else {
      // return response
      // enqueueSnackbar("Error while fetching payment status", {
      //   variant: "error",
      // });
    }
  };
  const paymentsDue = async () => {
    const response = await subscriptionpaymentsdue(getAccessToken());
    // console.log(communityId,userId);

    if (response?.status === 200) {
      return response.data;
    } else {
      // return response
      // enqueueSnackbar("Error while fetching payment status", {
      //   variant: "error",
      // });
    }
  };
  //////////////////////////////////////////////////////////////// return statement
  return {
    isLoading,
    getCommunityPaymentsById,
    initiatePaymentByIds,
    getPaymentStatusById,
    CustomPay,
    userPaymentRequest,
    userPaymentTransactionList,
    initialSubscriptionPaymentIds,
    paymentsDue,
    updateSequencesPaymentStatus,
  };
};
