import { Stack } from "@mui/material";
import { RotatingLines } from "react-loader-spinner"
import { useLocation } from "react-router-dom";

export const Loader = () => {

  const location = useLocation();
  const isExploreCommunitiesPage = location.pathname === "/explore-communities";

  return (
    <Stack
      spacing={2} direction="row"
      sx={{
        color: 'grey.500',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // background: isExploreCommunitiesPage
        //   ? "transparent"
        //   : "#ffffff",
        height: isExploreCommunitiesPage ? '30vh' : '50vh'
      }}
    >
      <RotatingLines
        visible={true}
        width="30"
        strokeWidth="5"
        strokeColor="#777777"
        ariaLabel="rotating-lines-loading"
      />
      {/* <CircularProgress color="inherit" /> */}
      {/* <Box
        component={"img"}
        src={require("../../assets/images/loader.gif")}
        alt=""
        sx={{ width: "50%" }}
      /> */}
    </Stack>
  );
};

export default Loader;
