import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useInvitations } from '../../../hooks/useInvitations';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { KingIcon } from '../../../assets/icons';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import {
  ADMIN_APP_BASE_URL,
  USER_APP_BASE_URL,
} from '../../../configurations/url.config';
import { useSnackbar } from 'notistack';
import Close from '@mui/icons-material/Close';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import InvitesandJoinPreview from '../CommunityPreview/InvitesandJoinPreview';
import { useCommunity } from '../../../hooks/useCommunity';
import { loadSelectedCommunity } from '../../../store/slices/CommunitySlice';
import { useDispatch } from 'react-redux';
import { Invitations } from '../../../models/Invitation.model';

interface InvitesAllCommunitiesProps {
  community: any; // Replace 'any' with the actual type of 'community'
  _id: string; // Assuming _id is a string
  message: string; //
  invites: Invitations;
}

export const InvitesAllCommunities: React.FC<InvitesAllCommunitiesProps> = ({
  community,
  _id,
  message,
  invites,
}) => {
  const [showBox, setShowBox] = useState(true);
  const [copied, setCopied] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const { joinInvitation } = useInvitations();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [link] = useState('');
  const [maxPopWidth] = React.useState<DialogProps['maxWidth']>('sm');

  const navigate = useNavigate();
  const { getCommunityById } = useCommunity();

  // console.log(invites?.role,"Role");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleSkip = () => {
  //   setShowBox(false);
  // };

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const navigateToAdminFlow = () => {
    const token = localStorage.getItem('access-token');
    const id = community?._id;
    const url = `${ADMIN_APP_BASE_URL}/login?communityId=${id}&token=${token}`;
    window.open(url, '_blank');
  };

  const dispatch = useDispatch();

  const handleJoinClick = async (communityId: string, inviteId: string) => {
    try {
      const formData = new FormData();
      // console.log(formData);
      // Append the community_id and invites_id to the FormData object
      formData.append('community_id', communityId);
      formData.append('invites_id', inviteId);
      // console.log("data", formData);
      const response = await joinInvitation(formData);
      // console.log("data", response);
      if (response && response.status === 200) {
        await getCommunityById(communityId);
        if (community?.collectSubscription === 'YES') {
          if (invites?.role === 'USER') {
            navigate('/subscriptions', { state: { _id: communityId } });
            dispatch(loadSelectedCommunity(community));
          } else if (invites?.role === 'ADMIN') {
            navigateToAdminFlow();
          }
        } else if (community?.collectSubscription === 'NO') {
          if (invites?.role === 'USER') {
            navigate(`/community/${communityId}/about`, {
              state: { id: communityId },
            });
          } else if (invites?.role === 'ADMIN') {
            navigateToAdminFlow();
          }
        }
        setShowBox(false);
      } else {
        // console.log("Joined Community Failed");
      }
    } catch (error) {
      // Handle any errors that occur during the joinInvitation function
      // You can display an error message here as well
    }
  };
  // console.log(community)

  const handlePreviewCommunity = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCopyLink = () => {
    const labelContent = `${USER_APP_BASE_URL}/community/${community?.name?.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${community?._id}`;
    navigator.clipboard
      .writeText(labelContent)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
        enqueueSnackbar('Link copied successfully', { variant: 'success' });
      })
      .catch((error) => {
        console.error('Error copying text: ', error);
      });
  };

  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${community?.name?.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${community?._id}`
  )}`;

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${community?.name?.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${community?._id}`
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${community?.name?.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${community?._id}`
  )}`;
  const linkedinShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${community?.name?.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${community?._id}`
  )}`;

  return (
    <>
      {showBox && (
        <Stack spacing={2} sx={{ p: 1 }}>
          <Card
            sx={{
              textAlign: 'center',
              borderRadius: '10px',
              p: 2,
              m: 1,
              minHeight: { xs: '20vh', md: '25vh' },
            }}
          >
            <Stack
              direction={'row'}
              alignContent={'space-evenly'}
              justifyContent={'space-between'}
            >
              <Stack direction={'row'} alignContent={'center'}>
                {' '}
                {community?.type === 'PRIVATE' ? (
                  <LockOutlinedIcon
                    sx={{ color: '#838383', fontSize: '18px' }}
                  />
                ) : (
                  <Stack sx={{ height: '25px' }}></Stack>
                )}
                {community?.collectSubscription === 'YES' ? (
                  <KingIcon sx={{ color: '#838383' }} />
                ) : (
                  <Stack sx={{ height: '25px' }}></Stack>
                )}
                <BootstrapTooltip title='Request Message' placement='right'>
                  <SmsOutlinedIcon
                    onClick={handleClickOpen}
                    sx={{
                      color: '#838383',
                      fontSize: '18px',
                      cursor: 'pointer',
                    }}
                  ></SmsOutlinedIcon>
                </BootstrapTooltip>
                <Dialog
                  fullWidth={fullWidth}
                  maxWidth={maxPopWidth}
                  open={open}
                  onClose={handleClose}
                >
                  <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
                    <Grid
                      container
                      direction='row'
                      justifyContent='space-between'
                      alignItems='center'
                      sx={{ backgroundColor: '#F0F9FF' }}
                    >
                      <Typography
                        sx={{
                          fontSize: '17px',
                          fontWeight: 500,
                          textDecoration: 'none',
                          color: '#50A1CA',

                          marginLeft: 2,
                        }}
                      >
                        Request Message
                      </Typography>
                      <Box
                        onClick={handleClose}
                        sx={{
                          backgroundColor: '#50A1CA',
                          padding: '4px',
                          borderRadius: '50%',
                          width: '20px',
                          height: '20px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: 'white',
                          fontSize: '10px',
                          mt: '0.5px',
                          mr: '10px',
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: '#50A1CA', // Background color on hover
                            cursor: 'pointer',
                            border: 'none',
                          },
                        }}
                      >
                        <Close sx={{ fontSize: '15px' }} />
                      </Box>
                    </Grid>
                  </DialogActions>
                  <DialogContent sx={{}}>
                    <Typography>{message}</Typography>
                  </DialogContent>
                </Dialog>
              </Stack>

              <Stack>
                <BootstrapTooltip title='Info' placement='left'>
                  <InfoOutlinedIcon
                    onClick={handlePreviewCommunity}
                    sx={{
                      color: '#838383',
                      fontSize: '18px',
                      cursor: 'pointer',
                    }}
                  ></InfoOutlinedIcon>
                </BootstrapTooltip>
              </Stack>
            </Stack>
            <Stack display='flex' alignItems='center' justifyContent='center'>
              <Avatar
                className='product--image'
                src={community?.logo}
                alt='community-profile'
                sx={{
                  objectFit: 'cover',
                  width: '60px',
                  height: '60px',
                  borderRadius: '100px',
                }}
              />
            </Stack>

            {/* Render name with conditional check */}
            {community?.name ? (
              <Typography
                variant='h2'
                sx={{
                  fontSize: '12px',
                  color: '#4D4C4C',
                  fontWeight: '600',
                  mt: 0.5,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%', // Ensure the text doesn't overflow the container
                }}
              >
                {community.name.length > 20
                  ? `${community.name.slice(0, 20)}...`
                  : community.name}
              </Typography>
            ) : (
              <Stack sx={{ height: '15px' }}></Stack>
            )}

            {/* Render city  with conditional check */}
            {community?.city ? (
              <Typography
                sx={{ fontSize: '10px', color: '#878787', fontWeight: '500' }}
              >
                {community?.city.length > 20
                  ? `${community?.city.slice(0, 20)}...`
                  : community?.city}
              </Typography>
            ) : (
              <Typography
                sx={{ fontSize: '10px', color: '#878787', fontWeight: '500' }}
              >
                No city
              </Typography>
            )}

            {/* Render members count with conditional check */}
            {community?.members !== undefined ? (
              <Typography
                sx={{ fontSize: '11px', color: '#353535', fontWeight: '500' }}
              >
                {community?.members?.length}
              </Typography>
            ) : (
              <Stack sx={{ height: '15px' }}></Stack>
            )}
            <Stack
              direction={'row'}
              alignContent={'center'}
              justifyContent={'center'}
              spacing={1}
              mt={1}
            >
              <Button
                variant='contained'
                sx={{
                  background:
                    'linear-gradient(to right bottom, #50A1CA, #3B9B7F)',
                  textTransform: 'capitalize',
                  height: '25px',
                  boxShadow: 'none',
                }}
                onClick={() => handleJoinClick(community?._id, _id)}
              >
                Accept
              </Button>
              {/* <Button
                variant="outlined"
                sx={{
                  border: "1px solid #3B9B7F",
                  textTransform: "capitalize",
                  height: "25px",
                  color: "#9C9C9C",
                  width:'50px'

                }}
                onClick={handleSkip}
              >
                Skip
              </Button> */}
            </Stack>
          </Card>
        </Stack>
      )}

      <InvitesandJoinPreview
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        logo={community?.logo}
        id={community?._id}
        title={community?.name}
        city={community?.city}
        members={community?.members.length || []}
        totalmembers={community?.totalMembers}
        status={community?.status}
        link={link}
        type={community?.type}
        description={community?.description}
        handleCopyLink={handleCopyLink}
        copied={copied}
        whatsappShareUrl={whatsappShareUrl}
        facebookShareUrl={facebookShareUrl}
        linkedinShareUrl={linkedinShareUrl}
        twitterShareUrl={twitterShareUrl}
        coverImageSrc={community?.banner}
      />
    </>
  );
};
