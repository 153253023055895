import { Stack, Typography } from "@mui/material";
import React from "react";
import { NoActivity } from "../AlternatePages/NoActivity";
import { SubNavbar } from "../Layout/components/SubNavbar";
import { Helmet } from "react-helmet";

const Activities = () => {
  return (
    <>
    <Helmet>
        <title>
          Activities - Onecommunn - Build & Manage your community
        </title>
        <meta
          name="description"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta
          name="keywords"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
      </Helmet>
    <Stack direction="row" spacing={2} alignItems="center">
    <SubNavbar>
        <Stack>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 500,
              color: "#50A1CA",
              fontFamily: "Inter",
              pb:1,
            }}
          >
           Activities
          </Typography>
        </Stack>
      </SubNavbar>
    </Stack>
      <NoActivity />
    </>
  );
};
export default Activities;
