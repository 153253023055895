import React, { useContext, useEffect, useState } from "react";
import {
  DialogProps,
  Grid,
  Paper,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
} from "@mui/material";
import Box from "@mui/material/Box";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { SubNavbar } from "../Layout/components/SubNavbar";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { IMultiMedia } from "../../models/multi-media.model";
import { ICommunity } from "../../models/communities.modal";
import "simplebar-react/dist/simplebar.min.css";
import { useCourses } from "../../hooks/useCourses";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import { updateCourseByCourseId } from "../../services/courses.service";
import { AuthContext, IAuthContext } from "../../contexts/Auth.context";
import { useSnackbar } from "notistack";
import store from "../../store";
import { NoCourses } from "../AlternatePages/NoCourses";
import { metaConfig } from "../../utils/constants";

interface IParam {
  name: string;
  value: string;
  icon: string;
}
export interface IPlan {
  _id: string;
  name: string;
  description: string;
  image: string;
  parameters: IParam[];
  createdAt: string;
  updatedAt: string;
  status: string;
  duration: string;
  interval: string;
  billingFrequency: string;
  summary: string;
  promocode: string;
  totalPlanValue: number;
  startDate: string;
  offerValue: number;
  community: string;
  images: IMultiMedia | "";
  documents: IMultiMedia | "";
  document: IMultiMedia | "";
  communityData: ICommunity;
  offerEndsDuration: string;
  subscribers: string;
}

const Courses = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();



  const navigate = useNavigate();


  const handleNavigate = (row: any) => {
    navigate(`/course-lists/view/${row?._id}`)
  }

  // ****Course list by communityId Api //
  const { courseListByCommunityId } = useCourses();
  const [courseList, setCourseList] = useState([]);
  const [selectedId, setSelectedId] = useState<any>();
  const [, setSelectedRowStatus] = useState<any>();
  // console.log(courseList);s
  const [loading, setLoding] = useState(false);

  const selectedCommunity = useSelector(
    (state: any) => state?.selectedCommunity
  );
  const selectedCommunityId = selectedCommunity?.selectedCommunity?._id;


  const [reload, setReload] = useState(false);
  useEffect(() => {
    const loadCourseList = async () => {
      try {
        setLoding(true);
        const data = await courseListByCommunityId(selectedCommunityId);
        if (data && data.courses) {
          setCourseList(data.courses);
        }
      } catch (error) {
        console.error("Error fetching course list:", error);
      } finally {
        setLoding(false);
      }
    };

    loadCourseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunityId, reload]);

  // table actions
  const [, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedId("");
    setSelectedRowStatus("");
  };

  const [courseValues] = useState<any>({
    status: "",
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmit = async (e: any, status: string, id: string) => {
    e.preventDefault();

    //  formData.append("status",status)
    courseValues.status = status;

    // setLoader(true); // Set loading to true when initiating the request
    if (selectedId || id) {
      // Editing an existing plan
      const updateResponse = await updateCourseByCourseId(
        selectedCommunityId,
        selectedId || id,
        getAccessToken(),
        courseValues,
        null,
        null
      );
      if (updateResponse.data && updateResponse.status === 200) {
        if (status === "CREATED") {
          enqueueSnackbar("Course Unpublished Succesfully", {
            variant: "success",
            autoHideDuration: 3000,
          });
        } else if (status === "PUBLISHED") {
          enqueueSnackbar("Course Published Succesfully", {
            variant: "success",
            autoHideDuration: 3000,
          });
        }

        setReload(!reload);
        handleCloseMenu();

      } else {
        enqueueSnackbar("Failed to update Course", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    }
  };


  const usersData = store.getState();


  return (
    <>
      <Helmet>
        <title>{metaConfig.title.courses}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.description} />
        <meta name="keywords" content={metaConfig.description} />
      </Helmet>
      <Stack direction={"column"} spacing={1} >
        <SubNavbar>
          <Stack>
            <Typography
              sx={{
                fontSize: { xs: "15px", md: "20px" },
                fontWeight: 500,
                color: "#50A1CA",
                fontFamily: "Inter",
                pb: 1,
              }}
            >
              Courses
            </Typography>
          </Stack>
        </SubNavbar>
      </Stack>
      <Stack direction={"column"} spacing={1}>
        <Box
          sx={{
            backgroundColor: "#ffffff",
            height: { xs: "15vh", md: "20vh" },
            borderRadius: "5px",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack
            spacing={{ xs: 0, md: 3 }}
            sx={{
              display: "flex",
              flexDirection: { xs: "row", sm: "row", md: "row" },
              p: { xs: 4, md: 8 },
              alignItems: { xs: "center" },
            }}
          >
            <Stack>
              <Box
                component={"img"}
                sx={{
                  height: { xs: "50px", md: "90px" },
                  width: { xs: "50px", md: "90px" },
                }}
                src={require("../../assets/images/Group (1).png")}
                alt=""
              />
            </Stack>
            <Stack pl={3}>
              <Typography
                sx={{
                  fontSize: { xs: "15px", md: "20px" },
                  fontWeight: 500,
                  color: "#50A1CA",
                  fontFamily: "Inter",
                }}
              >
                Welcome {usersData?.loggedInUser?.user?.firstName}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "12px", md: "12px" },
                  fontWeight: 500,
                  color: "#838383",
                  fontFamily: "Inter",
                }}
              >
                Get the best content or exclusive ebook and courses.
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Box
          sx={{ backgroundColor: "white", borderRadius: "5px", height: "50vh" }}
        >
          <Stack sx={{ mt: 2 }} display={{ xs: "none", md: "block", xl: "block", lg: "block" }}   >
            <TableContainer
              component={Paper}
              elevation={0}
              sx={{ height: "calc(100vh - 37vh)", paddingTop: "0" }}
            >
              <Table
                sx={{
                  "& th": {
                    fontSize: "13px",
                    fontWeight: 500,
                    color: "#000000",
                    fontFamily: "Inter",
                    pt: 0,
                    pl: 1,
                    pb: 0,
                  },
                  "& td": {
                    borderColor: "#E0E0E0",
                    fontSize: "13px",
                    fontWeight: 400,
                    color: "#565656",
                    p: "0px 10px 0px 10px",

                    "& .MuiTypography-root": { fontSize: { xs: "12px", md: "12px" }, },
                  },
                }}
              >
                <TableHead
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    background: "#ffffff",
                  }}
                >
                  <TableRow sx={{ pl: 1 }}>
                    <TableCell
                      sx={{
                        minWidth: 250,
                        color: "#3760A9",
                        fontSize: "10px",
                        fontWeight: "600",
                      }}
                    >
                      Course Name
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: 250,
                        color: "#3760A9",
                        fontSize: "10px",
                        fontWeight: "600",
                      }}
                    >
                      Image
                    </TableCell>

                    <TableCell
                      sx={{
                        minWidth: 150,
                        color: "#3760A9",
                        fontSize: "10px",
                        fontWeight: "600",
                      }}
                    >
                      Type
                    </TableCell>
                    <TableCell
                      sx={{
                        minWidth: 150,
                        color: "#3760A9",
                        fontSize: "10px",
                        fontWeight: "600",
                        display: { xs: 'none', md: 'block' }
                      }}
                    >
                      End Date
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={4} style={{ textAlign: "center" }}>
                        <Loader />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {courseList?.length > 0 ? (
                        courseList?.map((row: any, index: number) => (

                          <TableRow key={row.id}>
                            <TableCell>
                              <Box
                                sx={{
                                  backgroundColor: "#ffffff",
                                  borderRadius: "5px",
                                  justifyContent: "center",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Stack
                                  spacing={3}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    py: 2,
                                  }}
                                >

                                  <Stack >
                                    <Typography
                                      sx={{
                                        fontSize: { xs: "13px", md: "18px" },
                                        fontWeight: 500,
                                        // color: "#000000",
                                        fontFamily: "Inter",
                                        textDecoration: "underLine",
                                        color: "#50A1CA",
                                        cursor: "pointer"
                                      }}
                                      onClick={() => { handleNavigate(row); }}
                                    >
                                      {row?.name}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: { xs: "12px", md: "14px" },
                                        fontWeight: 500,
                                        color: "#838383",
                                        fontFamily: "Inter",
                                      }}
                                    >
                                      {row?.price}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Stack
                                spacing={3}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  py: 2,
                                }}
                              >
                                <Stack>
                                  <img
                                    src={(row?.coverImage) ? row?.coverImage?.value : require("./../../assets/images/courseIcon@2x.png")}
                                    alt=""
                                    style={{
                                      height: "100px",
                                      width: "150px",
                                      maxHeight: '100px',
                                      borderRadius: "10px",
                                      cursor: "pointer",
                                      objectFit: "cover",

                                    }}
                                    onClick={() => { handleNavigate(row); }}
                                  />
                                </Stack>
                              </Stack>
                            </TableCell>

                            <TableCell>
                              <Typography sx={{
                                color: "#565656", fontSize: { xs: "12px", md: "14px" },
                                fontWeight: 500,
                              }}>
                                {row?.pricing}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{}}>
                              <Stack display={"flex"} flexDirection={"row"}>
                                <Typography sx={{ color: "#565656" }}>

                                  {new Date(row?.updatedAt).toLocaleDateString("en-US")}
                                </Typography>

                              </Stack>
                            </TableCell>



                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4} sx={{ color: '#777777', fontSize: '50px', fontWeight: '500', textAlign: "center", border: 'none' }}>
                            <NoCourses />
                            <h2 style={{ color: '#777777', fontSize: '15px', fontWeight: '500' }}>No Courses Available</h2>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

          </Stack>
          <Grid
            container
            direction={"row"}
            display={{ xs: "inline-flex", md: "none", xl: "none", lg: "none" }}
            alignItems={"center"}
            // justifyContent={"center"}
            spacing={1.5}
            sx={{ p: 1 }}
          >
            {loading ? ( // Show loader while loading
              <Loader />
            ) : (
              <>
                {courseList?.map((row: any, index) => (
                  <>
                    <Grid
                      item
                      xs={4}
                      display={"flex"}
                      alignItems={"center"}
                      // justifyContent={"center"}
                      key={index}
                    >
                      <img
                        src={
                          row?.coverImage
                            ? row?.coverImage?.value
                            : ''
                        }
                        alt=""
                        style={{

                          width: "100%",
                          borderRadius: "5px",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      display={"flex"}
                    // alignItems={"center"}
                    // justifyContent={"center"}
                    >
                      <Stack
                        display={"flex"}
                        alignItems={"flex-start"}
                        justifyContent={"flex-start"}
                        spacing={0.3}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "11px" },
                            fontWeight: 500,
                            color: "#3C5AA0",
                            fontFamily: "Inter",
                            textTransform: "capitalize",
                          }}
                        >
                          {row?.pricing}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: "13px" },
                            fontWeight: 500,
                            color: "black",
                            textDecoration: "underline",

                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleNavigate(row);
                          }}
                        >
                          {" "}
                          {row?.name?.length > 13
                            ? `${row?.name?.substring(0, 13)}...`
                            : row.name}
                        </Typography>
                        {row?.pricing === "PAID" && (
                          <Typography
                            sx={{
                              fontSize: { xs: "13px" },
                              fontWeight: 500,
                              color: "gray",
                              fontFamily: "Inter",
                              textTransform: "capitalize",
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            ₹{row?.plan?.offerValue}/-
                          </Typography>
                        )}
                        <Stack
                          direction={"row"}
                          spacing={1}
                          display={"flex"}
                          alignItems={"center"}
                          sx={{ backgroundColor: "#F0F9FF" }}
                        >

                          <Typography
                            sx={{ fontSize: { xs: "11px" }, color: "#565656" }}
                          >
                            Validity -
                          </Typography>
                          <Typography
                            sx={{ fontSize: { xs: "11px" }, color: "#565656" }}
                          >
                            {new Date(row?.updatedAt).toLocaleDateString("en-US")}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Stack
                        display={"flex"}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"flex-start"}
                      >

                        <MoreVertOutlinedIcon
                          sx={{
                            color: "#565656",
                            fontSize: "18px",
                            ml: 2,
                          }}
                        />

                      </Stack>

                    </Grid>

                  </>

                ))}
              </>
            )}
          </Grid>
        </Box>
      </Stack>
    </>
  );
};
export default Courses
