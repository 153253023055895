// import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
// import MuiDrawer from "@mui/material/Drawer";

// export const drawerWidth = 400;

export const styles = {
  main: {
    // flexGrow: 1,
    // p: 3,

    height: "100vh",
    overflow: "hidden",
    backgroundColor: '#FAFAFA',

  },
  contentSection: {
    height: "calc(100vh - 60px)",
    overflow: "auto",
    justifyContent: 'center',
    width: '100%',
    mt: 3

  },
};


