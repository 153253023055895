import axios from "axios";
import { BASE_URL } from "../configurations/url.config";

export const generateQrCode = async (
  token: string,
  id: string,
  url: string
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/communities/${id}/generate-qr-code`,
      { url },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      }
    );

    // console.log(response.data);
    return response;
  } catch (err) {
    console.log("ERR :", err);
    return { status: 500, data: {} };
  }
};
