import { FileCopyOutlined } from "@mui/icons-material";
import {
  Breadcrumbs,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LinkIcon from "@mui/icons-material/Link";
import { useNavigate, useParams } from "react-router-dom";
import { ILinks, interfaceCourse } from "../../models/courses.model";
import { useCourses } from "../../hooks/useCourses";

import Loader from "../Loader/Loader";

import CourseInfoCard from "./CourseInfoCard";
import BreadCrumbs from "../AlternatePages/BreadCrumbs";


const ViewCourse = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const courseId = id || "";

  const [course, setCourse] = useState<interfaceCourse[]>([]);
  const [links, setLinks] = useState<ILinks[]>([]);

  const { getCourseByCourseId } = useCourses();

  const selectedCommunity = useSelector(
    (state: any) => state?.selectedCommunity.selectedCommunity
  );
  const selectedCommunityId = selectedCommunity?._id;

  const [isCourseDataLoading, setCourseDataLoading] = useState(false);

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        setCourseDataLoading(true);
        const data = await getCourseByCourseId(selectedCommunityId, courseId);

        if (data && data.courses) {
          setCourse(data.courses);
          const allLinks = data.courses.flatMap(
            (course: interfaceCourse) => course.links
          );
          setLinks(allLinks);
          // const allFiles = data.courses.flatMap((course: interfaceCourse) => course.documents);
          // setSelectedFiles(allFiles)
        }
      } catch (error) {
        console.error("Error fetching course list:", error);
      } finally {
        setCourseDataLoading(false);
      }
    };

    fetchCourse();
  }, [selectedCommunityId]);

  const refetchCourseData = async () => {
    const response = await getCourseByCourseId(selectedCommunityId, courseId);
    if (response.courses) {
      setCourse(response.courses);
      const allLinks = response.courses.flatMap(
        (course: interfaceCourse) => course.links
      );
      setLinks(allLinks);
    }
  };

  //DRAG
  const [draggedItem, setDraggedItem] = useState<number | null>(null);
  const handleDragStart = (index: number) => {
    setDraggedItem(index);
  };

  const handleDragOver = (index: number) => {
    if (draggedItem === null || index === draggedItem) {
      return;
    }

    const newLinks = [...links];

    const draggedLink = newLinks[draggedItem];

    newLinks.splice(draggedItem, 1);
    newLinks.splice(index, 0, draggedLink);
    setLinks(newLinks);
    setDraggedItem(index);
  };

  return (
    <>
      < BreadCrumbs title="Courses" subTitle="Details" link="/course-lists" />
      {isCourseDataLoading ? (
        <Loader />
      ) : (
        <Stack
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: '5px',
            my: { xs: 0, sm: 0, md: 1 },
          }}
          spacing={2}
        >
          {/* <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            sx={{ color: "#3760A9", pt: 2 }}
          >
            <Typography
              sx={{
                fontSize: { xs: "15px", md: "20px" },
                fontWeight: 500,
                color: "#50A1CA",
                fontFamily: "Inter",
                px: 2,
              }}
            >
              Courses
            </Typography>
            <Stack sx={{ px: 3 }}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" onClick={() => navigate(`/course-lists`)} sx={{
                  fontSize: { xs: "14px", md: "16px" },
                  cursor: 'pointer', fontFamily: 'Inter'
                }}>
                  Home
                </Link>
                <Typography color="#50A1CA" sx={{ fontSize: { xs: "14px", md: "16px" }, fontFamily: 'Inter' }}>Details</Typography>
              </Breadcrumbs>
            </Stack>
          </Stack> */}

          <Stack
            sx={{
              justifyContent: "flex-end",
              alignItems: "flex-end",
              px: 2.3,

            }}
          ></Stack>
          {/* test section */}
          {/* add files */}
          <CourseInfoCard data={course} refetchCourseData={refetchCourseData} />
          <Stack sx={{ pl: 2, pt: 1 }}>
            <>
              {course[0]?.documents && (
                <Grid container spacing={2} sx={{ px: 2, pt: 0 }}>
                  {course[0]?.documents
                    ?.slice()
                    .reverse()
                    .map((item: any, index: number) => (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        key={index}
                        alignItems="center"
                        sx={{
                          p: 1,
                          backgroundColor: "#F0F9FF",
                          borderRadius: "5px",
                          marginBottom: "10px",
                        }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Stack direction="row" alignItems="center">
                            {/* <DragIndicatorOutlinedIcon
                              sx={{
                                color: "#565656",
                                fontSize: "20px",
                                pr: "3px",
                              }}
                            /> */}
                            <IconButton sx={{ p: 0 }}>
                              <FileCopyOutlined
                                sx={{ color: "#50A1CA", fontSize: "20px" }}
                              />
                            </IconButton>
                            <Stack sx={{ px: "10px" }}>
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  color: "#565656",
                                  fontFamily: "Inter",
                                  fontWeight: "600",
                                }}
                              >
                                {item.label}
                              </Typography>
                              <Link
                                href={item.value}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Typography
                                  sx={{
                                    fontSize: "10px",
                                    color: "#565656",
                                    fontFamily: "Roboto",
                                  }}
                                >
                                  Link
                                </Typography>
                              </Link>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                    ))}
                </Grid>
              )}
            </>
          </Stack>

          <Stack sx={{ pt: 0, pl: 2 }}>
            <Grid container spacing={2} sx={{ px: 2 }}>
              {links
                ?.slice()
                .reverse()
                .map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    key={index}
                    alignItems="center"
                    sx={{
                      p: 2,
                      backgroundColor: "#F0F9FF",
                      borderRadius: "5px",
                      marginBottom: "10px",
                    }}
                    draggable
                    onDragStart={() => handleDragStart(index)}
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                    onDrop={() => handleDragOver(index)}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack direction="row" alignItems="center">
                        {/* <DragIndicatorOutlinedIcon
                          sx={{
                            color: "#565656",
                            fontSize: "20px",
                            pr: "3px",
                          }}
                        /> */}
                        <IconButton
                          onClick={() => window.open(item.value, "_blank")}
                          sx={{ p: 0 }}
                        >
                          <LinkIcon
                            sx={{
                              transform: "rotate(135deg)",
                              color: "#3C5AA0",
                            }}
                          />
                        </IconButton>
                        <Stack sx={{ px: "10px" }}>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "#565656",
                              fontFamily: "Roboto",
                              fontWeight: "600",
                            }}
                          >
                            {item.name}
                          </Typography>
                          <Link
                            href={item?.value}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                color: "#3C5AA0",
                                fontFamily: "Roboto",
                              }}
                            >
                              Link
                            </Typography>
                          </Link>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Grid>
                ))}
            </Grid>
          </Stack>

          {/* delete document popover */}
        </Stack>
      )}
    </>
  );
};

export default ViewCourse;
