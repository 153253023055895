import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { AppBar, styles } from './NavigationBar.styles';
import { Stack } from '@mui/system';
import { EditIcon, Group401Icon } from '../../../../assets/icons';
import Avatar from '@mui/material/Avatar';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Divider,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Modal,
  Typography,
  styled,
  Tooltip,
  DialogProps,
  DialogActions,
} from '@mui/material';
import SwitchCommunity from './SwitchCommunity';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import store from '../../../../store';
import { useSelector } from 'react-redux';
import { loadSelectedCommunity } from '../../../../store/slices/CommunitySlice';
import ShareCommunity from '../../../Home/ShareCommunityPopUp';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import {
  USER_APP,
  USER_APP_BASE_URL,
} from '../../../../configurations/url.config';
import MobileSideBar from '../MobileNav/MobileSidebar';
import { useSnackbar } from 'notistack';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useInvitations } from '../../../../hooks/useInvitations';
import { Invitations } from '../../../../models/Invitation.model';
import { ImportExport } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import QrCode from '../../../community/aboutCommunity/QrCode';
import { logoutService } from '../../../../services/logOut.service';
import { NotificationList } from '../../../../hooks/useNotification';
import { INotification } from '../../../../models/notifications.model';
// import { useUsers } from '../../../../hooks/useUsers';
import ExitToAppIcon from '@mui/icons-material/DeleteOutlineOutlined'
// import { removeUserFromCommunity } from '../../../../services/users.service';
import { AuthContext, IAuthContext } from '../../../../contexts/Auth.context';





export default function NavigationBar() {
  const navigate = useNavigate();
  const { getInvitesForUser } = useInvitations();
  const [invitedCommunities, setInvitedCommunities] = useState<Invitations[]>([]);

  const state = store.getState();
  const loggedInUserData = useSelector(() => {
    return state?.loggedInUser;
  });

  const usersData = store.getState();

  const [userId] = useState(usersData.loggedInUser.user?.id);

  const selectedCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );

  const { id } = useParams();

  // console.log(name, "communityName");

  const community_id = selectedCommunity?._id ? selectedCommunity?._id : id;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  // const [openDialog, setOpenDialog] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);

  // const { removeUserFromCommunityUserById } = useUsers();

  const [notifications, setNotifications] = useState<INotification[]>([]);

  const { getNotificationList } = NotificationList();

  const loadNotifcations = async () => {
    try {
      const response = await getNotificationList(userId);
      if (response && response.data) {
        const notificationData = response.data.notifications;
        setNotifications(notificationData);
      } else {
        console.log('No notifications found.');
      }
    } catch (err) {
      console.log('Error: ', err);
    } finally {
    }
  };

  useEffect(() => {
    loadNotifcations();
  }, []);



  const sentNotificationsCount = notifications.filter((notification) => {
    return (
      notification?.status === 'SENT' && community_id === notification?.communityId
    )
  });

  // console.log(sentNotificationsCount?.length, "sentNotificationsCount");

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleProfileClick = () => {
    handleMenuClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const open = Boolean(anchorEl);
  const handleClick = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getData = async (data: any) => {
    store.dispatch(loadSelectedCommunity(data));
  };

  const handleCommunitySelect = () => {
    setOpenModal(false);
  };

  const handleShareCommunity = () => {
    setModalOpen(true);
  };

  const handleCloseShere = () => {
    setModalOpen(false);
  };

  const handleLogout = async () => {
    const success = await logoutService();
    if (success) {
      localStorage.removeItem('access-token');
      localStorage.removeItem('refresh-token');
      window.location.reload();
      // navigate('/');
    } else {
      console.error('Logout failed, unable to navigate to login.');
    }
  };

  const handleInvitationsPage = async () => {
    const data = await getInvitesForUser();
    setInvitedCommunities(data);
    navigate('/invitations');
  };

  const handleNotificationPage = () => {
    navigate('/notifications');
  };

  const handleDeletePage = () => {
    navigate('/delete');
  };

  const handleCopyLink = () => {
    const labelContent = `${USER_APP_BASE_URL}/community/${selectedCommunity?.name
      .trim()
      .toLocaleLowerCase()
      .replace(/\s+/g, '-')}/${community_id}`;
    navigator.clipboard
      .writeText(labelContent)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
        enqueueSnackbar('Link copied successfully', { variant: 'success' });
      })
      .catch((error) => {
        console.error('Error copying text: ', error);
      });
  };


  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${selectedCommunity?.name
      .trim()
      .toLocaleLowerCase()
      .replace(/\s+/g, '-')}/${community_id}`
  )}`;
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${selectedCommunity?.name
      .trim()
      .toLocaleLowerCase()
      .replace(/\s+/g, '-')}/${community_id}`
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${selectedCommunity?.name
      .trim()
      .toLocaleLowerCase()
      .replace(/\s+/g, '-')}/${community_id}`
  )}`;
  const linkedinShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${selectedCommunity?.name
      .trim()
      .toLocaleLowerCase()
      .replace(/\s+/g, '-')}/${community_id}`
  )}`;


  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  const styleModal = {
    position: 'fixed' as 'fixed',
    top: { xs: '50%', md: '50%' },
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 'auto', md: '35%' },
    bgcolor: '#FFFFFF',
    borderRadius: '10px',
    boxShadow: 5,
    pb: 2,
    pl: 4,
    pr: 4,
  };
  const location = useLocation();
  const isDisabled =
    location.pathname === `/community/${community_id}/about` ||
    location.pathname ===
    `/${selectedCommunity?.name
      .trim()
      .toLocaleLowerCase()
      .replace(/\s+/g, '-')}/${community_id}` ||
    location.pathname === `/payments`;

  const selectedCommunityName =
    selectedCommunity?.name?.length > 40
      ? selectedCommunity?.name.slice(0, 40) + '...'
      : selectedCommunity?.name;

  const selectedCommunityNameMobile =
    selectedCommunity?.name?.length > 11
      ? selectedCommunity?.name.slice(0, 11) + '...'
      : selectedCommunity?.name;

  const menuId = 'primary-search-account-menu';

  const [opens, setOpens] = React.useState(false);
  const [maxWidth] = React.useState<DialogProps['maxWidth']>('sm');
  const [openQrCode, setOpenQrCode] = React.useState(false);
  const [fullWidth] = React.useState(true);

  const handleClickOpenQrCode = () => {
    setOpenQrCode(true);
  };
  const handleClickCloseQrCode = () => {
    setOpenQrCode(false);
  };

  const handleClickOpens = () => {
    setOpens(true);
  };

  const handleCloses = () => {
    setOpens(false);
  };

  const communityId = `${selectedCommunity?.selectedCommunity?._id}`;

  const communityUrl = `${USER_APP}/community/${selectedCommunity?.name
    .trim()
    .toLocaleLowerCase()
    .replace(/\s+/g, '-')}/${community_id}`;

  const [maxImgWidth] = React.useState<DialogProps['maxWidth']>('sm');

  // console.log(communityUrl, "communityUrl");

  // const userID = loggedInUserData?.user?.id
  // const handleRemoveUserByMappingId = async () => {
  //   await removeUserFromCommunityUserById(userID?.toString() || '', selectedCommunity?.selectedCommunity?._id);
  // };



  // const handleLeaveCommunity = async () => {
  //   const userId = loggedInUserData?.user?.id;
  //   const communityId = selectedCommunity?.selectedCommunity?._id;

  //   try {
  //     const response = await removeUserFromCommunity(getAccessToken(), userId?.toString() || " ", communityId,);
  //     if (response.status === 200) {
  //       enqueueSnackbar("User deleted successfully", { variant: "success", autoHideDuration: 3000 });
  //     } else {
  //       enqueueSnackbar("Invalid User delete from this community", { variant: "error", autoHideDuration: 3000 });
  //     }
  //   } catch (error) {
  //     enqueueSnackbar("Error leaving the community", { variant: "error", autoHideDuration: 3000 });
  //   }
  // };

  const renderMenu = (
    <>
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        sx={{ padding: '10px', width: '100%' }}
      >
        <MenuItem
          onClick={() => {
            navigate(`/profile/posts/${userId}`);
            handleMenuClose();
          }}
          sx={{ minHeight: '0px' }}
        >
          <Stack
            direction={'row'}
            spacing={1}
            sx={{ color: '#575757', fontWeight: '400' }}
          >
            <AccountCircleOutlinedIcon
              sx={{
                fontSize: '18px',
                color:
                  location.pathname === `/profile/posts/${userId}`
                    ? '#50A1CA'
                    : '#575757',
              }}
            />{' '}
            <Typography
              sx={{
                fontSize: '13px',
                color:
                  location.pathname === `/profile/posts/${userId}`
                    ? '#50A1CA'
                    : '#575757',
                fontFamily: 'Inter',
              }}
            >
              My Profile
            </Typography>
          </Stack>
        </MenuItem>

        <MenuItem
          onClick={() => navigate(`/profile/${userId}`)}
          sx={{ minHeight: '0px' }}
        >
          <Stack
            direction={'row'}
            spacing={1}
            sx={{ color: '#575757', fontWeight: '400' }}
            onClick={handleProfileClick}
          >
            <EditIcon
              sx={{
                fontSize: '16px',
                color:
                  location.pathname === `/profile/${userId}`
                    ? '#50A1CA'
                    : '#575757',
              }}
            >
              {' '}
            </EditIcon>
            <Typography
              sx={{
                fontSize: '13px',
                color:
                  location.pathname === `/profile/${userId}`
                    ? '#50A1CA'
                    : '#575757',
                fontFamily: 'Inter',
              }}
            >
              Edit Profile
            </Typography>
          </Stack>
        </MenuItem>
        <MenuItem onClick={handleDeletePage} sx={{ minHeight: '0px' }}>
          <Stack direction={'row'} spacing={1} sx={{ color: '#575757', fontWeight: '400' }}>
            <ExitToAppIcon sx={{ fontSize: '16px', color: '#575757' }} />
            <Typography sx={{ fontSize: '13px', color: '#575757', fontFamily: 'Inter' }}>
              Delete Account
            </Typography>
          </Stack>
        </MenuItem>
        <MenuItem onClick={handleClickOpens} sx={{ minHeight: '0px' }}>
          <Stack
            direction={'row'}
            spacing={1}
            sx={{ color: '#575757', fontWeight: '400' }}
          >
            <LogoutIcon sx={{ fontSize: '16px', color: '#575757' }} />{' '}
            <Typography
              sx={{ fontSize: '13px', color: '#575757', fontFamily: 'Inter' }}
            >
              Logout
            </Typography>
          </Stack>
        </MenuItem>
      </Menu>

      <Dialog maxWidth={maxWidth} open={opens} onClose={handleCloses}>
        <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{ backgroundColor: '#F0F9FF' }}
          >
            <Stack></Stack>
            <Stack></Stack>
            <Stack sx={{ backgroundColor: '#F0F9FF' }}>
              <Box
                onClick={handleCloses}
                sx={{
                  backgroundColor: '#50A1CA',
                  padding: '4px',
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  fontSize: '10px',
                  mt: '0.5px',
                  mr: '10px',
                  cursor: 'pointer',
                  '&:hover': {
                    cursor: 'pointer',
                    border: 'none',
                  },
                }}
              >
                <Close sx={{ fontSize: '15px' }} />
              </Box>
            </Stack>
          </Stack>
        </DialogActions>
        <DialogTitle>
          <Typography
            sx={{
              fontSize: '15px',
              font: 'Roboto',
              // color: "red",
            }}
          >
            Are you sure you want to log out?
          </Typography>
        </DialogTitle>
        <Divider />

        <Stack
          direction='row'
          sx={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <DialogActions>
            <Button
              size='small'
              onClick={handleLogout}
              sx={{
                cursor: 'pointer',
                pr: 1,
                textTransform: 'capitalize',
                color: 'White',
                background:
                  'linear-gradient(91.58deg, #50A1CA 9.79%, #3B9B7F 92.96%)',
              }}
            >
              Yes
            </Button>
            <Divider orientation='vertical' style={{ marginLeft: 25 }} />
          </DialogActions>
          <Button
            size='small'
            variant='outlined'
            sx={{
              cursor: 'pointer',
              textTransform: 'capitalize',
              color: '#50A1CA',
              border: '1px solid #50A1CA',
            }}
            onClick={handleCloses}
          >
            No
          </Button>
        </Stack>
      </Dialog>
    </>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size='large' aria-label='show 4 new mails' color='inherit'>
          <Badge badgeContent={4} color='error'>
            <Group401Icon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Grid container>
      <Stack sx={styles}>
        <AppBar color='transparent'>
          <Toolbar>
            <Grid
              item
              xs={1.6}
              md={6}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <MobileSideBar />
              </Box>
            </Grid>
            <Grid item xs={10} md={8.4}>
              <Card
                elevation={0}
                sx={{
                  height: { xs: 35, md: 50 },
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: { xs: '2vw', md: '1vw' },
                  backgroundColor: '#fff',
                  marginLeft: { xs: '-2.5vw', md: '-0.35vw' },
                  borderRadius: '10px',
                  // boxShadow: '1px 1px 8px 1px rgba(0, 0, 0, 0.05);',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    alt='not Sharp'
                    src={selectedCommunity?.logo}
                    sx={{
                      marginRight: '1vw',
                      width: { xs: '6vw', md: '2vw' },
                      height: { xs: '6vw', md: '2vw' },
                    }}
                  />
                  <Typography
                    sx={{
                      display: { xs: 'none', md: 'block' },
                      fontSize: { xs: '12px', md: '17px' },
                      background:
                        '-webkit-linear-gradient(90deg, #50A1CA 0%, #3B9B7F 100%);',

                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      fontWeight: 'bold',
                      fontFamily: 'Inter',
                    }}
                  >
                    {selectedCommunityName}
                  </Typography>
                  <Typography
                    sx={{
                      display: { xs: 'block', md: 'none' },
                      fontSize: { xs: '12px', md: '17px' },
                      background:
                        '-webkit-linear-gradient(90deg, #50A1CA 0%, #3B9B7F 100%);',

                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      fontWeight: 'bold',
                      fontFamily: 'Inter',
                    }}
                  >
                    {selectedCommunityNameMobile}
                  </Typography>
                </Box>
                <Stack
                  direction={'row'}
                  sx={{ display: { xs: 'none', md: 'flex' } }}
                >
                  <Divider
                    orientation='vertical'
                    style={{ color: 'red', height: '50px' }}
                  />
                  <Button
                    id='demo-customized-button'
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    variant='text'
                    disableElevation
                    onClick={handleClick}
                    startIcon={
                      <ImportExport
                        sx={{
                          transform: 'rotate(90deg)',
                          transition: 'transform 0.5s ease',
                          ml: 1,
                        }}
                      />
                    }
                    sx={{
                      fontSize: { xs: '13px', md: '15px' },
                      textTransform: 'none',
                      color: '#50A1CA',
                      fontFamily: 'Inter',
                    }}
                    disabled={isDisabled || Boolean(id)}
                  >
                    Switch
                  </Button>
                </Stack>

                <Stack
                  direction={'row'}
                  sx={{ display: { xs: 'flex', md: 'none' } }}
                >
                  <Divider
                    orientation='vertical'
                    style={{ color: '#50A1CA87', height: '50px' }}
                  />
                  <Button
                    id='demo-customized-button'
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    variant='text'
                    disableElevation
                    onClick={handleClick}
                    startIcon={
                      <ImportExport
                        sx={{
                          transform: 'rotate(90deg)',
                          transition: 'transform 0.5s ease',
                          ml: 3,
                        }}
                      />
                    }
                    sx={{
                      fontSize: { xs: '13px', md: '15px' },
                      textTransform: 'none',
                      color: '#50A1CA',
                      fontFamily: 'Inter',
                      width: '1px',
                    }}
                    disabled={isDisabled || Boolean(id)}
                  ></Button>
                </Stack>
              </Card>
            </Grid>
            <Stack
              sx={{
                display: { xs: 'flex', md: 'none' },
                flexDirection: 'row',
                ml: 1,
              }}
              gap={1}
            >
              <Badge badgeContent={invitedCommunities?.length} color='error'>
                <GroupAddOutlinedIcon
                  onClick={handleInvitationsPage}
                  sx={{ color: '#50A1CA', fontSize: '18px' }}
                />
              </Badge>
              <Badge badgeContent={sentNotificationsCount?.length} color='error'>
                <NotificationsNoneOutlinedIcon
                  onClick={handleNotificationPage}
                  sx={{ color: '#50A1CA', fontSize: '18px' }}
                />
              </Badge>
            </Stack>

            <Box sx={{ flexGrow: 1 }} />

            <Box
              sx={{
                display: {
                  xs: 'none',
                  md: 'flex',
                  alignItems: 'end',
                  justifyContent: 'center',
                },
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton size='large' onClick={handleNotificationPage}>
                  <Tooltip title='Notifications'>
                    <Badge badgeContent={sentNotificationsCount?.length} color='error'>
                      <NotificationsNoneOutlinedIcon
                        sx={{ color: '#50A1CA', fontSize: '23px' }}
                      />
                    </Badge>
                  </Tooltip>
                </IconButton>
                <IconButton size='large' onClick={handleInvitationsPage}>
                  <Tooltip title='Invitations'>
                    <Badge
                      badgeContent={invitedCommunities?.length}
                      color='error'
                    >
                      <GroupAddOutlinedIcon
                        sx={{ color: '#50A1CA', fontSize: '23px' }}
                      />
                    </Badge>
                  </Tooltip>
                </IconButton>

                <IconButton disableRipple size='large'>
                  <Tooltip title='QR Code'>
                    <QrCodeScannerIcon
                      onClick={handleClickOpenQrCode}
                      sx={{ color: '#50A1CA', fontSize: '23px' }}
                    />
                  </Tooltip>
                </IconButton>
                <IconButton size='large'>
                  <Badge color='error'>
                    <Tooltip title='Share'>
                      <ShareOutlinedIcon
                        onClick={handleShareCommunity}
                        sx={{ color: '#50A1CA', fontSize: '23px' }}
                      />
                    </Tooltip>
                  </Badge>
                </IconButton>
                <Dialog
                  fullWidth={fullWidth}
                  maxWidth={maxImgWidth}
                  open={openQrCode}
                  onClose={handleClickCloseQrCode}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      height: '100%',
                    }}
                  >
                    <Box
                      component={'img'}
                      src={selectedCommunity?.logo}
                      sx={{
                        backgroundColor: 'white',
                        padding: 1,
                        width: '80px',
                        height: '80px',
                        position: 'fixed',
                        borderRadius: '100px',
                        left: '50%',
                        transform: 'translate(-50%, 0)',
                        zIndex: 10,
                        boxShadow: 1,
                      }}
                    />
                  </div>
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    sx={{ backgroundColor: '#ffffff', p: 2 }}
                  >
                    <Stack>
                      <Box
                        sx={{}}
                        component={'img'}
                        src={require('./../../../../assets/images/Communn-favicon.png')}
                        alt=''
                        width={'30%'}
                      />
                    </Stack>
                    <Stack></Stack>
                    <Stack sx={{ backgroundColor: '#ffffff' }}>
                      <Box
                        onClick={handleClickCloseQrCode}
                        sx={{
                          backgroundColor: '#50A1CA',
                          padding: '4px',
                          borderRadius: '50%',
                          width: '20px',
                          height: '20px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: 'white',
                          fontSize: '10px',
                          cursor: 'pointer',
                          '&:hover': {
                            cursor: 'pointer',
                            border: 'none',
                          },
                        }}
                      >
                        <Close sx={{ fontSize: '15px' }} />
                      </Box>
                    </Stack>
                  </Stack>
                  <DialogContent sx={{ padding: '0px' }}>
                    <Stack
                      direction={'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Stack></Stack>
                      <Stack
                        display={'flex'}
                        alignItems={'center'}
                        sx={{ pb: { xs: 2, md: 5 } }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: '16px', md: '24px' },
                            color: '#50A1CA',
                            fontWeight: 600,
                          }}
                        >
                          {selectedCommunityName}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: '13px', md: '16px' },
                            color: '#000000',
                            textAlign: 'center'
                          }}
                        >
                          Invite members easily—just ask them to scan to join.
                        </Typography>
                        <QrCode id={communityId} url={communityUrl} />
                        {/* <Stack display={'flex'} direction={'row'} spacing={3}>
                          <OutlineButton
                            name='Copy URL'
                            onClick={handleCopyLinkUrl}
                            icon={
                              <ContentCopyOutlinedIcon
                                sx={{ display: { xs: 'none', md: 'block' } }}
                              />
                            }
                          />
                          <OutlineButton
                            name='Download PNG'
                            onClick={handleDownloadQrCode}
                            icon={
                              <DownloadOutlined
                                sx={{ display: { xs: 'none', md: 'block' } }}
                              />
                            }
                          />
                          <OutlineButton
                            name='Share Link'
                            onClick={() => {
                              const url = `${USER_APP_BASE_URL}/communities/${selectedCommunity?.name
                                .trim()
                                .toLocaleLowerCase()
                                .replace(/\s+/g, '-')}/${community_id}/about`;
                              const encodedUrl = encodeURIComponent(url);
                              window.open(
                                `https://api.whatsapp.com/send?text=${encodedUrl}`,
                                '_blank'
                              );
                            }}
                            icon={
                              <ShareOutlinedIcon
                                sx={{ display: { xs: 'none', md: 'block' } }}
                              />
                            }
                          />
                        </Stack> */}
                      </Stack>
                      <Stack></Stack>
                    </Stack>
                  </DialogContent>
                </Dialog>
              </div>
              <IconButton
                disableRipple
                size='small'
                edge='end'
                aria-label='account of current user'
                aria-controls={menuId}
                aria-haspopup='true'
                onClick={handleProfileMenuOpen}
                color='inherit'
              >
                <StyledBadge
                  overlap='circular'
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant='dot'
                >
                  <Avatar
                    src={loggedInUserData?.user?.avatar}
                    sx={{ backgroundColor: '#3C5AA0' }}
                  >
                    {loggedInUserData?.user?.avatar
                      ? null
                      : loggedInUserData?.user?.firstName?.charAt(0) +
                      '' +
                      loggedInUserData?.user?.lastName?.charAt(0)}
                  </Avatar>
                </StyledBadge>
              </IconButton>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              {/* <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton> */}
              <Box onClick={handleProfileMenuOpen}>
                <IconButton
                  disableRipple
                  size='large'
                  edge='end'
                  aria-label='account of current user'
                  aria-controls={menuId}
                  aria-haspopup='true'
                  onClick={handleProfileMenuOpen}
                  color='inherit'
                >
                  <StyledBadge
                    overlap='circular'
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant='dot'
                  >
                    <Avatar
                      src={loggedInUserData?.user?.avatar}
                      sx={{ backgroundColor: '#3C5AA0' }}
                    >
                      {loggedInUserData?.user?.avatar
                        ? null
                        : loggedInUserData?.user?.firstName?.charAt(0) +
                        '' +
                        loggedInUserData?.user?.lastName?.charAt(0)}
                    </Avatar>
                  </StyledBadge>
                </IconButton>
              </Box>
            </Box>
          </Toolbar>
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            sx={{ borderRadius: '100px' }}
          >
            <Box sx={styleModal}>
              <SwitchCommunity
                getData={getData}
                onCommunitySelect={handleCommunitySelect}
              />
            </Box>
          </Modal>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Stack>
      <ShareCommunity
        isOpen={isModalOpen}
        handleClose={handleCloseShere}
        dpSrc={selectedCommunity?.logo || ''}
        title={selectedCommunity?.name || ''}
        city={selectedCommunity?.city || ''}
        members={selectedCommunity?.members || []}
        communityId={selectedCommunity?._id || ''}
        handleCopyLink={handleCopyLink}
        copied={copied}
        whatsappShareUrl={whatsappShareUrl}
        facebookShareUrl={facebookShareUrl}
        linkedinShareUrl={linkedinShareUrl}
        twitterShareUrl={twitterShareUrl}
        coverImageSrc={selectedCommunity?.banner || ''}
      />
    </Grid>
  );
}
