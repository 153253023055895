import {
  Button,
  Card,
  Stack,
  Typography,
  Tooltip,
  Avatar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { KingIcon } from "../../../assets/icons";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useState } from "react";
import { USER_APP_BASE_URL } from "../../../configurations/url.config";
import { useSnackbar } from "notistack";
import InvitesandJoinPreview from "../CommunityPreview/InvitesandJoinPreview";
// import store from "../../../store";
import { useDispatch } from "react-redux";
import { loadSelectedCommunity } from "../../../store/slices/CommunitySlice";
import { IMemberCommunity } from "../../../models/communities.modal";

interface ICardProps {
  data: IMemberCommunity;
}

export const JoinedAllCommunities = (props: ICardProps) => {
  const { data } = props;

  // console.log(data, "In Joined Al communities");

  const [copied, setCopied] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [link] = useState("");

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  // console.log(community);
  const navigate = useNavigate();

  const handlePreviewCommunity = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const dispatch = useDispatch();

  const handleButtonClick = () => {
    if (data?.community?.collectSubscription === "YES") {
      if (
        data?.subscriptionStatus === "CREATED" ||
        data?.subscriptionStatus === "EXPIRED"
      ) {
        const { _id } = data?.community;
        navigate("/subscriptions", { state: { _id } });
        dispatch(loadSelectedCommunity(data?.community));
      } else {
        const { _id } = data?.community;
        navigate("/home", { state: { _id } });
        dispatch(loadSelectedCommunity(data?.community));
      }
    } else {
      const { _id } = data?.community;
      navigate("/home", { state: { _id } });
      dispatch(loadSelectedCommunity(data?.community));
    }
  };

  const handleCopyLink = () => {
    const labelContent = `${USER_APP_BASE_URL}/community/${data?.community?.name?.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${data?.community?._id}`;

    navigator.clipboard
      .writeText(labelContent)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
        enqueueSnackbar("Link copied successfully", { variant: "success" });
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };

  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${data?.community?.name?.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${data?.community?._id}`
  )}`;
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${data?.community?.name?.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${data?.community?._id}`
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${data?.community?.name?.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${data?.community?._id}`
  )}`;
  const linkedinShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/community/${data?.community?.name?.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${data?.community?._id}`
  )}`;

  return (
    <>
      <Stack spacing={2} sx={{ p: 1 }}>
        <Card
          sx={{
            textAlign: "center",
            borderRadius: "10px",
            p: 2,
            m: 1,
          }}
        >
          <Stack
            direction={"row"}
            alignContent={"space-evenly"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} alignContent={"center"}>
              {" "}
              {data?.community?.type === "PRIVATE" ? (
                <LockOutlinedIcon sx={{ color: "#838383", fontSize: "18px" }} />
              ) : (
                <Stack sx={{ height: "25px" }}></Stack>
              )}
              {data?.community?.collectSubscription === "YES" ? (
                <KingIcon sx={{ color: "#838383" }} />
              ) : (
                <Stack sx={{ height: "25px" }}></Stack>
              )}
            </Stack>

            <Stack>
              <BootstrapTooltip title="Info" placement="left">
                <InfoOutlinedIcon
                  onClick={handlePreviewCommunity}
                  sx={{ color: "#838383", fontSize: "18px", cursor: "pointer" }}
                ></InfoOutlinedIcon>
              </BootstrapTooltip>
            </Stack>
          </Stack>
          {/* Community Logo */}
          <Stack display="flex" alignItems="center" justifyContent="center">
            <Avatar
              className="product--image"
              src={data?.community?.logo}
              alt="community-profile"
              sx={{
                objectFit: "cover",
                width: "60px",
                height: "60px",
                borderRadius: "100px",
              }}
            />
          </Stack>

          {/* Render name with conditional check */}
          {data?.community?.name ? (
            <Typography
              variant="h2"
              sx={{
                fontSize: "12px",
                color: "#4D4C4C",
                fontWeight: "600",
                mt: 0.5,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "100%", // Ensure the text doesn't overflow the container
              }}
            >
              {data?.community?.name.length > 20
                ? `${data?.community?.name.slice(0, 20)}...`
                : data?.community?.name}
            </Typography>
          ) : (
            <Stack sx={{ height: "15px" }}></Stack>
          )}

          {/* Render city with conditional check */}
          {data?.community?.city ? (
            <Typography
              sx={{ fontSize: "10px", color: "#878787", fontWeight: "500" }}
            >
              {data?.community?.city.length > 20
                ? `${data?.community?.city.slice(0, 20)}...`
                : data?.community?.city}
            </Typography>
          ) : (
            <Typography
              sx={{ fontSize: "10px", color: "#878787", fontWeight: "500" }}
            >
              No city
            </Typography>
          )}

          {/* Render members count with conditional check */}
          {data?.community?.members?.length !== undefined ? (
            <Typography
              sx={{ fontSize: "11px", color: "#353535", fontWeight: "500" }}
            >
              {data?.community?.members?.length}
            </Typography>
          ) : (
            <Stack sx={{ height: "15px" }}></Stack>
          )}
          <Stack
            direction={"row"}
            alignContent={"center"}
            justifyContent={"center"}
            spacing={2}
            mt={1}
          >
            {data?.status === "ACTIVE" || data?.status === "CREATED" ? (
              <Button
                variant="contained"
                size="small"
                sx={{
                  background:
                    "linear-gradient(to right bottom, #50a1ca, #3b9b7f)",
                  textTransform: "capitalize",
                  height: "25px",
                  color: "#ffffff",
                  boxShadow: "none",
                }}
                onClick={handleButtonClick}
              >
                <Typography
                  sx={{ color: "#ffffff", fontSize: "13px", fontWeight: "500" }}
                >
                  Go
                </Typography>
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                size="small"
                sx={{
                  textTransform: "capitalize",
                  height: "25px",
                  color: "#ffffff",
                  boxShadow: "none",
                }}
              >
                <Typography
                  sx={{ color: "#ffffff", fontSize: "13px", fontWeight: "500" }}
                >
                  Blocked
                </Typography>
              </Button>
            )}
          </Stack>
        </Card>
      </Stack>

      <InvitesandJoinPreview
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        logo={data?.community?.logo}
        id={data?.community?._id}
        title={data?.community?.name}
        city={data?.community?.city}
        status={data?.community?.status}
        link={link}
        members={data?.community?.members?.length || []}
        totalmembers={data?.community?.totalMembers}
        type={data?.community?.type}
        description={data?.community?.description}
        handleCopyLink={handleCopyLink}
        copied={copied}
        whatsappShareUrl={whatsappShareUrl}
        facebookShareUrl={facebookShareUrl}
        linkedinShareUrl={linkedinShareUrl}
        twitterShareUrl={twitterShareUrl}
        coverImageSrc={data?.community?.banner}
      />
    </>
  );
};
