import { useState, useEffect } from "react";
import { useCommunity } from "./useCommunity";
import { ICommunity } from "../models/communities.modal";

export const useFetchCommunityData = (community_id: string | undefined) => {
  const { getCommunityById } = useCommunity();
  const [data, setData] = useState<ICommunity | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (community_id) {
          const response = await getCommunityById(community_id);
          const communityData = response?.data as ICommunity;
          setData(communityData);
          const paymentStatus =
            communityData?.collectSubscription === "YES" &&
            ["EXPIRED", "CREATED"].includes(
              communityData?.subscriptionStatus || ""
            );
          if (paymentStatus) {
            setShowPopup(true);
          }
        }
      } catch (error) {
        console.error("Error fetching community data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [community_id]);

  return { data, loading, showPopup, setShowPopup };
};
