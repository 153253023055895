import Box from "@mui/material/Box";
import { Grid, } from "@mui/material";
import { styles } from "./SubscriptionLayout.styles";
import { Outlet } from "react-router-dom";
import SubscriptionLayoutHeader from "./SubscriptionLayoutHeader";


export default function NoAuthCommunityLayout() {
  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <Box sx={styles.main}>
          <SubscriptionLayoutHeader />
          <Box sx={styles.contentSection}>
            <Grid container sx={{ pb: 8 }}>
              <Grid item xs={0.5} md={2}></Grid>
              <Grid item xs={11} md={8} lg={8}>
                <Box>
                  <Outlet />
                </Box>
              </Grid>
              <Grid item xs={0.5} md={2}></Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
